import { createAction, createReducer } from "@reduxjs/toolkit";

// Action types
export const decrementFetchingCounter = createAction("app/decrementFetchingCounter");
export const incrementFetchingCounter = createAction("app/incrementFetchingCounter");
export const resetFetchingCounter = createAction("app/resetFetchingCounter");
// Initial state
const initialState = {
  fetchingCounter: 0
};

// Reducer
export const fetchingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(incrementFetchingCounter, (state) => {
      return {
        ...state,
        fetchingCounter: state.fetchingCounter + 1
      };
    })
    .addCase(decrementFetchingCounter, (state) => {
      return {
        ...state,
        fetchingCounter: state.fetchingCounter - 1
      };
    })
    .addCase(resetFetchingCounter, (state) => {
      return {
        ...state,
        fetchingCounter: 0
      };
    });
});
