import React from "react";
import { Image, Text, View } from "@react-pdf/renderer";
import { baseStyle, extendStyle } from "./methods";

const ReportFunnelSection = ({ data, name }) => {
  const styles = baseStyle();

  if (!data) {
    return null;
  }

  return (
    <View style={extendStyle({}, { marginTop: 20, marginBottom: 35 })} wrap={false}>
      <Text style={extendStyle(styles.bold, { marginBottom: 10, fontSize: 12 })}>{name}</Text>
      {!data.image2 && <Image src={data.image} style={extendStyle({}, { maxWidth: "100%", height: "auto" })} />}
      <View
        style={extendStyle(
          {},
          {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            marginTop: 10,
            flexWrap: "wrap"
          }
        )}
      ></View>
    </View>
  );
};

export default ReportFunnelSection;
