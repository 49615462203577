export const INFO_MODAL = "info-modal";
export const GRAPH_INFO = {
  "chartCpcc": {
    "title": "Cost per customer contact",
    "description": "The media cost required to obtain one customer, i.e. media costs/unique contacts."
  },
  "chartReach": {
    "title": "Reach",
    "description": "The net reach, i.e. unique contacts."
  },
  "chartGRP": {
    "title": "GRP",
    "description": "The grp for the classical channels."
  },
  "chartCpm": {
    "title": "Cost per mille",
    "description": "The media cost required to obtain 1000 customers, i.e. (media costs/unique contacts) * 1000 ."
  },
  "chartTotal": {
    "title": "Total Spending",
    "description": "The pure media spend, without platform or agencies fees."
  },
  "funnel": {
    "title": "Funnel",
    "description":
      "The path your customers take on their journey with your business, from the first point a person becomes aware of your brand, through to potential purchase and beyond."
  },
  "chartCostAnalysis": {
    "title": "Cost Per Channel Detail",
    "description":
      "View all your different costs (technical, operational, data, and content) across all your channels, publishers and media types."
  },
  "chartCostTrendAnalysis": {
    "title": "Cost Trends",
    "description": "View all your different costs (technical, operational, data, and content) spent over the time."
  },
  "chartMediaBudgetAllocation": {
    "title": "Media Budget Allocation",
    "description": "Distribution of the budget across different channels"
  },
  "chartMediaRevenueForecast": {
    "title": "Media Revenue Forecast",
    "description": "Distribution of the forecasted revenue across different channels"
  },
  "chartReturnOnInvestment": {
    "title": "Return on Investment",
    "description": "Measure of the profit i.e ratio between the forecasted revenue and marketing spend"
  }
};
