import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { pdf } from "@react-pdf/renderer";
import moment from "moment";
import Button from "../../../components/Button";
import { SAVE_MODAL } from "../config";
import Form from "../../../components/Form";
import { Input } from "../../../components/Input";
import ReportPDF from "../ReportPDF";
import { preparePDFReportData } from "../ReportPDF/methods";
import useFormSubmit from "../../../components/Form/useFormSubmit";
import { successNotification } from "../../../components/Notification";
import config from "../../../config";
import { closeModalAction } from "../../../components/Modal/reducer";

const SaveModalForm = ({ dashboardFilters, dashboardData, funnelData, type }) => {
  const globalState = useSelector((state) => state.global);
  const data = {
    ...dashboardData,
    ...dashboardFilters,
    funnelData,
    globalState,
    totalSpendingTable: dashboardData?.totalSpending?.data || [],
    totalSpendingValue: globalState.totalSpending
  };
  const [form] = Form.useForm();
  const [axiosSource, setAxiosSource] = useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const dispatch = useDispatch();
  const report_data = {
    "cpcc": dashboardFilters.cpccVisible ? globalState.cpcc?.data : null,
    "cpm": dashboardFilters.cpmVisible ? globalState.cpm?.data : null,
    "cpcd": dashboardFilters.costVisible ? globalState.cpcd?.data : null,
    "reach": dashboardFilters.reachVisible ? globalState.reach?.data : null,
    "costTrend": dashboardFilters.costTrendVisible ? globalState.costTrend?.data : null,
    "totalSpending": dashboardFilters.totalSpendingsVisible ? globalState.totalSpendingsTable?.data?.result : null
  };
  const { loading, generalError, submit } = useFormSubmit({
    form,
    values: {
      report_data: report_data,
      created_at: new Date()
    },
    route: "reports/",
    onSuccess: () => {
      successNotification({
        description: "Report saved successfully!",
        iconStyle: "icon-color-green"
      });
      dispatch(closeModalAction({ name: SAVE_MODAL }));
    }
  });

  const onDownloadPdf = () => {
    if (downloadLoading) {
      return;
    }

    const pdfFileName = form.getFieldValue("report_name") || `Report ${moment().format(config.dateFormat.shortFormat)}`;
    preparePDFReportData({
      data,
      onStart: (source) => {
        setDownloadLoading(true);
        setAxiosSource(source);
      },
      onSuccess: (res) => {
        const blob = pdf(<ReportPDF data={{ ...res, reportName: pdfFileName }} type={type} />).toBlob();
        blob.then((res) => {
          const pdfBlob = new Blob([res], { type: "application/pdf" });
          var reader = new FileReader();
          reader.readAsDataURL(pdfBlob);
          reader.onloadend = function () {
            submit({
              report_name: pdfFileName,
              report_type: "pdf",
              report_pdf: reader.result
            });
            setDownloadLoading(false);
          };
        });
      },
      onFailed: () => {
        setDownloadLoading(false);
      }
    });
  };
  //changes to be made here
  useEffect(() => {
    return () => {
      axiosSource?.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axiosSource]);

  return (
    <>
      <span className="export-title mb-a">Save report</span>
      <Form onSubmit={onDownloadPdf} form={form} loading={loading || downloadLoading} errors={generalError}>
        <Form.Item label="Report name" name="report_name">
          <Input placeholder="Set a name for this report" />
        </Form.Item>
        <div className="flex flex-justify-center">
          <Button
            type="primary"
            className="min-w-130"
            htmlType="submit"
            disabled={loading || downloadLoading}
            loading={loading || downloadLoading}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => ({
  dashboardFilters: state.globalFilters,
  dashboardData: state.dashboardData,
  funnelData: state.funnelData
});

export default connect(mapStateToProps)(SaveModalForm);
