import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Back from "../../images/icons/Back";
import Preloader from "../../components/Preloader";
import "./style.scss";
import Button from "../../components/Button";
import UpdateBusinessRulePopupModal from "./UpdateBusinessRule/Modal";
import UpdateBusinessRuleModel from "./UpdateBusinessRule/UpdateModal";

const CostManagementDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const data = location.state && location.state.record;
  const extractName = function (item) {
    return item?.name;
  };
  const channels = data.channels.map(extractName);
  const publishers = data.publishers.map(extractName);
  const media_types = data.media_types.map(extractName);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    setLoading(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showUpdateModal = () => {
    setIsUpdateModalOpen(true);
  };
  const handleUpdateModalCancel = () => {
    setIsUpdateModalOpen(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${new Intl.DateTimeFormat("en-US", { month: "short" }).format(date)} ${date.getFullYear()}`;
    return formattedDate;
  };

  const formatString = (costType) => {
    if (costType != null) {
      return costType.charAt(0).toUpperCase() + costType.slice(1).toLowerCase();
    } else {
      return "Not applicable";
    }
  };

  const formatCostUnit = (costUnit) => {
    return costUnit === "euro" ? "€" : costUnit === "percentage" ? "%" : costUnit;
  };
  return (
    <>
      <div className="flex flex-items-center title-medium mr-20 mb-10 mt-15 flex-nowrap">
        <button
          type="button"
          className="back-button"
          onClick={() => {
            history.goBack();
          }}
        >
          <Back />
        </button>
        <div className="mr-5 nowrap">Cost Management</div>
      </div>

      <div className="rounded-box cost-management-box">
        {loading ? (
          <Preloader size={30} />
        ) : (
          <div>
            <div className="title-info-container">
              <div className="mr-5">{data.title}</div>
              <div className="mr-5">Last Updated: {data.updated_at.substring(0, 10)}</div>
            </div>
            <div className="divider"></div>
            <div className="details-container">
              <div className="detail-row">
                <div className="mr-5 title-container">Date period : </div>
                <div className="mr-5 value-container">
                  {formatDate(data.period_start)} - {formatDate(data.period_end)}
                </div>
              </div>
              <div className="detail-row">
                <div className="mr-5 title-container">Channels : </div>
                <div className="mr-5 value-container">{channels.join(", ")}</div>
              </div>
              <div className="detail-row">
                <div className="mr-5 title-container">Publishers : </div>
                <div className="mr-5 value-container">{publishers.join(", ")}</div>
              </div>
              <div className="detail-row">
                <div className="mr-5 title-container">Media type : </div>
                <div className="mr-5 value-container">{media_types.join(", ")}</div>
              </div>
            </div>
            <br />
            <div className="divider"></div>
            <br />
            <div className="details-container">
              <div className="detail-row">
                <div className="mr-5 title-container">Cost Type :</div>
                <div className="mr-5 value-container">
                  {formatString(data.cost_type)} - {data.cost_value} {formatCostUnit(data.cost_unit)}
                </div>
              </div>
              <div className="detail-row">
                <div className="mr-5 title-container">Split by Metric :</div>
                <div className="mr-5 value-container">{formatString(data.split_metric)}</div>
              </div>
              <div className="detail-row">
                <div className="mr-5 title-container">Split on Values :</div>
                <div className="mr-5 value-container">{formatString(data.split_values)}</div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="rounded-box update-box">
        <div className="update-info-container">
          <div className="mr-5">Update the dashboard with the business rule?</div>
          <div className="mr-5 button-container">
            <div>
              <Button
                type="primary"
                style={{ padding: "5px 15px", width: "90px", marginRight: "20px" }}
                className="ml-a"
                onClick={showUpdateModal}
              >
                Edit
              </Button>
              <UpdateBusinessRuleModel
                data={data}
                // reloadPanningCenterData={reloadCostManagementDetailData()}
                // reloadPanningCenterData={() => {}}
                visible={isUpdateModalOpen}
                onCancel={handleUpdateModalCancel}
              />
            </div>
            <div>
              <Button
                type="primary"
                style={{ padding: "5px 15px", width: "90px" }}
                className="ml-a"
                onClick={showModal}
              >
                {data.active ? "Deactivate" : "Activate"}
              </Button>
              <UpdateBusinessRulePopupModal visible={isModalOpen} onCancel={handleCancel} data={data} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CostManagementDetails;
