import React from "react";
import { Text, View } from "@react-pdf/renderer";
import numeral from "numeral";
import { baseStyle, extendStyle } from "./methods";

const ReportTotalSpendingPdf = ({ data }) => {
  const styles = baseStyle();

  if (!data) {
    return null;
  }

  return (
    <View>
      <View style={styles.table}>
        <View style={extendStyle(styles.row, { marginBottom: 15 })}>
          <View style={styles.column}>
            <Text style={styles.bold}>{data.columnName || "Channel"}</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.bold}>Spend</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.bold}>Reach</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.bold}>ø CpCC (€)</Text>
          </View>
        </View>
        {data.data.map((item, idx) => (
          <View
            key={item.label || item.Properties || idx}
            style={extendStyle(styles.row, {
              marginBottom: 4,
              paddingBottom: 4,
              borderBottom: "1px solid #F5F6FA"
            })}
          >
            <View style={styles.column}>
              <Text>{item.label || item.Properties}</Text>
            </View>
            <View style={styles.column}>
              <Text>{numeral(item.spent).format("0a")}</Text>
            </View>
            <View style={styles.column}>
              <Text>{numeral(item.reach).format("0a")}</Text>
            </View>
            <View style={styles.column}>
              <Text>{numeral(item.cpcc).format("0a")}</Text>
            </View>
          </View>
        ))}
      </View>
      <View
        style={extendStyle({
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: 15,
          fontSize: 12
        })}
      >
        {/* <Text style={extendStyle({ marginRight: 8 })}>Total Spending:</Text>
        <Text style={styles.bold}>{formatCurrency(data.total)}</Text> */}
      </View>
    </View>
  );
};

export default ReportTotalSpendingPdf;
