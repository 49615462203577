import React from "react";

function Icon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill={props.color || "#000"}
        d="M.397.554L.47.47a.75.75 0 01.976-.073L1.53.47 8 6.939l6.47-6.47a.75.75 0 111.06 1.061L9.061 8l6.47 6.47a.75.75 0 01.072.976l-.073.084a.75.75 0 01-.976.073l-.084-.073L8 9.061l-6.47 6.47A.75.75 0 01.47 14.47L6.94 8 .47 1.53A.75.75 0 01.396.554L.47.47.397.554z"
      ></path>
    </svg>
  );
}

export default Icon;
