export const sortTableColumnString = (a = "", b = "") => {
  return (a || "").localeCompare(b || "");
};

export const sortTableColumnDate = (a = "", b = "") => {
  if (a && b) {
    return new Date(a) - new Date(b);
  }

  return false;
};
