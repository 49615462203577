import { createAction, createReducer } from "@reduxjs/toolkit";

// Define the action
export const savePlanningCenterData = createAction("planningCenter/saveData");

// Define the reducer
export default createReducer({ planningCenterData: null }, (builder) => {
  builder.addCase(savePlanningCenterData, (state, action) => {
    state.planningCenterData = action.payload;
  });
});
