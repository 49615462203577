import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRequestSWR } from "../../../utils/custom-hooks";
import LineBarChartLegends from "../Charts/LineBarChartLegends";
import { prepareCPCCParams, prepareYOYResponse } from "../methods";
import { changeGlobalState } from "../../App/reducer";
import { analysisTypes, getChartNameKeyBasedOnAnalysisType } from "../Charts/methods";
import { incrementFetchingCounter, decrementFetchingCounter } from "../../../utils/reducer.js";
import { getStorageColorData } from "../../../components/_auth/methods";
export const CHART_CPCC_NAME = "chartCpcc";
export const AVG_CHART = true;

const CostPerCustomerChart = () => {
  const globalFiltersState = useSelector((state) => state.globalFilters);
  const [analysisType, setAnalysisType] = useState(analysisTypes[0].value);
  const dispatch = useDispatch();

  const cpccData = useRequestSWR({
    requestKeyName: "cpcc_graph",
    apiRoute: "data/cpcc_graph",
    method: "post",
    params: prepareCPCCParams(globalFiltersState, {
      analysis_type: analysisType,
      colors: JSON.parse(getStorageColorData())
    }),
    onStart: () => {
      dispatch(incrementFetchingCounter());
    },
    onSuccess: () => {
      dispatch(decrementFetchingCounter());
    }
  });
  dispatch(changeGlobalState({ cpcc: { data: cpccData?.data?.result, loading: cpccData?.loading } }));

  useEffect(() => {
    return () => {
      cpccData.source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parserNameKey = getChartNameKeyBasedOnAnalysisType(analysisType);

  return (
    <LineBarChartLegends
      dashboardFilters={globalFiltersState}
      chartName={CHART_CPCC_NAME}
      avgChart={AVG_CHART}
      title="Cost per customer contact"
      data={cpccData.isError ? [] : prepareYOYResponse(cpccData.data, parserNameKey)}
      loading={cpccData.loading}
      className="mb-50"
      parserKeys={{
        nameKey: parserNameKey
      }}
      onAnalysisTypeChange={(type) => {
        if (type !== analysisType) {
          setAnalysisType(type);
        }
      }}
      lineChartActive
    />
  );
};

export default CostPerCustomerChart;
