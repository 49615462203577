import React from "react";
import { useSelector } from "react-redux";
import Modal from "../../../components/Modal";
import { INFO_MODAL, GRAPH_INFO } from "../config";
import { getModalData } from "../../../utils/common";

const InfoModal = () => {
  const data = useSelector(getModalData(INFO_MODAL));
  const currGraphInfo = GRAPH_INFO[data];
  return (
    <Modal width={650} name={INFO_MODAL} className="rounded">
      <span className="export-title mb-a">{currGraphInfo?.title}</span>
      {currGraphInfo?.description}
    </Modal>
  );
};

export default InfoModal;
