import { createAction, createReducer } from "@reduxjs/toolkit";

export const openLogoutModal = createAction("app/openLogoutModal");
export const closeLogoutModal = createAction("app/closeLogoutModal");

// Initial state
const initialState = {
  visible: false
};

// Reducer
export const logoutReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(openLogoutModal, (state) => {
      return {
        ...state,
        visible: true
      };
    })
    .addCase(closeLogoutModal, (state) => {
      return {
        ...state,
        visible: false
      };
    });
});
