import React from "react";
import { Helmet } from "react-helmet";
import ViewTable from "./ViewTable";

const CostManagementPage = () => {
  // const [seeDetails, setSeeDetails] = useState(false);

  // const onClickBackButton = () => {
  //   setSeeDetails(false);
  // };

  return (
    <>
      <Helmet>
        <title>taico - Cost Management</title>
      </Helmet>
      <ViewTable />
    </>
  );
};

export default CostManagementPage;
