import { closeModalAction } from "../../../components/Modal/reducer";
import { successNotification } from "../../../components/Notification";
import { getStorageAccessToken } from "../../../components/_auth/methods";
import { EXPORT_MODAL } from "../config";
import { errorNotification } from "../../../components/Notification";
import axios from "../../../axios";
import { formatDate } from "../../../utils/common";
import config from "../../../config";

export const onDownloadReport = (data, dispatch) => {
  const accessToken = getStorageAccessToken();
  const route = data["report_data"] ? "/reports/export" : `/reports/${data["id"]}`;
  const RequestConfig = {
    params: data.params,
    method: "GET",
    responseType: "arraybuffer",
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  const apiRequest = data["report_data"] ? axios.post(route, data, RequestConfig) : axios.get(route, RequestConfig);
  apiRequest.then(
    (res) => {
      const fileExtension =
        data.params.file_type === "excel"
          ? ".xlsx"
          : data.params.file_type === "xml"
          ? ".zip"
          : data.params.file_type === "pdf"
          ? ".pdf"
          : "";
      const blob = new Blob([res.data]);
      const blobURL = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      let formattedDate = data["report_data"] ? Date.now() : data.created_at;
      formattedDate = formatDate({ date: formattedDate, format: config.dateFormat.compressed });
      const filename = `${formattedDate}_${data["report_data"] ? "taico_report" : data.title}`;
      anchor.href = blobURL;

      anchor.setAttribute("download", filename + fileExtension);
      document.body.appendChild(anchor);
      anchor.click();

      successNotification({});
      dispatch(closeModalAction({ name: EXPORT_MODAL }));
    },
    () => {
      errorNotification({ description: "Report download failed." });
    }
  );
};
