import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import { EXPORT_MODAL } from "../config";
import DownloadPDFButton from "../ReportPDF/DownloadPDFButton";
import { getModalData } from "../../../utils/common";
import { onDownloadReport } from "./methods";

const ExportModal = ({ inTable, dashboardFilters, dashboardData, funnelData, type }) => {
  const globalState = useSelector((state) => state.global);
  let data = useSelector(getModalData(EXPORT_MODAL));
  const dispatch = useDispatch();
  if (!inTable) {
    const report_data = {
      "cpcc": dashboardFilters.cpccVisible ? globalState.cpcc?.data : null,
      "cpm": dashboardFilters.cpmVisible ? globalState.cpm?.data : null,
      "cpcd": dashboardFilters.costVisible ? globalState.cpcd?.data : null,
      "reach": dashboardFilters.reachVisible ? globalState.reach?.data : null,
      "costTrend": dashboardFilters.costTrendVisible ? globalState.costTrend?.data : null,
      "totalSpending": dashboardFilters.totalSpendingsVisible ? globalState.totalSpendingsTable?.data?.result : null
    };
    // eslint-disable-next-line no-const-assign
    data = {
      ...data,
      report_data
    };
  }

  return (
    <Modal width={530} name={EXPORT_MODAL} className="rounded">
      <span className="export-title mb-a">Export report as:</span>
      <div>
        {inTable ? (
          <Button
            type="primary"
            style={{ padding: "5px 15px" }}
            className="min-w-150 mr-5"
            onClick={() => onDownloadReport({ ...data, params: { "file_type": "pdf" } }, dispatch)}
          >
            PDF
          </Button>
        ) : (
          <DownloadPDFButton
            type={type}
            data={{
              ...dashboardFilters,
              ...dashboardData,
              funnelData,
              totalSpendingTable: dashboardData?.totalSpending?.data || [],
              totalSpendingValue: globalState.totalSpending,
              file_type: "pdf"
            }}
          />
        )}
        <Button
          type="primary"
          style={{ padding: "5px 15px" }}
          className="min-w-150 mr-5"
          onClick={() => onDownloadReport({ ...data, params: { "file_type": "excel" } }, dispatch)}
        >
          Excel
        </Button>
        <Button
          type="primary"
          style={{ padding: "5px 15px" }}
          className="min-w-150"
          onClick={() => onDownloadReport({ ...data, params: { "file_type": "xml" } }, dispatch)}
        >
          XML
        </Button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  dashboardFilters: state.globalFilters,
  dashboardData: state.dashboardData,
  funnelData: state.funnelData
});

export default connect(mapStateToProps)(ExportModal);
