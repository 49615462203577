export const CREATE_NEW_BUSINESS_RULE = "create-new-business-rule";
export const UPDATE_BUSINESS_RULE = "update-business-rule";
export const METRIC_TYPE_CHANNELS = "Channels";
export const METRIC_TYPE_PUBLISHERS = "Publishers";
export const METRIC_TYPE_PROPERTIES = "Properties";
export const CONSTRAINTS_BUDGET = "Budget";
export const VALUE_IMPRESSION = "impressions/send-outs";
export const VALUE_CONVERSIONS = "conversions";
export const VALUE_CLICKS = "clicks";
export const METRIC_VOLUME = "volume";
export const METRIC_LINEAR = "linear";
export const METRIC_NOT_APPLICABLE = "not_applicable";
export const COSTS_TECH = "technical";
export const COSTS_OPERATIONAL = "operational";
export const COSTS_CONTENT = "content";
export const COSTS_DATA = "data";
export const COSTEUR = "euro";
export const COSTPERCENTAGE = "percentage";
export const EXPORT_MODAL = "export-modal";
export const SAVE_MODAL = "save-modal";

export const publishers = [
  {
    text: METRIC_TYPE_PUBLISHERS,
    value: METRIC_TYPE_PUBLISHERS
  }
];

export const properties = [
  {
    text: METRIC_TYPE_PROPERTIES,
    value: METRIC_TYPE_PROPERTIES
  }
];

export const constraints = [
  {
    text: CONSTRAINTS_BUDGET,
    value: CONSTRAINTS_BUDGET
  }
];

export const values = [
  {
    text: VALUE_IMPRESSION,
    value: VALUE_IMPRESSION
  },
  {
    text: VALUE_CONVERSIONS,
    value: VALUE_CONVERSIONS
  }
];

export const costs = [
  {
    text: COSTS_TECH,
    value: COSTS_TECH
  },
  {
    text: COSTS_OPERATIONAL,
    value: COSTS_OPERATIONAL
  },
  {
    text: COSTS_CONTENT,
    value: COSTS_CONTENT
  },
  {
    text: COSTS_DATA,
    value: COSTS_DATA
  }
];
export const costUnit = [
  {
    text: COSTEUR,
    value: COSTEUR
  },
  {
    text: COSTPERCENTAGE,
    value: COSTPERCENTAGE
  }
];
export const metrics = [
  {
    text: METRIC_VOLUME,
    value: METRIC_VOLUME
  },
  {
    text: METRIC_LINEAR,
    value: METRIC_LINEAR
  }
];
