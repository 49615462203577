import moment from "moment";
import currency from "currency.js";
import numeral from "numeral";
import config from "../config";

export const preventDefault = (e) => e.preventDefault();
export const preventDefaultWithCallback = (func) => (e) => {
  e.preventDefault();
  func();
};
export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatDate = ({ date, format = config.dateFormat.shortFormat, formatIn, emptyValue = "=" }) =>
  date ? moment(date, formatIn).format(format) : emptyValue;

export const getModalData = (modalName) => (state) => {
  if (!modalName) {
    return null;
  }

  if (state.modal && state.modal[modalName]) {
    return state.modal[modalName].data;
  }

  return null;
};

export const addColorOpacity = (color, opacity) => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

export const formatCurrency = (value, format = { symbol: "€", decimal: ",", separator: "." }) => {
  return currency(value || 0).format(format);
};

export const formatNumber = ({ value, format = "0.00a", isCurrency }) => {
  const result = numeral(value || 0).format(format);

  if (isCurrency) {
    return `€${result}`;
  }

  return result;
};

export const formatString = (value, defaultReturn = "") => {
  return value || defaultReturn;
};
