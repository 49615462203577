import { createAction, createReducer } from "@reduxjs/toolkit";

export const initialState = {
  statsSection: [],
  totalSpending: 0,
  overallStats: {},
  topChannels: [],
  weakChannels: [],
  cpm: {},
  cpcc: {},
  cpcd: {},
  costTrend: {},
  reach: {},
  totalSpendingsTable: {},
  planningCenterTable: {},
  metrics: []
};

export const changeGlobalState = createAction("global/change");

export default createReducer(initialState, (builder) => {
  builder.addCase(changeGlobalState, (state, action) => {
    return {
      ...state,
      ...action.payload
    };
  });
});
