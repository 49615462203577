import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { identity, pickBy } from "lodash";
import { useDispatch } from "react-redux";
import axios from "../../../axios";
import Button from "../../../components/Button";
import CheckboxesInput from "../../../components/CheckboxesInput";
import Form from "../../../components/Form";
import { Input } from "../../../components/Input";
import Select from "../../../components/Select";
import {
  CREATE_NEW_BUSINESS_RULE,
  METRIC_LINEAR,
  METRIC_NOT_APPLICABLE,
  costUnit,
  costs,
  metrics,
  values
} from "../config";
import { getModalData } from "../../../utils/common";
import { ReactComponent as CalendarIcon } from "../../../images/icons/calendar.svg";
import DateRangePicker from "../../../components/DateRangePicker";
import { successNotification } from "../../../components/Notification";
import useFormSubmit from "../../../components/Form/useFormSubmit";
import { closeModalAction } from "../../../components/Modal/reducer";
import { prepareFilters } from "../../../components/GlobalFilters/methods";
import { prepareFilterParams } from "../methods";
import { filtersDateFormat } from "../CreateBusinessRule/methods";

const UpdateBusinessRuleForm = ({ data, onCancel }) => {
  // eslint-disable-next-line unused-imports/no-unused-vars, no-unused-vars
  const globalFiltersState = useSelector((state) => state.globalFilters);

  const costManagementModalData = useSelector(getModalData(CREATE_NEW_BUSINESS_RULE));
  const [selectedMetric, setSelectedMetric] = useState(metrics[0]);
  const [selectedValue, setSelectedValue] = useState(values[0]);
  const [selectedCost, setSelectedCost] = useState(costs[0]);
  const [selectedCostUnit, setSelectedCostUnit] = useState(costUnit[0]);
  const [isLoading, setLoading] = useState(false);
  const [localOptions, setLocalOptions] = useState([]);
  const [isShown, setIsShown] = useState(data.split_metric.toLowerCase() === metrics[1].value.toLowerCase());
  const formattedStartDate = new Date(data?.period_start)
    .toLocaleDateString("en-US", { day: "2-digit", month: "2-digit", year: "numeric" })
    .toString();
  const formattedEndDate = new Date(data?.period_end)
    .toLocaleDateString("en-US", { day: "2-digit", month: "2-digit", year: "numeric" })
    .toString();
  const [selectedDate, setSelectedDate] = useState(formattedStartDate + " - " + formattedEndDate);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(globalFiltersState);
  useEffect(() => {
    const initialSelectedChannels = data?.channels;
    const initialSelectedPublishers = data?.publishers;
    const initialSelectedMediaTypes = data?.media_types;
    form.setFields([{ name: "channels", value: initialSelectedChannels }]);
    form.setFields([{ name: "publishers", value: initialSelectedPublishers }]);
    form.setFields([{ name: "mediaTypes", value: initialSelectedMediaTypes }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const initialSelectedChannels = data?.channels;
  const initialSelectedPublishers = data?.publishers;
  const initialSelectedMediaTypes = data?.media_types;

  // eslint-disable-next-line unused-imports/no-unused-vars, no-unused-vars
  const [initialSelectedValues, setInitialSelectedValues] = useState({
    "channels": initialSelectedChannels?.length !== 0 ? initialSelectedChannels.map((channel) => channel?.id) : [],
    "publishers":
      initialSelectedPublishers?.length !== 0 ? initialSelectedPublishers.map((publisher) => publisher?.id) : [],
    "mediaTypes":
      initialSelectedMediaTypes?.length !== 0 ? initialSelectedMediaTypes.map((media_type) => media_type?.id) : []
    // TODO: merge comment
    // const [filtersReq, setFiltersReq] = useState({
    //   "channels": data.channels.map((channel) => channel.channel.id),
    //   "publishers": data.publishers.map((publisher) => publisher.publisher.id),
    //   "mediaTypes": data.media_types.map((media_type) => media_type.media_type.id),
    //   // "publishers": "",
    //   // "properties": "",
    //   // "mediaTypes": "",
    //   // "campaigns": "",
    //   "date": "01/01/2019 - 01/18/2024",
    //   "yoy": false
  });

  const transformer = (res) => {
    return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
  };

  //Used to update filters request payload upon the change of the selected values
  const filtersTransformer = (filters) => {
    const result = filters.map((filter) => filter.id);
    return result;
  };

  function transformDate(startDate, endDate) {
    const [startYear, startMonth, startDay] = startDate.split("-");
    const [endYear, endMonth, endDay] = endDate.split("-");

    const formattedStartDate = `${startMonth.padStart(2, "0")}/${startDay.padStart(2, "0")}/${startYear}`;
    const formattedEndDate = `${endMonth.padStart(2, "0")}/${endDay.padStart(2, "0")}/${endYear}`;

    return `${formattedStartDate} - ${formattedEndDate}`;
  }

  // eslint-disable-next-line unused-imports/no-unused-vars, no-unused-vars
  const [filtersReq, setFiltersReq] = useState({
    "channels": data?.channels?.length !== 0 ? data.channels.map((channel) => channel.id) : [],
    "publishers": data?.publishers?.length !== 0 ? data.publishers.map((publisher) => publisher.id) : [],
    "mediaTypes": data?.media_types?.length !== 0 ? data.media_types.map((media_type) => media_type.id) : [],
    "properties": "",
    "campaigns": "",
    "date": selectedDate,
    "yoy": false
  });

  const getOptionsFromRequest = async () => {
    try {
      setLoading(true);
      const res = await axios["post"]("data/filters_names", filtersReq);
      let transformedOptions = {
        channel: [],
        campaign: [],
        publisher: [],
        property: [],
        media_type: []
      };
      transformedOptions["channel"] = transformer(res.data?.data?.channel || res.data);
      transformedOptions["publisher"] = transformer(res.data?.data?.publisher || res.data);
      transformedOptions["media_type"] = transformer(res.data?.data?.media_type || res.data);

      setLocalOptions(transformedOptions);
      form.setFields([{ name: "channels", value: transformedOptions["channel"] }]);
      form.setFields([{ name: "publishers", value: transformedOptions["publisher"] }]);
      form.setFields([{ name: "mediaTypes", value: transformedOptions["media_type"] }]);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setIsShown((isShown) => !isShown);
  }, [selectedMetric]);
  useEffect(() => {
    getOptionsFromRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const initialFilters = prepareFilters({});
    setFilters(prepareFilters(initialFilters));
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback((type) => (value) => {
    let newFilters;
    if (type === "date") {
      const { type, val } = value || {};
      newFilters = {
        startDate:
          val?.length > 0
            ? String(type) !== "week"
              ? val[0].startOf(String(type)).format(filtersDateFormat)
              : moment(val[0]).format(filtersDateFormat)
            : undefined,
        endDate:
          val?.length > 0
            ? String(type) !== "week"
              ? val[1].endOf(String(type)).format(filtersDateFormat)
              : moment(val[1]).format(filtersDateFormat)
            : undefined
      };
      setSelectedDate(newFilters);
      setFiltersReq({ ...filtersReq, "date": transformDate(newFilters?.startDate, newFilters?.endDate) });
    }
    if (type === "metric") {
      setSelectedMetric({ text: value, value: value });
    }
    if (type === "value") {
      setSelectedValue({ text: value, value: value });
    }
    if (type === "cost") {
      setSelectedCost({ text: value, value: value });
    }
    if (type === "costUnit") {
      setSelectedCostUnit({ text: value, value: value });
    }
  });

  let { loading, generalError, submit } = useFormSubmit({
    form,
    route: "/business_rules/",
    method: "patch",
    onSuccess: (res) => {
      successNotification({
        description: res.msg || "The business rule was updated",
        iconStyle: "icon-color-green"
      });
      costManagementModalData?.onClose();
      onCancel();
      dispatch(closeModalAction({ name: CREATE_NEW_BUSINESS_RULE }));
    },
    transformer: (dataForm) => {
      const selectedChannels = (dataForm.channels || []).map((channel) => channel.id);
      const selectedPublishers = (dataForm.publishers || []).map((publisher) => publisher.id);
      const selectedMediaTypes = (dataForm.mediaTypes || []).map((mediaType) => mediaType.id);
      // Conditionally setting split_values
      const splitValues = selectedMetric.value === METRIC_LINEAR ? METRIC_NOT_APPLICABLE : selectedValue.value;
      // TODO: merge comment
      // const selectedChannels = (dataForm.channels || []).map((channel) => channel.id);
      // const selectedPublishers = (dataForm.publishers || []).map((publisher) => publisher.id);
      // const selectedMediaTypes = (dataForm.mediaTypes || []).map((mediaType) => mediaType.id);
      const dataToSend = {
        "br_id": data.id,
        "title": dataForm.title,
        "cost_type": selectedCost.value,
        "cost_value": dataForm.cost_value,
        "cost_unit": selectedCostUnit.value,
        "channels": selectedChannels,
        "publishers": selectedPublishers,
        "media_types": selectedMediaTypes,
        "split_metric": selectedMetric.value,
        "split_values": splitValues,
        "period_start": selectedDate.startDate,
        "period_end": selectedDate.endDate
      };

      return pickBy(dataToSend, identity);
    }
  });

  return (
    <Form onSubmit={submit} form={form} errors={generalError} loading={loading} requiredMark={false}>
      <div>
        <Form.Item
          label="Business Rule Name"
          name="title"
          validateTrigger={["onBlur", "onChange"]}
          rules={[
            {
              required: true,
              message: "Add a business rule name"
            }
          ]}
          initialValue={data.title}
        >
          <Input placeholder="Rule Name" />
        </Form.Item>
      </div>
      <div className="group-two-columns">
        <Form.Item name="date">
          <DateRangePicker
            showArrow
            allowClear
            yoyDisabled
            icon={<CalendarIcon />}
            placement="bottomRight"
            placeholder="Select date"
            onChange={onChange("date")}
            defaultValue={{
              type: "week",
              val: [moment(data.period_start, filtersDateFormat), moment(data.period_end, filtersDateFormat)]
            }}
            apiRoute="data/disable_dates"
            requestMethod="post"
            deleteYoyFunctionality
          />
        </Form.Item>
      </div>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 20 }} className="group-3-">
        <div style={{ flex: 1 }}>
          <Form.Item name="channels">
            <CheckboxesInput
              showArrow
              selectAll
              searchable
              allowClear
              selectedValue={form.getFieldValue("channels") || initialSelectedValues?.channels}
              apiRoute="data/filters_names"
              requestMethod="post"
              requestBody={prepareFilterParams({ ...filters }, {}, "channel")}
              onChange={(newValue) => {
                setFiltersReq({ ...filtersReq, "channels": filtersTransformer(newValue) });
                form.setFields([{ name: "channels", value: newValue }]);
              }}
              placeholder="Channels"
              transformer={(res) => {
                return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
              }}
              isLoading={isLoading}
              options={localOptions["channel"]}
              mmm={false}
              initialSelectedValues={initialSelectedValues?.channels}
              getOptionsFromRequest={getOptionsFromRequest}
              dropdownName="channel"
            />
          </Form.Item>
        </div>
        <div style={{ flex: 1 }}>
          <Form.Item name="publishers">
            <CheckboxesInput
              showArrow
              selectAll
              searchable
              allowClear
              selectedValue={form.getFieldValue("publishers") || initialSelectedValues?.publishers}
              onChange={(newValue) => {
                setFiltersReq({ ...filtersReq, "publishers": filtersTransformer(newValue) });
                form.setFields([{ name: "publishers", value: newValue }]);
              }}
              apiRoute="data/filters_names"
              requestMethod="post"
              placeholder="Publishers"
              requestBody={prepareFilterParams({ ...filters }, {}, "publisher")}
              transformer={(res) => {
                return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
              }}
              isLoading={isLoading}
              options={localOptions["publisher"]}
              initialSelectedValues={initialSelectedValues?.publishers}
              getOptionsFromRequest={getOptionsFromRequest}
              dropdownName="publisher"
            />
          </Form.Item>
        </div>
        <div style={{ flex: 1 }}>
          <Form.Item name="mediaTypes">
            <CheckboxesInput
              showArrow
              selectAll
              searchable
              allowClear
              selectedValue={form.getFieldValue("mediaTypes") || initialSelectedValues?.mediaTypes}
              onChange={(newValue) => {
                setFiltersReq({ ...filtersReq, "mediaTypes": filtersTransformer(newValue) });
                setInitialSelectedValues({ ...initialSelectedValues, "mediaTypes": filtersTransformer(newValue) });
                form.setFields([{ name: "mediaTypes", value: newValue }]);
              }}
              placeholder="mediaTypes"
              apiRoute="data/filters_names"
              requestMethod="post"
              requestBody={prepareFilterParams({ ...filters }, {}, "mediaType")}
              transformer={(res) => {
                return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
              }}
              isLoading={isLoading}
              options={localOptions["media_type"]}
              initialSelectedValues={initialSelectedValues?.mediaTypes}
              getOptionsFromRequest={getOptionsFromRequest}
              dropdownName="media type"
            />
          </Form.Item>
        </div>
      </div>
      <div className="group-three-columns">
        <div>
          <Form.Item label="Choose Cost Type" name="cost_type">
            <Select
              getPopupContainer={false}
              options={costs}
              onChange={onChange("cost")}
              defaultValue={data.cost_type.charAt(0).toUpperCase() + data.cost_type.slice(1).toLowerCase()}
              placeholder="Cost Type"
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="cost_value"
            validateTrigger={["onBlur", "onChange"]}
            label=" "
            rules={[
              {
                required: true,
                message: "Add a cost value"
              }
            ]}
            initialValue={data.cost_value}
          >
            <Input
              placeholder="Cost in % or EUR"
              onKeyPress={(event) => {
                if (!/[0-9.-]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item label=" " name="cost_unit">
            <Select
              getPopupContainer={false}
              options={costUnit}
              onChange={onChange("costUnit")}
              defaultValue={data.cost_unit}
              placeholder="Cost Unit"
            />
          </Form.Item>
        </div>
      </div>
      <div className="group-two-columns mb-20">
        <div>
          <Form.Item label="Split by Metric" name="metric_type">
            <Select
              defaultValue={data.split_metric.charAt(0).toUpperCase() + data.split_metric.slice(1).toLowerCase()}
              onChange={onChange("metric")}
              getPopupContainer={false}
              options={metrics}
              placeholder="Set Metric"
            />
          </Form.Item>
        </div>
        {isShown && (
          <div>
            <Form.Item label="Split on Values" name="value_type">
              <Select
                defaultValue={data.split_values.charAt(0).toUpperCase() + data.split_values.slice(1).toLowerCase()}
                onChange={onChange("value")}
                getPopupContainer={false}
                options={values}
                placeholder="Set Value"
              />
            </Form.Item>
          </div>
        )}
      </div>

      <Form.Item shouldUpdate>
        {() => (
          <div className="form-actions flex flex-justify-end flex-wrap">
            <Button
              type="primary"
              htmlType="submit"
              disabled={isLoading || loading || form.getFieldsError().filter(({ errors }) => errors.length).length > 0}
              className="min-w-130"
            >
              Update
            </Button>
          </div>
        )}
      </Form.Item>
    </Form>
  );
};

export default UpdateBusinessRuleForm;
