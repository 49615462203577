import React, { useCallback, useState } from "react";
import { Layout } from "antd";
import { connect, useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { ReactComponent as ExportIcon } from "../../images/icons/export.svg";
import { ReactComponent as SaveDocumentIcon } from "../../images/icons/add-document.svg";
import { EXPORT_MODAL, SAVE_MODAL } from "../../pages/MyReports/config";
import { openModalAction } from "../Modal/reducer";
import ExportModal from "../../pages/MyReports/Modals/ExportModal";
import SaveModal from "../../pages/MyReports/Modals/SaveModal";
import routes from "../../pages/App/routes";
import NavigationMenu from "./NavigationMenu";
/**
 * Sidebar
 * @function Sidebar
 * @description Sidebar component
 */
const Sidebar = ({ collapsed, handleCollapse, firstClicked, setFirstClicked }) => {
  const globalState = useSelector((state) => state.global);
  const { pathname } = useLocation();
  const [hoverCollapsed, setHoverCollapsed] = useState(false);
  const dispatch = useDispatch();

  const collapse = useCallback(
    (e) => {
      if (collapsed) {
        e.preventDefault();
        handleCollapse(!collapsed);
        setHoverCollapsed(true);
      }
    },
    [collapsed, handleCollapse]
  );
  const uncollapse = useCallback(
    (e) => {
      if (hoverCollapsed) {
        e.preventDefault();
        handleCollapse(!collapsed);
        setHoverCollapsed(false);
      }
    },
    [collapsed, handleCollapse, hoverCollapsed]
  );
  return (
    <div onMouseEnter={collapse} onMouseLeave={uncollapse}>
      <Layout.Sider
        className="layout-sidebar"
        trigger={null}
        collapsible
        onCollapse={handleCollapse}
        collapsed={collapsed}
        width={270}
        collapsedWidth={120}
      >
        <div className="layout-sidebar-navigation-container">
          <NavigationMenu collapsed={collapsed} firstClicked={firstClicked} setFirstClicked={setFirstClicked} />
          {(pathname === routes.DASHBOARD_PAGE || pathname === routes.SANDBOX) && (
            <div className="sidebar-report-buttons">
              <ExportModal
                type={
                  pathname === routes.DASHBOARD_PAGE ? "DASHBOARD_PAGE" : pathname === routes.SANDBOX ? "SANDBOX" : ""
                }
              />
              <SaveModal
                type={
                  pathname === routes.DASHBOARD_PAGE ? "DASHBOARD_PAGE" : pathname === routes.SANDBOX ? "SANDBOX" : ""
                }
              />
              <a
                disabled={
                  globalState?.totalSpending?.loading ||
                  globalState?.cpcc?.loading ||
                  globalState?.cpm?.loading ||
                  globalState?.reach?.loading
                }
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    globalState?.totalSpending?.loading ||
                    globalState?.cpcc?.loading ||
                    globalState?.cpm?.loading ||
                    globalState?.reach?.loading
                  ) {
                    return;
                  }

                  dispatch(openModalAction({ name: EXPORT_MODAL }));
                }}
              >
                <ExportIcon />
                <span>Export Report</span>
              </a>
              <a
                disabled={
                  globalState?.totalSpending?.loading ||
                  globalState?.cpcc?.loading ||
                  globalState?.cpm?.loading ||
                  globalState?.reach?.loading
                }
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    globalState?.totalSpending?.loading ||
                    globalState?.cpcc?.loading ||
                    globalState?.cpm?.loading ||
                    globalState?.reach?.loading
                  ) {
                    return;
                  }
                  dispatch(openModalAction({ name: SAVE_MODAL }));
                }}
              >
                <SaveDocumentIcon />
                <span>Save Report</span>
              </a>
            </div>
          )}
        </div>
      </Layout.Sider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dashboardData: state.dashboardData
});

export default connect(mapStateToProps)(Sidebar);
