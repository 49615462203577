import isArray from "lodash/isArray";
import isString from "lodash/isString";
import isObject from "lodash/isObject";

export const antdSelectFilterOption = (input, option) => {
  let children = option.props.children || [];

  if (isObject(children)) {
    children = option.props.title || [];
  }

  if (isArray(children) && children.length) {
    return children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  return children && isString(children) ? children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false;
};
