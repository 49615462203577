import isNumber from "lodash/isNumber";
import moment from "moment";
import pattern from "patternomaly";
import { addColorOpacity } from "../../../utils/common";
import config from "../../../config";

export const barChartOptions = (isYOYActive) => {
  return {
    responsive: true,
    aspectRatio: 2,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: isYOYActive ? true : false
      },
      tooltip: {
        callbacks: {
          ...(isYOYActive
            ? {
                title: (item) => {
                  return item[0].dataset.yoy ? `YOY ${item[0].label}` : item[0].label;
                }
              }
            : {})
        }
      }
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          offset: true,
          display: false,
          drawBorder: false
        },
        ticks: {
          padding: 0,
          color: "#333333",
          font: {
            size: 12,
            family: "'Heebo-Medium 500'"
          }
        }
      },
      y: {
        stacked: false,
        beginAtZero: true,
        ticks: {
          padding: 15,
          color: "#002060",
          font: {
            size: 12,
            family: "'Heebo-Medium 500'"
          }
        },
        grid: {
          drawBorder: false,
          color: "rgba(85, 136, 238, 0.22)"
        }
      }
    }
  };
};

export const stackedBarChartOptions = (isYOYActive) => {
  return {
    responsive: true,
    aspectRatio: 2,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: isYOYActive ? true : false
      },
      tooltip: {
        callbacks: {
          ...(isYOYActive
            ? {
                title: (item) => {
                  return item[0].dataset.yoy ? `YOY ${item[0].label}` : item[0].label;
                }
              }
            : {})
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          offset: true,
          display: false,
          drawBorder: false
        },
        ticks: {
          padding: 0,
          color: "#333333",
          font: {
            size: 12,
            family: "'Heebo-Medium 500'"
          }
        }
      },
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          padding: 15,
          color: "#002060",
          font: {
            size: 12,
            family: "'Heebo-Medium 500'"
          }
        },
        grid: {
          drawBorder: true,
          color: "rgba(85, 136, 238, 0.22)"
        }
      }
    }
  };
};

export const formatBarChartData = ({
  data = [],
  avgChart = false,
  nameKey = "channel",
  valueKey = "cpcc",
  colorKey = "color",
  multipleValueKeys = [],
  multipleLabels = [],
  isYOYActive,
  visible
}) => {
  const filteredData =
    visible === undefined
      ? [...data]
      : data.filter((item) => visible.map((item) => item.name).indexOf(item[`original_${nameKey}`]) > -1);

  const updateDatasetsData = (datasetsData, idxToUpdate, value) => {
    const result = [...datasetsData];
    if (idxToUpdate >= result.length) {
      result.push([parseFloat(value)]);
    } else {
      result[idxToUpdate].push(parseFloat(value));
    }
    return result;
  };

  const updateColors = (colorsData, idxToUpdate, value) => {
    const result = [...colorsData];
    result[idxToUpdate] = result[idxToUpdate] || value;
    return result;
  };

  const sumData = filteredData.reduce(
    (result, item) => {
      let labelIdx = result.labels.findIndex((label) => label === item[nameKey]);

      if (labelIdx === -1) {
        result.labels.push(item[nameKey]);
        labelIdx = result.labels.length - 1;
      }

      if (multipleValueKeys && multipleValueKeys.length > 0) {
        // If multipleValueKeys is not empty, create datasets for each key
        multipleValueKeys.forEach((key, index) => {
          result.datasets[index] = result.datasets[index] || {
            data: [],
            borderColor: [],
            backgroundColor: [],
            maxBarThickness: 64,
            borderWidth: 0, // Adjust thickness of the lines as needed
            borderRadius: 8
          };

          result.datasets[index] = {
            ...result.datasets[index],
            label: multipleLabels.length > 0 ? multipleLabels[index] : result.datasets[index].label,
            data: updateDatasetsData(result.datasets[index].data, labelIdx, roundNumber(item[key])),
            backgroundColor: updateColors(
              result.datasets[index].backgroundColor,
              labelIdx,
              index === 1 ? pattern.draw("diagonal", item[colorKey]) : item[colorKey]
            )
          };
        });
      } else {
        // If multipleValueKeys is empty, use only one dataset
        result.datasets[0] = {
          ...result.datasets[0],
          data: updateDatasetsData(result.datasets[0].data, labelIdx, roundNumber(item[valueKey])),
          borderColor: updateColors(result.datasets[0].borderColor, labelIdx, item[colorKey]),
          backgroundColor: updateColors(result.datasets[0].backgroundColor, labelIdx, item[colorKey])
        };
      }

      return result;
    },
    {
      labels: [],
      datasets: [
        {
          data: [],
          borderColor: [],
          backgroundColor: [],
          maxBarThickness: 64,
          borderWidth: 0,
          borderRadius: 8
        }
      ]
    }
  );

  if (avgChart) {
    sumData.datasets.forEach((dataset) => {
      dataset.data = dataset.data.map(function (n) {
        return n.reduce((a, b) => a + b, 0) / n.length;
      });
    });
  } else {
    sumData.datasets.forEach((dataset) => {
      dataset.data = dataset.data.map(function (n) {
        return n.reduce((a, b) => a + b, 0);
      });
    });
  }

  var dict = [];
  var dict1 = [];
  for (var i = 0; i < sumData.datasets[0].data.length; i++) {
    dict.push({
      channel: sumData.labels[i],
      value: sumData.datasets[0].data[i],
      backgroundColor: sumData.datasets[0].backgroundColor[i],
      borderColor: sumData.datasets[0].borderColor[i]
    });
  }
  if (multipleValueKeys && multipleValueKeys.length !== 0 && sumData.datasets[1]) {
    for (i = 0; i < sumData.datasets[1].data.length; i++) {
      dict1.push({
        channel: sumData.labels[i],
        value: sumData.datasets[1].data[i],
        backgroundColor: sumData.datasets[1].backgroundColor[i],
        borderColor: sumData.datasets[1].borderColor[i]
      });
    }
  }
  var keysSorted = Object.values(dict).sort(function (a, b) {
    return b.value - a.value;
  });
  var labelsTmp = [];
  var dataTmp = [];
  var backgroundColorsTmp = [];
  var borderColorsTmp = [];

  for (var elem in keysSorted) {
    labelsTmp.push(keysSorted[elem].channel);
    dataTmp.push(keysSorted[elem].value);
    backgroundColorsTmp.push(keysSorted[elem].backgroundColor);
    borderColorsTmp.push(keysSorted[elem].borderColor);
  }

  sumData.datasets[0].data = dataTmp;
  sumData.labels = labelsTmp;
  sumData.datasets[0].backgroundColor = backgroundColorsTmp;
  sumData.datasets[0].borderColor = borderColorsTmp;
  if (multipleValueKeys && multipleValueKeys.length !== 0 && sumData.datasets[1]) {
    var summedData = [];
    var summedBackgroundColors = [];

    // Loop through labelsTmp to maintain order
    labelsTmp.forEach((label) => {
      const item = dict1.find((item) => item.channel === label);
      if (item) {
        summedData.push(item.value);
        summedBackgroundColors.push(item.backgroundColor);
      } else {
        summedData.push(0);
        summedBackgroundColors.push(null);
      }
    });
    sumData.datasets[1].data = summedData;
    sumData.datasets[1].backgroundColor = summedBackgroundColors;

    return sumData;
  }
  if (isYOYActive) {
    const resultYOYActive = {
      labels: [],
      datasets: [
        {
          maxBarThickness: 64,
          borderWidth: 2,
          borderColor: "white",
          borderRadius: 8,
          minBarLength: 5,
          data: [],
          backgroundColor: [],
          label: "Current Year",
          barPercentage: 1
        },
        {
          maxBarThickness: 64,
          borderWidth: 0,
          borderRadius: 8,
          yoy: true,
          minBarLength: 5,
          data: [],
          backgroundColor: [],
          label: "Previous Year",
          barPercentage: 1
        }
      ]
    };

    labelsTmp.forEach((label) => {
      if (label && !label.includes("YOY")) {
        resultYOYActive.labels.push(label);
      }
    });

    resultYOYActive.labels.forEach((label) => {
      const labelTmpIdxMatch = labelsTmp.findIndex((lab) => lab === label);
      if (labelTmpIdxMatch !== -1) {
        resultYOYActive.datasets[0].data.push(dataTmp[labelTmpIdxMatch]);
        resultYOYActive.datasets[0].backgroundColor.push(backgroundColorsTmp[labelTmpIdxMatch]);
      }

      const labelYOYIdxMatch = labelsTmp.findIndex((lab) => lab.includes(label) && lab !== label);
      resultYOYActive.datasets[1].data.push(labelYOYIdxMatch !== -1 ? dataTmp[labelYOYIdxMatch] : undefined);
      resultYOYActive.datasets[1].backgroundColor.push(
        labelYOYIdxMatch !== -1 ? pattern.draw("diagonal", backgroundColorsTmp[labelYOYIdxMatch]) : undefined
      );
    });

    return resultYOYActive;
  }

  return sumData;
};
const lightenColor = (color, amount) => {
  color = color.replace(/^#/, "");

  if (color.length === 3) {
    color = color.replace(/(.)/g, "$1$1");
  }

  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);

  const newR = Math.min(255, r + amount);
  const newG = Math.min(255, g + amount);
  const newB = Math.min(255, b + amount);

  return `#${(newR * 65536 + newG * 256 + newB).toString(16).padStart(6, "0")}`;
};

export const formatCostBarChartData = ({
  data = [],
  nameKey = "channel",
  valueKey = "cost_type",
  costTypeKey = "cost_type",
  colorKey = "color",
  visible,
  visibleCostTypes,
  ratio
}) => {
  const filteredData =
    visible === undefined
      ? [...data]
      : data.filter((item) => visible.map((item) => item.name).indexOf(item[`original_${nameKey}`]) > -1);

  const labels = [...new Set(filteredData.map((item) => item[nameKey]))];
  const datasets = [];

  const labelColors = filteredData.reduce((colors, item) => {
    if (!colors[item[nameKey]]) {
      colors[item[nameKey]] = item[colorKey];
    }
    return colors;
  }, {});

  // Initialize datasets
  const costTypes = [...new Set(filteredData.map((item) => item[costTypeKey]))];
  const costTypesValues = ["TECHNICAL", "OPERATIONAL", "CONTENT", "DATA", "ALL_COST"];
  costTypes.sort((a, b) => costTypesValues.indexOf(a) - costTypesValues.indexOf(b));
  let brightnessIncrease = -10;
  costTypes.forEach((costType) => {
    datasets.push({
      label: costType,
      data: Array(labels.length).fill(0),
      backgroundColor: labels.map((label) => lightenColor(labelColors[label], brightnessIncrease)),
      borderColor: labels.map((label) => lightenColor(labelColors[label], brightnessIncrease)),
      borderWidth: 1,
      borderRadius: 8
    });
    brightnessIncrease += 20;
  });

  let sumAllCostTypes = labels.map((label) => {
    return filteredData.reduce((total, currItem) => {
      if (currItem[nameKey] === label) {
        return total + currItem[valueKey];
      }
      return total;
    }, 0);
  });
  // Calculate the ratio of the current cost type to the sum of all cost types for each channel
  // Fill datasets with data
  filteredData.forEach((item) => {
    const labelIndex = labels.indexOf(item[nameKey]);
    const costTypeIndex = costTypes.indexOf(item[costTypeKey]);
    if (visibleCostTypes && visibleCostTypes.indexOf(item[costTypeKey]) === -1) {
      // If cost type is not in visibleCostTypes, set data to 0
      datasets[costTypeIndex].data[labelIndex] = 0;
    } else {
      if (ratio) {
        // Calculate the sum of all cost types for each channel
        datasets[costTypeIndex].data[labelIndex] =
          (datasets[costTypeIndex].data[labelIndex] || 0) +
          parseFloat(item[valueKey]) / parseFloat(sumAllCostTypes[labelIndex]);
      } else {
        datasets[costTypeIndex].data[labelIndex] =
          (datasets[costTypeIndex].data[labelIndex] || 0) + parseFloat(item[valueKey]);
      }
    }
  });

  return {
    labels,
    datasets
  };
};

let shouldResetLineColors = false;
export const lineChartOptions = (isYOYActive) => {
  return {
    responsive: true,
    aspectRatio: 2,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          filter: function (legendItem) {
            return typeof legendItem?.text === "string" && !legendItem.text.startsWith("YOY");
          }
        }
      },
      tooltip: {
        callbacks: {
          label: (item) => {
            return [item.dataset.label, item.raw.y];
          },
          ...(isYOYActive
            ? {
                title: (item) => {
                  let tooltipTitle = moment(item[0].raw.x).format(config.dateFormat.shortFormat);

                  if (item[0].dataset.yoy) {
                    tooltipTitle = moment(item[0].raw.x).add(-1, "year").format(config.dateFormat.shortFormat);
                  }

                  return tooltipTitle;
                }
              }
            : {})
        }
      }
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "month",
          tooltipFormat: config.dateFormat.shortFormat,
          displayFormats: {
            day: "DD/MM/YY",
            ...(isYOYActive ? { month: "MMM" } : {})
          }
        },
        grid: {
          display: false,
          drawBorder: false
        },
        bounds: "data",
        ticks: {
          maxTicksLimit: window.innerWidth < 1300 ? 10 : 15,
          padding: 5,
          major: true,
          color: "#333333",
          minRotation: 0,
          maxRotation: 45,
          font: {
            size: 12,
            family: "'Heebo-Medium 500'"
          }
        }
      },
      y: {
        ticks: {
          padding: 15,
          color: "#002060",
          font: {
            size: 12,
            family: "'Heebo-Medium 500'"
          }
        },
        grid: {
          drawBorder: false,
          color: "rgba(85, 136, 238, 0.22)"
        }
      }
    },
    events: ["mousemove", "mouseout", "touchstart", "touchmove"],
    onHover: (evt, activeElements, chart) => {
      if (shouldResetLineColors) {
        swapActiveChartColors(chart, null, null);
        shouldResetLineColors = false;
      }

      if (activeElements.length > 0) {
        shouldResetLineColors = true;
        swapActiveChartColors(chart, null, activeElements[0].datasetIndex);
      }
    }
  };
};

export const pieChartOptions = (isYOYActive) => {
  const options = {
    aspectRatio: 1,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.label || "";
            if (label) {
              label += ": ";
            }
            let sum = 0;
            let dataArr = context.chart.data.datasets[0].data;
            // eslint-disable-next-line array-callback-return
            dataArr.map((data) => {
              sum += data;
            });
            label += ((context.parsed * 100) / sum).toFixed(2) + "%";
            return label;
          }
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false
  };

  if (isYOYActive) {
    options.plugins.legend = {
      display: false,
      labels: {
        generateLabels(chart) {
          const data = chart.data;
          if (data.labels.length && data.datasets.length) {
            const {
              labels: { pointStyle }
            } = chart.legend.options;
            return data.labels.map((label, i) => {
              const meta = chart.getDatasetMeta(0);
              const style = meta.controller.getStyle(i);
              return {
                text: label.substring("YOY".length + 1),
                fillStyle: style.backgroundColor,
                strokeStyle: style.borderColor,
                lineWidth: style.borderWidth,
                pointStyle: pointStyle,
                hidden: !chart.getDataVisibility(i),
                index: i
              };
            });
          }
        }
      }
    };
  }
  return options;
};

export const formatLineChartData = ({
  data = [],
  nameKey = "channel",
  valueKey = "cpcc",
  timeKey = "date",
  colorKey = "color",
  visible
}) => {
  const filteredData =
    visible === undefined
      ? [...data]
      : data.filter((item) => visible.map((item) => item.name).indexOf(item[`original_${nameKey}`]) > -1);
  return filteredData.reduce((result, item) => {
    const existingLabelIdx = result.findIndex((resultItem) => resultItem.label === item[nameKey]);

    if (existingLabelIdx > -1) {
      result[existingLabelIdx]["data"].push({
        x: moment(item[timeKey], config.dateFormat.api),
        y: roundNumber(item[valueKey])
      });
    } else {
      result.push({
        originalLabel: item[`original_${nameKey}`],
        label: item[nameKey],
        originalColor: item[colorKey] || "#52B788",
        borderColor: item[colorKey] || "#52B788",
        backgroundColor: item[colorKey] || "#52B788",
        original_date: item.original_date,
        yoy: item.yoy,
        borderWidth: 2,
        tension: 0,
        borderDash: item.yoy ? [8, 4] : [],
        data: [
          {
            x: moment(item[timeKey], config.dateFormat.api),
            y: roundNumber(item[valueKey])
          }
        ]
      });
    }
    return result;
  }, []);
};

export const formatCostTrendLineChartData = ({ data = [], valueKey = "cpcc", timeKey = "date", visible }) => {
  const filteredData =
    visible === undefined ? [...data] : data.filter((item) => visible.map((item) => item).indexOf(item.cost_type) > -1);

  const groupedData = filteredData.reduce((result, item) => {
    const costType = item.cost_type;

    // Exclude "ALL_COSTS"
    if (!result[costType]) {
      result[costType] = [];
    }
    result[costType].push(item);

    return result;
  }, {});

  return Object.keys(groupedData).map((costType) => {
    const analysisType = costAnalysisTypes.find((type) => type.value === costType);
    const color = analysisType ? analysisType.costTrendColor : "#52B788";

    return {
      originalLabel: costType,
      label: costType,
      originalColor: color,
      borderColor: color,
      backgroundColor: color,
      borderWidth: 2,
      tension: 0,
      data: groupedData[costType].map((item) => ({
        x: moment(item[timeKey], config.dateFormat.api),
        y: roundNumber(item[valueKey])
      }))
    };
  });
};

function round(num) {
  var m = Number((Math.abs(num) * 1000).toPrecision(15));
  return (Math.round(m) / 1000) * Math.sign(num);
}

export const prepareDataLegends = ({ data = [], nameKey = "channel", colorKey = "color" }) => {
  return data.reduce((result, item, idx) => {
    let modifiedNameKey = item[nameKey] || "";
    // Check if the nameKey starts with "YOY "
    if (modifiedNameKey && modifiedNameKey.startsWith("YOY ")) {
      // Remove "YOY " and the space after it from the nameKey
      modifiedNameKey = modifiedNameKey.substring(4);
    }

    const exists = result.findIndex((resItem) => resItem.name === modifiedNameKey);
    if (exists === -1) {
      result.push({
        id: idx,
        name: modifiedNameKey,
        color: item[colorKey] || "#03215E",
        value:
          "reach" in item
            ? round(item["reach"])
            : "cpcc" in item
            ? round(item["cpcc"])
            : "cpm" in item
            ? round(item["cpm"])
            : "",
        count: 1
      });
    } else {
      result[exists].value +=
        "reach" in item
          ? round(item["reach"])
          : "cpcc" in item
          ? round(item["cpcc"])
          : "cpm" in item
          ? round(item["cpm"])
          : "";
      result[exists].count += 1;
    }

    if (item["reach"] === undefined) {
      result = result.map(function (e) {
        e.value = round(e.value / e.count);
        e.count = 1;
        return e;
      });
    }

    result.sort((a, b) => b.value - a.value);
    return result;
  }, []);
};

export const swapActiveChartColors = (chart, activeDataset, activeIdx) => {
  if (chart) {
    const changeDatasetsLineColorOmittingIdx = isNumber(activeIdx)
      ? activeIdx
      : activeDataset
      ? chart.data.datasets.findIndex((item) => typeof item.label === "string" && item.label.includes(activeDataset))
      : undefined;

    const activeDatasetName =
      typeof chart.data.datasets[changeDatasetsLineColorOmittingIdx]?.originalLabel === "string"
        ? chart.data.datasets[changeDatasetsLineColorOmittingIdx]?.originalLabel
        : undefined;

    const activeDatasetIndexes = activeDatasetName
      ? chart.data.datasets.reduce((res, item, idx) => {
          if (item.originalLabel === activeDatasetName) {
            res.push(idx);
          }
          return res;
        }, [])
      : [];

    if (activeDatasetIndexes.length > 0) {
      chart.data.datasets.forEach((dataset, idx) => {
        if (!activeDatasetIndexes.includes(idx)) {
          const opacityColor = addColorOpacity(chart.data.datasets[idx].originalColor, 0.1);
          chart.data.datasets[idx].backgroundColor = opacityColor;
          chart.data.datasets[idx].borderColor = opacityColor;
        }
      });
    } else {
      chart.data.datasets.forEach((dataset, idx) => {
        chart.data.datasets[idx].backgroundColor = chart.data.datasets[idx].originalColor;
        chart.data.datasets[idx].borderColor = chart.data.datasets[idx].originalColor;
      });
    }

    chart.update();
  }
};
export const analysisTypes = [
  { value: "channel", text: "Channel" },
  { value: "campaign", text: "Campaign" },
  { value: "publisher", text: "Publisher" },
  { value: "property", text: "Property" },
  { value: "media_type", text: "Media type" },
  { value: "media_cluster", text: "Media cluster" },
  { value: "audience", text: "Audience" },
  { value: "product", text: "Product" },
  { value: "product_group", text: "Product group" }
];

export const costAnalysisTypes1 = [
  { value: "TECHNICAL", text: "Technical costs" },
  { value: "OPERATIONAL", text: "Operational costs" },
  { value: "CONTENT", text: "Content costs" },
  { value: "DATA", text: "Data costs" },
  { value: "ALL_COSTS", text: "All costs" }
];
export const analysisTypes3 = [
  { value: "channel", text: "Channel" },
  { value: "publisher", text: "Publisher" },
  { value: "media_type", text: "Media type" }
];

export const costAnalysisTypes = [
  { value: "TECHNICAL", name: "Technical costs", color: "#183359", id: 1, costTrendColor: "#005A66" },
  {
    value: "OPERATIONAL",
    name: "Operational costs",
    color: lightenColor("#183359", 20),
    id: 2,
    costTrendColor: "#3bbeb4"
  },
  {
    value: "CONTENT",
    name: "Content costs",
    color: lightenColor("#183359", 40),
    id: 3,
    costTrendColor: "#df9547"
  },
  { value: "DATA", name: "Data costs", color: lightenColor("#183359", 60), id: 4, costTrendColor: "#009EB3" },
  { value: "ALL_COSTS", name: "Pure costs", color: lightenColor("#183359", 50), id: 5, costTrendColor: "#2D6C4F" }
];

export const mapAnalysisTypesToCPCC = (analysisType) => {
  switch (analysisType) {
    case analysisTypes[3].value:
      return "properties";
    case analysisTypes[4].value:
      return "media types";
    case analysisTypes[5].value:
      return "media clusters";
    default:
      return analysisType + "s";
  }
};

export const getChartNameKeyBasedOnAnalysisType = (analysisType) => {
  if (analysisType === analysisTypes[0].value) {
    return "channel";
  }

  if (analysisType === analysisTypes[1].value) {
    return "campaign";
  }

  if (analysisType === analysisTypes[2].value) {
    return "publisher";
  }

  if (analysisType === analysisTypes[3].value) {
    return "property";
  }

  if (analysisType === analysisTypes[4].value) {
    return "media_type";
  }
  if (analysisType === analysisTypes[5].value) {
    return "media_cluster";
  }
  if (analysisType === analysisTypes[6].value) {
    return "audience";
  }
  if (analysisType === analysisTypes[7].value) {
    return "product";
  }
  if (analysisType === analysisTypes[8].value) {
    return "product_group";
  }
  return "channel";
};

export const roundNumber = (val) => {
  let decimalsLength = 0;
  if (val) {
    const numToString = `${val}`;
    if (numToString.indexOf(".") > -1) {
      decimalsLength = `${val}`.split(".").length;
    }
  }

  if (decimalsLength) {
    return val.toFixed(decimalsLength > 1 ? 2 : val);
  }

  return val;
};
