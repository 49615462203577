import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import "chartjs-adapter-moment";
import classNames from "classnames";
import { connect } from "react-redux";
import Preloader from "../../../components/Preloader";
import NoData from "../../../components/NoData";
import { formatBarChartData, pieChartOptions } from "./methods";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = (props) => {
  const { data = [], visibleDatasets, loading, parserKeys = {}, chartName, dashboardFilters } = props;

  const noDataToShow = !loading && (!data || data.length === 0);

  if (dashboardFilters?.yoy) {
    return (
      <div className="yoy-pie-charts">
        <div className={classNames("line-chart-container", { loading, "no-data": noDataToShow })}>
          <div className="text-center mb-10">Previous Year</div>
          {loading && <Preloader size={30} />}
          {!loading && (!data || data.length === 0) && (
            <div className="no-chart-data">
              <NoData />
            </div>
          )}
          <Pie
            id={chartName}
            options={pieChartOptions(true)}
            data={formatBarChartData({
              data: data.filter((item) => item.yoy),
              visible: visibleDatasets,
              avgChart: true,
              nameKey: parserKeys.nameKey,
              valueKey: parserKeys.valueKey
            })}
          />
        </div>
        <div className={classNames("line-chart-container", { loading, "no-data": noDataToShow })}>
          <div className="text-center mb-10">Current Year</div>
          {loading && <Preloader size={30} />}
          {!loading && (!data || data.length === 0) && (
            <div className="no-chart-data">
              <NoData />
            </div>
          )}
          <Pie
            id={chartName + "2"}
            options={pieChartOptions(false)}
            data={formatBarChartData({
              data: data.filter((item) => !item.yoy),
              visible: visibleDatasets,
              avgChart: true,
              nameKey: parserKeys.nameKey,
              valueKey: parserKeys.valueKey
            })}
          />
        </div>
      </div>
    );
  }

  if (parserKeys.multipleLabels && parserKeys.multipleLabels.length > 0) {
    return (
      <div className="yoy-pie-charts mb-25">
        <div className={classNames("line-chart-container", { loading, "no-data": noDataToShow })}>
          <div className="text-center mb-10">{parserKeys.multipleLabels[0]}</div>
          {loading && <Preloader size={30} />}
          {!loading && (!data || data.length === 0) && (
            <div className="no-chart-data">
              <NoData />
            </div>
          )}
          <Pie
            id={chartName}
            options={pieChartOptions(false)}
            data={formatBarChartData({
              data: data,
              visible: visibleDatasets,
              avgChart: true,
              nameKey: parserKeys.nameKey,
              valueKey: parserKeys.multipleValueKeys[0]
            })}
          />
        </div>
        <div className={classNames("line-chart-container", { loading, "no-data": noDataToShow })}>
          <div className="text-center mb-10">{parserKeys.multipleLabels[1]}</div>
          {loading && <Preloader size={30} />}
          {!loading && (!data || data.length === 0) && (
            <div className="no-chart-data">
              <NoData />
            </div>
          )}
          <Pie
            id={chartName + "2"}
            options={pieChartOptions(false)}
            data={formatBarChartData({
              data: data,
              visible: visibleDatasets,
              avgChart: true,
              nameKey: parserKeys.nameKey,
              valueKey: parserKeys.multipleValueKeys[1]
            })}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames("line-chart-container", { loading, "no-data": noDataToShow })}>
      {loading && <Preloader size={30} />}
      {!loading && (!data || data.length === 0) && (
        <div className="no-chart-data">
          <NoData />
        </div>
      )}
      <Pie
        id={chartName}
        options={pieChartOptions(false)}
        data={formatBarChartData({
          data,
          visible: visibleDatasets,
          avgChart: true,
          nameKey: parserKeys.nameKey,
          valueKey: parserKeys.valueKey
        })}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  dashboardData: state.dashboardData
});

export default connect(mapStateToProps)(PieChart);
