import React, { useEffect, useState } from "react";
import { pdf } from "@react-pdf/renderer";
import { useDispatch } from "react-redux";
import Button from "../../../components/Button";
import { closeModalAction } from "../../../components/Modal/reducer";
import { EXPORT_MODAL } from "../config";
import { successNotification } from "../../../components/Notification";
import { downloadPDFBlob, preparePDFReportData } from "./methods";
import ReportPDF from "./index";

const DownloadPDFButton = ({ data = {}, type = "" }) => {
  const [axiosSource, setAxiosSource] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      axiosSource?.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axiosSource]);

  const onDownloadPdf = () => {
    preparePDFReportData({
      data,
      onStart: (source) => {
        setAxiosSource(source);
        setLoading(true);
      },
      onFailed: () => {
        setLoading(false);
      },
      onSuccess: (res) => {
        const blob = pdf(<ReportPDF data={res} type={type} />).toBlob();
        blob.then((res) => {
          const pdfBlob = new Blob([res], { type: "application/pdf" });
          // const blobURL = URL.createObjectURL(pdfBlob);
          // window.open(blobURL);
          downloadPDFBlob(pdfBlob);
          setLoading(false);
          successNotification({});
          dispatch(closeModalAction({ name: EXPORT_MODAL }));
        });
      }
    });
  };

  return (
    <Button
      type="primary"
      style={{ padding: "5px 15px" }}
      className="min-w-150 mr-5"
      onClick={onDownloadPdf}
      disabled={loading}
      loading={loading}
    >
      PDF
    </Button>
  );
};

export default DownloadPDFButton;
