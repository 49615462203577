import forEach from "lodash/forEach";
import routes from "../../pages/App/routes";
import { ReactComponent as DashboardIcon } from "../../images/icons/dashboard.svg";
import { ReactComponent as MyReportsIcon } from "../../images/icons/my-reports.svg";
import { ReactComponent as DataCenterIcon } from "../../images/icons/data-center.svg";
import { ReactComponent as AccessIcon } from "../../images/icons/access.svg";
import { ReactComponent as SandboxIcon } from "../../images/icons/sandbox.svg";
import { ReactComponent as ComingSoonIcon } from "../../images/icons/coming-soon.svg";
import { ReactComponent as CostManagement } from "../../images/icons/saving-icon.svg";
import { ReactComponent as Targets } from "../../images/icons/targets.svg";
import { ReactComponent as ModelsCenterIcon } from "../../images/icons/models-center.svg";

export const getDropdownValues = () => {
  return [
    {
      key: "1",
      label: "MediaCore"
    },
    {
      key: "2",
      label: "MediaProphet"
    },
    {
      key: "3",
      label: "MediaAviator"
    }
  ];
};

export const getNavigationMenuOption = (t, itemkey) => {
  if (itemkey === "1") {
    return [
      {
        key: "dashboard",
        name: t("Dashboard"),
        path: routes.DASHBOARD_PAGE,
        icon: <DashboardIcon />
      },
      {
        key: "my-reports",
        name: t("My Reports"),
        path: routes.MY_REPORTS,
        icon: <MyReportsIcon />
      },
      {
        key: "data-center",
        name: t("Data Center"),
        path: routes.DATA_CENTER_MY_CHANNELS,
        activeRoutes: [routes.DATA_CENTER_EXPLORE, routes.DATA_CENTER_MY_CHANNELS],
        icon: <DataCenterIcon />
      },
      {
        key: "cost-management",
        name: t("Cost Management"),
        path: routes.COST_MANAGEMENT,
        icon: <CostManagement />
      },
      {
        key: "targets",
        name: t("Targets"),
        path: routes.TARGETS_PAGE,
        icon: <Targets />
      },
      {
        key: "access",
        name: t("Access"),
        path: routes.ACCESS,
        icon: <AccessIcon />
      },
      {
        key: "sandbox",
        name: t("Funnel Analysis"),
        path: routes.SANDBOX,
        icon: <SandboxIcon />
      }
    ];
  } else if (itemkey === "2") {
    return [
      {
        key: "planning-center",
        name: t("Planning Center"),
        path: routes.PLANNING_CENTER,
        icon: <DataCenterIcon />
      },
      {
        key: "models-center",
        name: t("Models Center"),
        path: routes.MODELS_CENTER,
        icon: <ModelsCenterIcon />
      },
      {
        key: "access",
        name: t("Access"),
        path: routes.ACCESS,
        icon: <AccessIcon />
      }
    ];
  } else if (itemkey === "3") {
    return [{ key: "coming-soon", name: t("Coming Soon..."), path: routes.COMING_SOON, icon: <ComingSoonIcon /> }];
  }
};

const isActiveMenuLink = (pathname, menuItem) => {
  if (pathname.includes(menuItem.path)) {
    return true;
  }

  let result = false;

  if (menuItem.activeRoutes) {
    menuItem.activeRoutes.forEach((item) => {
      if (pathname.indexOf(item) > -1) {
        result = true;
      }
    });
  }

  return result;
};

export const getSelectedMenuKey = (location, options) => {
  let selectedKey;
  let parentSelectedKey;

  forEach(options, (item) => {
    if (item.submenu) {
      forEach(item.submenu, (submenuItem) => {
        if (isActiveMenuLink(location.pathname, submenuItem)) {
          selectedKey = submenuItem.key;
          parentSelectedKey = item.key;
        }
      });
    } else {
      if (isActiveMenuLink(location.pathname, item)) {
        selectedKey = item.key;
      }
    }
  });

  // if (itemkey === "1") {
  //   selectedKey = "dashboard";
  // }
  // if (itemkey === "2") {
  //   selectedKey = "planning-center";
  // }
  // if (itemkey === "3") {
  //   selectedKey = "coming-soon";
  // }

  return parentSelectedKey ? [parentSelectedKey, selectedKey] : selectedKey;
};
