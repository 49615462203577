import isEmpty from "lodash/isEmpty";
import config from "../../config";

export const deleteStorageAccessToken = () => {
  localStorage.removeItem(config.localStorageKeys.token);
};

export const saveStorageAccessToken = (token) => {
  localStorage.setItem(config.localStorageKeys.token, token);
};

export const saveStorageColorData = (colors) => {
  localStorage.setItem("colors", JSON.stringify(colors));
};

export const getStorageColorData = () => {
  return localStorage.getItem("colors");
};

export const getStorageAccessToken = () => {
  return localStorage.getItem(config.localStorageKeys.token);
};

export const checkIsLogged = (user) => !isEmpty(user);
