import { Modal } from "antd";
import Preloader from "../../Preloader";
import "./style.scss";
const LogoutModal = () => {
  const title = <h3 className="custom-modal-title">Logging Out...</h3>;
  return (
    <>
      <Modal
        className="custom-modal rounded"
        open={true}
        visible={true}
        closable={false}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <div className="modal-content">
          {title}
          <Preloader />
        </div>
      </Modal>
    </>
  );
};
export default LogoutModal;
