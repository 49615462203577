import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { analysisTypes, getChartNameKeyBasedOnAnalysisType } from "../Charts/methods";
import { useRequestSWR } from "../../../utils/custom-hooks";
import { prepareCPCCParams, prepareYOYResponse } from "../methods";
import { changeGlobalState } from "../../App/reducer";
import LineBarChartLegends from "../Charts/LineBarChartLegends";
import { incrementFetchingCounter, decrementFetchingCounter } from "../../../utils/reducer";
import { getStorageColorData } from "../../../components/_auth/methods";

export const CHART_REACH_NAME = "chartReach";
export const AVG_CHART = false;

const ReachChart = (props) => {
  const globalFiltersState = useSelector((state) => state.globalFilters);
  const [analysisType, setAnalysisType] = useState(analysisTypes[0].value);
  const dispatch = useDispatch();

  const reachData = useRequestSWR({
    requestKeyName: "reach_graph",
    apiRoute: "data/reach_graph",
    method: "post",
    params: prepareCPCCParams(globalFiltersState, {
      analysis_type: analysisType,
      colors: JSON.parse(getStorageColorData())
    }),
    onStart: () => {
      dispatch(incrementFetchingCounter());
    },
    onSuccess: () => {
      dispatch(decrementFetchingCounter());
    }
  });
  dispatch(changeGlobalState({ reach: { data: reachData?.data?.result, loading: reachData?.loading } }));

  useEffect(() => {
    return () => {
      reachData.source.cancel();
      setAnalysisType("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parserNameKey = getChartNameKeyBasedOnAnalysisType(analysisType);
  return (
    <LineBarChartLegends
      dashboardFilters={globalFiltersState}
      chartName={CHART_REACH_NAME}
      avgChart={AVG_CHART}
      title="Reach"
      data={reachData.isError ? [] : prepareYOYResponse(reachData.data, parserNameKey)}
      loading={reachData.loading}
      className={classNames(props.className)}
      parserKeys={{
        nameKey: parserNameKey,
        valueKey: "reach"
      }}
      onAnalysisTypeChange={(type) => {
        if (type !== analysisType) {
          setAnalysisType(type);
        }
      }}
      lineChartActive={false}
    />
  );
};

export default ReachChart;
