import React from "react";
import { Image, Text } from "@react-pdf/renderer";
import pdfFooterBgImg from "./pdf-footer-bg.png";
import { baseStyle } from "./methods";

const ReportFooterPdf = () => {
  const footerStyle = baseStyle({
    pageBackground: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      minWidth: "100%",
      display: "block",
      height: "auto"
    },
    pageNumber: {
      position: "absolute",
      bottom: 30,
      left: 0,
      right: 20,
      textAlign: "right"
    }
  });

  return (
    <>
      <Text
        style={footerStyle.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
      <Image src={pdfFooterBgImg} style={footerStyle.pageBackground} fixed />
    </>
  );
};

export default ReportFooterPdf;
