export const USER_MODAL_NAME = "register-new-user";
export const ACCOUNT_ROLE_ADMIN = "ADMIN";
export const ACCOUNT_ROLE_USER = "USER";
export const ACCOUNT_ROLE_AGENCY = "AGENT";
export const ACCESS_TYPE_READ = "r";
export const ACCESS_TYPE_FULL = "r+w";
export const accountRoles = [
  {
    text: ACCOUNT_ROLE_ADMIN,
    value: ACCOUNT_ROLE_ADMIN
  },
  {
    text: ACCOUNT_ROLE_USER,
    value: ACCOUNT_ROLE_USER
  },
  {
    text: ACCOUNT_ROLE_AGENCY,
    value: ACCOUNT_ROLE_AGENCY
  }
];
export const accessTypes = [
  {
    text: "Read only",
    value: ACCESS_TYPE_READ
  },
  {
    text: "Read and Write",
    value: ACCESS_TYPE_FULL
  }
];
