import authReducer from "../components/_auth/reducer";
import modalReducer from "../components/Modal/reducer";
import dashboardDataReducer from "../pages/Dashboard/reducer";
import funnelDataReducer from "../pages/Sandbox/reducer";
import globalReducer from "../pages/App/reducer";
import { fetchingReducer } from "../utils/reducer";
import { logoutReducer } from "../components/_layout/utils/reducer";
import { sidebarDataReducer } from "../components/_layout/reducer";
import planningCenterFiltersReducer from "../pages/PlanningCenter/StatisticsFilters/reducer";
import planningCenterData from "../pages/PlanningCenter/reducer";
import globalFiltersReducer from "../components/GlobalFilters/reducer";

const rootReducer = {
  auth: authReducer,
  modal: modalReducer,
  globalFilters: globalFiltersReducer,
  dashboardData: dashboardDataReducer,
  funnelData: funnelDataReducer,
  fetching: fetchingReducer,
  logoutModal: logoutReducer,
  global: globalReducer,
  planningCenterFilters: planningCenterFiltersReducer,
  sidebarData: sidebarDataReducer,
  planningCenterReducer: planningCenterData
};

export default rootReducer;
