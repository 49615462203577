import React from "react";
import Modal from "../../../components/Modal";
import { CREATE_NEW_BUSINESS_RULE } from "../config";
import BusinessRuleForm from "./Form";

const CreateNewBusinessRuleModal = ({ reloadCostManagementData }) => {
  return (
    <Modal width={850} name={CREATE_NEW_BUSINESS_RULE} className="rounded">
      <BusinessRuleForm reloadPanningCenterData={reloadCostManagementData} />
    </Modal>
  );
};

export default CreateNewBusinessRuleModal;
