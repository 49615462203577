import isEmpty from "lodash/isEmpty";
import moment from "moment";

export const filtersDateFormat = "YYYY-MM-DD";

export const prepareInitialValues = (data) => {
  if (!data || isEmpty(data)) {
    return {
      startDate: moment().subtract(5, "years").format(filtersDateFormat),
      endDate: moment().format(filtersDateFormat)
    };
  }
  // TODO: is it required?
  return {
    id: data.id,
    name: data.name,
    startDate: startDate || moment().subtract(5, "years").format(filtersDateFormat),
    endDate: endDate || moment().format(filtersDateFormat)
  };
};
