/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import Checkbox, { CheckboxGroup } from "../Checkbox";
import { usePrevious } from "../../utils/custom-hooks";
import Preloader from "../Preloader";
import NoData from "../NoData";

const CheckboxesDropdown = (props) => {
  const {
    options,
    selectAll,
    onChange,
    clearValues,
    loading,
    selectedValue,
    handleCheckAllChangeParent,
    initialSelectedValues = [],
    clear,
    dropdownName = "default",
    doNotCheckedAll,
    disabled
  } = props;
  const [checkedList, setCheckedList] = React.useState(
    selectedValue?.length > 0 ? selectedValue.map((item) => item.id) : []
  );
  const [indeterminate, setIndeterminate] = React.useState(false);
  const [initialReload, setIinitialReload] = React.useState(true);

  const [checkAll, setCheckAll] = useState(true);

  const prevClearValues = usePrevious(clearValues);
  useEffect(() => {
    if (!clear) {
      if (!indeterminate && initialReload && selectedValue.length === 0) {
        var newValuesChecked = options.map((option) => option.id);
        if (dropdownName === "metric") {
          newValuesChecked = initialSelectedValues.map((selectedOption) => selectedOption.id);
          setCheckedList(newValuesChecked);
          onChange(selectedValue);
        } else {
          setCheckedList(newValuesChecked);
          doNotCheckedAll && onChange(options);
        }
        if (newValuesChecked.length > 0) {
          setIinitialReload(false);
        }
      }
    } else {
      if (options.length === 0 || checkedList.length < options.length) {
        setCheckAll(false);
      }
    }
  }, [options, selectAll, initialReload, clear]);

  const onLocalChange = useCallback(
    (list) => {
      setCheckedList(list);
      setIndeterminate(!!list.length && list.length <= options.length);
      setCheckAll(list.length === options.length);
      handleCheckAllChangeParent(list.length === options.length);
      onChange(options.filter((option) => list.indexOf(option.id) > -1));
    },
    [options, onChange]
  );

  const onCheckAllChange = useCallback(
    (e) => {
      const newValuesChecked = e.target.checked ? options.map((option) => option.id) : [];
      setCheckedList(newValuesChecked);
      setIndeterminate(false);
      setCheckAll(e.target.checked);
      handleCheckAllChangeParent(e.target.checked);
      onChange(e.target.checked ? options : []);
    },
    [options, onChange]
  );

  useEffect(() => {
    if (options.length > 0 && checkedList.length >= 0) {
      setIndeterminate(checkedList.length !== options.length);
    }
    handleCheckAllChangeParent(checkedList.length === options.length);
  }, [options, checkedList]);

  useEffect(() => {
    if (clearValues !== undefined && clearValues !== prevClearValues) {
      if (dropdownName === "metric") {
        var newValuesChecked = initialSelectedValues.map((selectedOption) => selectedOption.id);
        setCheckedList(newValuesChecked);
        onChange(initialSelectedValues);
        setCheckAll(false);
        handleCheckAllChangeParent(false);
        setIndeterminate(false);
      } else {
        setCheckedList([]);
        setCheckAll(false);
        handleCheckAllChangeParent(false);
        setIndeterminate(false);
      }
    }
  }, [clearValues, prevClearValues, clear]);

  useEffect(() => {
    if (selectedValue) {
      setCheckedList(selectedValue.map((item) => item.id));
    }
  }, [selectedValue]);

  return (
    <div className="checkboxes-input-dropdown">
      {selectAll && (
        <div className="check-all">
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            All
          </Checkbox>
        </div>
      )}
      {loading && <Preloader />}
      {!loading && options.length === 0 && <NoData className="mt-15" />}

      <CheckboxGroup value={checkedList} onChange={onLocalChange}>
        {options.map((option) => (
          <Checkbox disabled={disabled} key={option.id} value={option.id}>
            {option.name}
          </Checkbox>
        ))}
      </CheckboxGroup>
    </div>
  );
};

export default CheckboxesDropdown;
