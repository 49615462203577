import { Progress } from "antd";
import { formatString } from "../../../utils/common";
import { sortTableColumnString } from "../../../components/Table/methods";
import Actions from "./overview/Actions";
import AnalysisActions from "./Analysis/AnalysisActions";

function formatAndRound(value) {
  var multipliedValue = value * 100;
  var roundedValue = Math.round(multipliedValue);
  var formattedValue = roundedValue.toFixed(2);
  return formattedValue; // Convert back to float if needed
}

// eslint-disable-next-line no-unused-vars
function calculateDivision(targetValue, realTarget) {
  if (targetValue !== null && realTarget !== null && targetValue !== 0) {
    return formatAndRound(realTarget / targetValue).toString();
  } else if (realTarget === 0) {
    return "To be determined";
  } else {
    return "0";
  }
}

export const targetOverviewColumns = (reloadData, history) => [
  {
    title: "Target Rule Name",
    sorter: (a, b) => sortTableColumnString(a.title, b.title),
    width: "10%",
    render: (row) => (
      <a
        href={`/targets/${row.id}`}
        onClick={(e) => {
          e.preventDefault();
          history.push({
            pathname: `/targets/${row.id}`,
            state: { record: row }
          });
        }}
        style={{
          display: "block",
          width: "100%",
          height: "100%",
          textDecoration: "none"
        }}
      >
        {formatString(row.title, "-")}
      </a>
    )
  },
  {
    title: "Creation Date",
    width: "5%",
    sorter: (a, b) => sortTableColumnString(a.created_at, b.created_at),

    render: (row) => formatString(row.created_at.substring(0, 10), "-")
  },
  {
    title: "Last Updated",
    width: "5%",
    sorter: (a, b) => sortTableColumnString(a.updated_at, b.updated_at),

    render: (row) => formatString(row.updated_at.substring(0, 10), "-")
  },
  {
    title: "Status",
    width: "5%",
    sorter: (a, b) => sortTableColumnString(a.acive, b.active),

    render: (row) => formatString(row.active ? "Active" : "Inactive")
  },
  {
    title: "",
    width: "10%",
    render: (row) => {
      return <Actions data={row} reloadData={reloadData} />;
    }
  }
];

export const targetAnalysisColumns = (reloadTargetData) => [
  {
    title: "Target Rule Name",
    sorter: (a, b) => sortTableColumnString(a.title, b.title),
    width: "20%",
    render: (row) => formatString(row.title, "-")
  },
  {
    title: "Ratio",
    width: "30%",
    sorter: (a, b) =>
      sortTableColumnString(
        calculateDivision(a.target_value, a.real_value),
        calculateDivision(b.target_value, b.real_value)
      ),

    render: (row) => (
      <div style={{ display: "flex", alignItems: "center", marginRight: "15%" }}>
        {row.target_value === null || row.real_value === null || row.target_value === 0 || row.real_value === 0 ? (
          "To be determined"
        ) : (
          <>
            <Progress
              percent={calculateDivision(row.target_value, row.real_value)}
              strokeLinecap="butt"
              size="big"
              // status="active"
              showInfo={false}
              style={{ flexShrink: 0, width: "60%" }} // Adjust width as needed
            />
            <span
              style={{
                display: "inline-block",
                width: "auto",
                fontSize: "1rem",
                lineHeight: 1,
                whiteSpace: "nowrap",
                textAlign: "left",
                verticalAlign: "middle",
                wordBreak: "normal",
                marginLeft: "20px" // Optional: Adds space between progress bar and text
              }}
            >
              {calculateDivision(row.target_value, row.real_value)} %
            </span>
          </>
        )}
      </div>
    )
  },
  {
    title: "Creation Date",
    width: "20%",
    sorter: (a, b) => sortTableColumnString(a.created_at, b.created_at),

    render: (row) => formatString(row.created_at.substring(0, 10), "-")
  },
  {
    title: "Status",
    width: "15%",
    sorter: (a, b) => sortTableColumnString(a.acive, b.active),

    render: (row) => formatString(row.active ? "Active" : "Inactive")
  },
  {
    title: "",
    width: "20%",
    render: (row) => {
      return <AnalysisActions data={row} reloadData={reloadTargetData} />;
    }
  }
];

export const analysisTypes = [
  { value: "channel", text: "Channel" },
  { value: "publisher", text: "Publisher" },
  { value: "campaign", text: "Campaign" },
  { value: "property", text: "Property" }
];
export const getMMMChartNameKeyBasedOnAnalysisType = (analysisType) => {
  if (analysisType === analysisTypes[0].value) {
    return "channels";
  }
  if (analysisType === analysisTypes[1].value) {
    return "publishers";
  }
  if (analysisType === analysisTypes[2].value) {
    return "campaigns";
  }
  if (analysisType === analysisTypes[3].value) {
    return "properties";
  }
  return "channels";
};
export const prepareMBAParams = (params = {}, additionalParams = {}) => {
  return {
    channels: params?.channel?.map((item) => item.id).toString(),
    publishers: params?.publisher?.map((item) => item.id).toString(),
    properties: params?.property?.map((item) => item.id).toString(),
    date: `${params.startDate} - ${params.endDate}`,
    ...additionalParams
  };
};

export const prepareFilterParams = (params = {}, additionalParams = {}, selectedFilter = "") => {
  return {
    channels: selectedFilter === "channel" ? [] : params?.channel?.map((item) => item.id).toString(),
    publishers: selectedFilter === "publisher" ? [] : params?.publisher?.map((item) => item.id).toString(),
    properties: selectedFilter === "property" ? [] : params?.property?.map((item) => item.id).toString(),
    mediaTypes: [],
    campaigns: [],
    date: `${params.startDate} - ${params.endDate}`,
    yoy: params.yoy,
    ...additionalParams
  };
};

export const reverseTransformObject = (output) => {
  return [
    {
      kanal: "actual value",
      original_kanal: "actual value",
      value: output.real_value,
      color: "#92c946",
      target_type: output.target_type
    },
    {
      kanal: "target value",
      original_kanal: "target value",
      value: output.target_value,
      color: "#3bbeb4",
      target_type: output.target_type
    }
  ];
};
