import React, { Suspense } from "react";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import App from "./pages/App";
import { GlobalPreloader } from "./components/_auth/CheckToken";
// import config from "./config";
import "./i18n";
import "./styles/index.scss";

// Sentry.init({
//   dsn: config.sentry.dsn,
//   integrations: [new Integrations.BrowserTracing()],
//   environment: process.env.NODE_ENV === "development" ? "development" : "production",
//   allowUrls: ["live-app-url.com"],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0
// });

ReactDOM.render(
  <Suspense fallback={<GlobalPreloader />}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>,
  document.getElementById("root")
);
