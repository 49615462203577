import useSWR from "swr";
import axios from "../../axios";
import { getStorageAccessToken } from "./methods";

const useCheckToken = () => {
  const accessToken = getStorageAccessToken();

  const { data, error } = useSWR(
    "checkToken",
    () => {
      if (accessToken) {
        return axios.post("auth/checkSession");
      }

      return Promise.reject(new Error("No Token Available"));
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      shouldRetryOnError: false
    }
  );

  return {
    user: data?.data?.user,
    loading: !error && !data,
    isError: error
  };
};

export default useCheckToken;
