import classNames from "classnames";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
import Avatar from "../../Avatar";
import { ReactComponent as Campaign } from "../../../images/icons/campaign.svg";
import { ReactComponent as Money } from "../../../images/icons/money.svg";
import { ReactComponent as Report } from "../../../images/icons/my-reports.svg";
import { ReactComponent as CampaignError } from "../../../images/icons/campaign-error.svg";

export const renderIcon = ({ type, photo, expired }) => {
  switch (type) {
    case "New Report":
      return (
        <>
          <div className={"dot " + (expired ? "transparent-background" : "blue-background")}></div>
          <span>
            <Avatar
              size={40}
              className={classNames("border-color-blue", "avatar", "icon-color-blue")}
              icon={<Report />}
            />
          </span>
        </>
      );
    case "New Campaign":
      return (
        <>
          <div className={"dot " + (expired ? "transparent-background" : "blue-background")}></div>
          <span>
            <Avatar
              size={40}
              className={classNames("border-color-blue", "avatar", "icon-color-blue")}
              icon={<Campaign />}
            />
          </span>
        </>
      );
    case "Assignment":
      return (
        <>
          <div className={"dot " + (expired ? "transparent-background" : "blue-background")}></div>
          <span>
            <Avatar src={photo} size={40} className="avatar" icon={<UserOutlined />} />
          </span>
        </>
      );
    case "Budgeting":
      return (
        <>
          <div className={"dot " + (expired ? "transparent-background" : "blue-background")}></div>
          <span>
            <Avatar size={40} className={classNames("icon-color-red", "avatar")} icon={<Money />} />
          </span>
        </>
      );
    case "API error":
      return (
        <>
          <div className={"dot " + (expired ? "transparent-background" : "blue-background")}></div>
          <span>
            <Avatar size={40} className={classNames("icon-color-red", "avatar")} icon={<CampaignError />} />
          </span>
        </>
      );
    case "Notification trigger":
      return (
        <>
          {expired}
          <div className={"dot " + (expired ? "transparent-background" : "blue-background")}></div>
          <span>
            <Avatar src={photo} size={40} className="avatar" icon={<UserOutlined />} />
          </span>
        </>
      );
    default:
      return "";
  }
};

export const renderNotificationMessage = ({ category, subject, type }) => {
  switch (type) {
    case "New Report":
      return (
        <span className="grey-text">
          <span className="blue-text">{subject}</span> created a <span className="blue-text">{category}</span>
        </span>
      );
    case "New Campaign":
      return (
        <span className="grey-text">
          <span className="blue-text">{subject}</span> added to <span className="blue-text">{category}</span>
        </span>
      );
    case "Assignment":
      return (
        <span className="grey-text">
          <span className="blue-text">{subject}</span> was assigned to <span className="blue-text">{category}</span>
        </span>
      );
    case "Budgeting":
      return (
        <span className="grey-text">
          <span className="red-text">{subject}</span> left for <span className="red-text">{category}</span> channel
        </span>
      );
    case "API error":
      return (
        <span className="grey-text">
          <span className="red-text">{subject}</span> API is down
        </span>
      );
    case "Notification trigger":
      return (
        <span className="grey-text">
          <span className="blue-text">{subject}</span> uploaded new <span className="blue-text">{category}</span> data
        </span>
      );
    default:
      return "";
  }
};

export const renderType = ({ type, time }) => {
  switch (type) {
    case "New Report":
      return (
        <span className="flex flex-row">
          New report <div className="grey-dot"></div>
          {moment(time).fromNow()}
        </span>
      );
    case "New Campaign":
      return (
        <span className="flex flex-row">
          New Campaign <div className="grey-dot"></div>
          {moment(time).fromNow()}
        </span>
      );
    case "Assignment":
      return (
        <span className="flex flex-row">
          Assignment <div className="grey-dot"></div>
          {moment(time).fromNow()}
        </span>
      );
    case "Budgeting":
      return (
        <span className="flex flex-row">
          Budgeting <div className="grey-dot"></div>
          {moment(time).fromNow()}
        </span>
      );
    case "API error":
      return (
        <span className="flex flex-row">
          API error <div className="grey-dot"></div>
          {moment(time).fromNow()}
        </span>
      );
    case "Notification trigger":
      return (
        <span className="flex flex-row">
          notification trigger <div className="grey-dot"></div>
          {moment(time).fromNow()}
        </span>
      );
    default:
      return "";
  }
};

export const prepareNotificationsData = (notifications = []) => {
  const formatHumanDate = (date) => {
    return moment(date).calendar({
      sameDay: "[Today]",
      lastDay: "[Yesterday]",
      lastWeek: "dddd",
      sameElse: "YYYY/MM/DD"
    });
  };
  return notifications.reduce((result, item) => {
    const formattedDateTime = formatHumanDate(new Date(item.created_at));
    if (result[formattedDateTime]) {
      result[formattedDateTime].push({
        ...item,
        date: moment(item.created_at)
      });
    } else {
      result[formattedDateTime] = [
        {
          ...item,
          date: moment(item.created_at)
        }
      ];
    }
    return result;
  }, {});
};
