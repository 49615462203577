import React from "react";
import { Form as AntForm } from "antd";
import classNames from "classnames";
import ErrorList from "../Errors";

const Form = (props) => {
  const { children, onSubmit, errors, loading, className, form, title, ...rest } = props;

  return (
    <AntForm
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      className={classNames("form-component", className, { loading })}
      scrollToFirstError
      {...rest}
    >
      {title}
      <ErrorList errors={errors} />
      {children}
    </AntForm>
  );
};

Form.Item = AntForm.Item;
Form.useForm = AntForm.useForm;
Form.useWatch = AntForm.useWatch;

export default Form;
