import { createAction, createReducer } from "@reduxjs/toolkit";
import { getDropdownValues } from "./methods";

const SIDEBAR_LOCALSTORAGE_KEY = "sidebar_data";
export const saveSidebarDataToLocalStorage = (state = {}) => {
  localStorage.setItem(SIDEBAR_LOCALSTORAGE_KEY, JSON.stringify(state));
  return state;
};

export const changeSidebarData = createAction("layout/change-sidebar-data");
export const resetSidebarData = createAction("layout/reset-sidebar-data");

const initialState = {
  selectedValue: getDropdownValues()[0]
};

export const sidebarDataReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(changeSidebarData, (state, action) => {
      return {
        ...state,
        ...saveSidebarDataToLocalStorage(action.payload)
      };
    })
    .addCase(resetSidebarData, () => {
      return {
        selectedValue: getDropdownValues()[0]
      };
    });
});
