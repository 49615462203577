import React from "react";
import { Button, Modal } from "antd";
import { UPDATE_BUSINESS_RULE } from "../config";
import "./style.scss";
import axios from "../../../axios";
import { errorNotification, successNotification } from "../../../components/Notification";
const UpdateBusinessRulePopupModal = (props) => {
  const { visible, onCancel, data } = props;

  const onUpdateConfirm = () => {
    const dataToSend = {
      "br_id": data.id,
      "active": !data.active
    };
    axios.patch("/business_rules/", dataToSend).then(
      (res) => {
        successNotification({
          description: res.data.msg || "The business rule has been updated",
          iconStyle: "icon-color-green"
        });
        onCancel();
        data.active = !data.active;
      },
      (error) => {
        errorNotification({
          description: error.response.data.msg || config.generalError
        });
        onCancel();
      }
    );
  };
  return (
    <Modal
      width={450}
      height={201}
      className="custom-modal rounded"
      name={UPDATE_BUSINESS_RULE}
      open={visible}
      visible={visible}
      closable={false}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
    >
      <div className="modal-container">
        <div className="modal-title">Confirm</div>
        <div className="modal-content">Are you sure you want to update the dashboard with the business rule?</div>
        <div className="form-actions flex flex-justify-end flex-wrap">
          <Button type="secondary" htmlType="button" className="min-w-50 mr-10" onClick={onCancel}>
            Cancel
          </Button>

          <Button type="primary" htmlType="submit" className="min-w-50" onClick={onUpdateConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateBusinessRulePopupModal;
