import { createAction, createReducer } from "@reduxjs/toolkit";

export const changeSandboxdData = createAction("sandbox/change-data");

export default createReducer({}, (builder) => {
  builder.addCase(changeSandboxdData, (state, action) => {
    return {
      ...state,
      ...action.payload
    };
  });
});
