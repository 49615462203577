import React, { useCallback, useEffect, useState } from "react";
import { Table as AntdTable } from "antd";
import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import isEqual from "lodash/isEqual";
import { isBoolean } from "lodash";
import { useDispatch } from "react-redux";
import axios from "../../axios";
import Preloader from "../Preloader";
import { usePrevious } from "../../utils/custom-hooks";
import { savePlanningCenterData } from "../../pages/PlanningCenter/reducer";

const Table = (props) => {
  const {
    className,
    columns,
    apiRoute,
    requestMethod,
    params = {},
    transformer = (res) => res,
    onSuccess = () => {},
    onStart = () => {},
    onFailed = () => {},
    onFilter,
    data = [],
    reload,
    yoy,
    ...rest
  } = props;
  const source = axios.CancelToken.source();
  const [loading, setLoading] = useState(false);
  const [originalRequestData, setOriginalRequestData] = useState([]);
  const dispatch = useDispatch();
  const [tableState, setTableState] = useState({
    params,
    data: [...data],
    pagination: {
      current: 1,
      pageSize: 10
    },
    filters: {}
  });

  const handleTableChange = (pagination, filters = {}, sorter) => {
    setTableState({
      ...tableState,
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  const fetchData = useCallback(
    (reqParams) => {
      if (apiRoute && !props.data) {
        setLoading(true);
        onStart();

        const RequestConfig = {
          method: requestMethod || "get",
          cancelToken: source.token
        };
        axios[RequestConfig["method"]](
          apiRoute,
          RequestConfig["method"] === "get" ? RequestConfig : { ...reqParams },
          RequestConfig
        ).then(
          (res) => {
            setTableState({
              ...tableState,
              data: transformer(res.data),
              params: reqParams
            });
            setOriginalRequestData(transformer(res.data));
            onSuccess(res.data);
            if (apiRoute === "/plans/") {
              dispatch(savePlanningCenterData(res.data));
              //add dispatch logic here to save this res.data : planningCenterData to the react redux store
            }
            setLoading(false);
          },
          () => {
            setTableState({
              ...tableState,
              data: transformer([]),
              params: reqParams
            });
            onFailed();
            setLoading(false);
          }
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiRoute, requestMethod, tableState, source, transformer, onSuccess, onStart, onFailed]
  );

  const previousParams = usePrevious(params);
  useEffect(() => {
    if (!isEqual(previousParams, params)) {
      fetchData(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const previousReloadValue = usePrevious(reload);
  useEffect(() => {
    if (isBoolean(reload) && !isEqual(reload, previousReloadValue)) {
      fetchData(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  useEffect(() => {
    fetchData(params);
    return () => {
      source?.cancel();
      setLoading(false);
      setOriginalRequestData([]);
      setTableState({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const previousData = usePrevious(data);
  useEffect(() => {
    if (!isEqual(data, previousData)) {
      setTableState({
        ...tableState,
        data: [...data]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const previousOnFilter = usePrevious(onFilter);
  useEffect(() => {
    if (!isEqual(onFilter, previousOnFilter)) {
      setTableState({
        ...tableState,
        data: onFilter(originalRequestData)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFilter]);

  return (
    <div className={classNames("antd-table-container", className)}>
      <AntdTable
        showSorterTooltip={false}
        columns={columns}
        rowKey={(record) => record.key || uniqueId("_table_row_id_")}
        dataSource={tableState.data}
        pagination={{ ...tableState.pagination, showSizeChanger: false, hideOnSinglePage: true }}
        loading={{
          spinning: loading,
          indicator: <Preloader size={30} />
        }}
        onChange={handleTableChange}
        scroll={{ x: yoy ? 2000 : 1300, y: 600 }}
        {...rest}
      />
    </div>
  );
};

export default Table;
