import React from "react";
import { Modal } from "antd";
import classNames from "classnames";
import { reverseTransformObject } from "../../methods";
import SingleRuleChart from "./SingleRuleChart";

// eslint-disable-next-line unused-imports/no-unused-vars
const SingleRuleChartModel = ({ data, visible, onCancel, reloadPanningCenterData: reloadData }) => {
  return (
    <Modal
      width={1000}
      name={"RULE_CHART"}
      wrapClassName={classNames("modal-wrapper", "rounded", 1000)}
      visible={visible}
      header={null}
      footer={null}
      onCancel={onCancel}
    >
      <SingleRuleChart
        title={`Target Rule : ${data.title}`}
        data={reverseTransformObject(data)}
        className="mb-50"
        parserKeys={{
          nameKey: "kanal",
          valueKey: "value",
          multipleLabels: [],
          multipleValueKeys: [],
          colorKey: "color"
        }}
        hideCategoryDropdown
        reloadPanningCenterData={reloadData}
        onCancel={onCancel}
      />
    </Modal>
  );
};

export default SingleRuleChartModel;
