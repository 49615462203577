import { useCallback, useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import routes from "../../pages/App/routes";
import { getDropdownValues, getNavigationMenuOption, getSelectedMenuKey } from "./methods";

const NavigationMenu = ({ collapsed, firstClicked, setFirstClicked }) => {
  const sidebarData = localStorage.getItem("sidebar_data")
    ? JSON.parse(localStorage.getItem("sidebar_data"))
    : { selectedValue: getDropdownValues()[0] };

  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const [options, setOptions] = useState(getNavigationMenuOption(t, getDropdownValues()[0].key));
  const initialSelectedKeys = getSelectedMenuKey(location, options);
  const [openKeys, setOpenKeys] = useState(initialSelectedKeys);
  const [selectedKey, setSelectedKey] = useState(initialSelectedKeys);

  const onOpenChange = (e) => {
    setOpenKeys(e);
  };

  const updateOpenKeys = (e) => {
    setSelectedKey(e.key);

    if (e.keyPath.length === 1) {
      setOpenKeys(e.keyPath);
    }
  };
  useEffect(() => {
    if (!isEqual(selectedKey, initialSelectedKeys)) {
      setSelectedKey(initialSelectedKeys);
      setOpenKeys(initialSelectedKeys);
    }
  }, [initialSelectedKeys, selectedKey, openKeys]);

  const updateOptions = useCallback(() => {
    setOptions(getNavigationMenuOption(t, sidebarData.selectedValue.key));
  }, [t, sidebarData.selectedValue.key]);

  const updateSelectedKey = useCallback(() => {
    setSelectedKey(getSelectedMenuKey(location, options));
  }, [location, options]);

  useEffect(() => {
    updateOptions();
    updateSelectedKey();
    if (sidebarData.selectedValue.key === "1" && firstClicked) {
      history.push(routes.DASHBOARD_PAGE);
      setFirstClicked(false);
    } else if (sidebarData.selectedValue.key === "2" && firstClicked) {
      history.push(routes.PLANNING_CENTER);
      setFirstClicked(false);
    } else if (sidebarData.selectedValue.key === "3" && firstClicked) {
      history.push(routes.COMING_SOON);
      setFirstClicked(false);
    }
    // eslint-disable-next-line
  }, [sidebarData.selectedValue.key]);
  return (
    <>
      <Menu
        className="layout-sidebar-navigation"
        theme="dark"
        mode="inline"
        selectedKeys={selectedKey}
        openKeys={!collapsed && isArray(openKeys) ? openKeys : undefined}
        onOpenChange={onOpenChange}
        onClick={updateOpenKeys}
      >
        {options.map((menuItem) => {
          return !menuItem.submenu ? (
            <Menu.Item key={menuItem.key} icon={menuItem.icon} title={menuItem.name}>
              {menuItem.name}
              <Link to={menuItem.path} />
            </Menu.Item>
          ) : (
            <Menu.SubMenu key={menuItem.key} icon={menuItem.icon} title={menuItem.name}>
              {menuItem.submenu.map((menuSubItem) => (
                <Menu.Item key={menuSubItem.key}>
                  {menuSubItem.name}
                  <Link to={menuSubItem.path} />
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          );
        })}
      </Menu>
    </>
  );
};

export default NavigationMenu;
