import forEach from "lodash/forEach";
import forOwn from "lodash/forOwn";
import isArray from "lodash/isArray";
import * as Sentry from "@sentry/react";
import config from "../../config";

/**
 * Create field rules
 */
export const fieldRules = ({ required, email, max, min }, additionalRules = []) => {
  const result = [];

  if (required) {
    result.push({
      required: true,
      message: required.message
    });
  }

  if (email) {
    result.push({
      type: "email",
      message: email.message
    });
  }

  if (max) {
    result.push({
      max: max.value,
      message: max.message
    });
  }

  if (min) {
    result.push({
      min: min.value,
      message: min.message
    });
  }

  return [...result, ...additionalRules];
};

/**
 * @param {object} errorResponse
 * @param {object} formValues
 * @return {object}
 * ex: {
 *   formErrors: [{ name: fieldName, value: fieldValue, errors: [error1, error2, ...] }],
 *   generalErrors: ['Error 1', ...]
 * }
 */
export const prepareErrors = (errorResponse, formValues = {}) => {
  const formErrors = [];
  let generalErrors = [];
  let errors = {};

  if (errorResponse && errorResponse.response && errorResponse.response.data) {
    errors = {
      general: errorResponse.response.data.msg || errorResponse.response.data.message || config.generalError
    };

    generalErrors = isArray(errors.general) ? errors.general : [errors.general];
  }

  forOwn(errors, (errItem, key) => {
    if (key !== "general") {
      formErrors.push({
        name: key,
        value: formValues[key] || undefined,
        errors: errItem
      });
    }
  });

  if (formErrors.length === 0 && generalErrors.length === 0) {
    Sentry.captureException(errorResponse);
    generalErrors = [config.generalError];
  }

  return {
    formErrors,
    generalErrors
  };
};

/**
 * @description This method is called when an ajax request from a form is failed
 * @param {object} errors
 * @param {object} form
 */
export const catchFormErrors = (errors, form) => {
  const { formErrors, generalErrors } = prepareErrors(errors, form.getFieldsValue());
  form.setFields(formErrors);
  return { formErrors, generalErrors };
};

/**
 * @description:
 * Create a form data object using form fields value collection
 * Exclude all undefined fields value
 */
export const prepareFormData = (data) => {
  const formData = new FormData();

  forEach(data, (value, key) => {
    if (value !== undefined) {
      if (isArray(value)) {
        forEach(value, (val) => {
          formData.append(`${key}[]`, val);
        });
      } else {
        formData.append(key, value);
      }
    }
  });

  return formData;
};
