import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { baseStyle, extendStyle } from "./methods";

const ReportFiltersPdf = ({ data, name }) => {
  const styles = baseStyle();

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <View style={extendStyle(styles.table, { marginBottom: 4 })}>
      <View style={styles.row}>
        <Text style={extendStyle(styles.column, { width: 100 })}>{name}</Text>
        <View style={extendStyle(styles.column, { flexWrap: "wrap" })}>
          {data.map((item, idx) => (
            <Text key={item.label} style={styles.bold}>
              {item.label}
              {data.length - 1 !== idx && ", "}
            </Text>
          ))}
        </View>
      </View>
    </View>
  );
};

export default ReportFiltersPdf;
