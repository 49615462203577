export const CREATE_NEW_TARGET_RULE = "create-new-target-rule";
export const UPDATE_TARGET_RULE = "update-target-rule";
export const TARGET_RULE_CHART = "target-rule-chart";

export const METRIC_TYPE_CHANNELS = "Channels";
export const METRIC_TYPE_PUBLISHERS = "Publishers";
export const METRIC_TYPE_PROPERTIES = "Properties";
export const VALUE_CLICKS = "clicks";
export const TARGET_BUDGET = "Budget";
export const TARGET_UNIQUE = "Uniques";
export const TARGET_IMPRESSIONS = "Impressions";
export const TARGET_GRP = "GRP";
export const EXPORT_MODAL = "export-modal";
export const SAVE_MODAL = "save-modal";

export const publishers = [
  {
    text: METRIC_TYPE_PUBLISHERS,
    value: METRIC_TYPE_PUBLISHERS
  }
];

export const properties = [
  {
    text: METRIC_TYPE_PROPERTIES,
    value: METRIC_TYPE_PROPERTIES
  }
];

export const targets = [
  {
    text: TARGET_BUDGET,
    value: TARGET_BUDGET.toLowerCase()
  },
  {
    text: TARGET_UNIQUE,
    value: TARGET_UNIQUE.toLowerCase()
  },
  {
    text: TARGET_IMPRESSIONS,
    value: TARGET_IMPRESSIONS.toLowerCase()
  },
  {
    text: TARGET_GRP,
    value: TARGET_GRP.toLowerCase()
  }
];
