import React, { useRef } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import classNames from "classnames";
import { connect } from "react-redux";
import Preloader from "../../../components/Preloader";
import NoData from "../../../components/NoData";
import { formatCostBarChartData, stackedBarChartOptions } from "./methods";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const barPosition = {
  id: "barPosition",
  beforeDatasetsDraw: (chart) => {
    const dataset1 = chart.getDatasetMeta(0).data;
    const dataset2 = chart.getDatasetMeta(1).data;
    if (dataset2.length > 0) {
      dataset1.forEach((datapoint, index) => {
        dataset2[index].x = datapoint.x;
      });
    }
  }
};

const StackedBarChart = (props) => {
  const {
    data = [],
    ratio,
    visibleDatasets,
    visibleCostTypes,
    avgChart,
    loading,
    parserKeys = {},
    chartName,
    className,
    dashboardFilters
  } = props;
  const options = stackedBarChartOptions(dashboardFilters?.yoy);
  const barChartRef = useRef(null);
  const noDataToShow = !loading && (!data || data.length === 0);

  return (
    <div className={classNames(className ? className : "bar-chart-container", { loading, "no-data": noDataToShow })}>
      {loading && <Preloader size={30} />}
      {!loading && (!data || data.length === 0) && (
        <div className="no-chart-data">
          <NoData />
        </div>
      )}
      <Bar
        id={chartName}
        options={options}
        plugins={[barPosition]}
        data={formatCostBarChartData({
          data,
          visible: visibleDatasets,
          visibleCostTypes: visibleCostTypes,
          avgChart: avgChart,
          nameKey: parserKeys.nameKey,
          valueKey: parserKeys.valueKey,
          isYOYActive: dashboardFilters?.yoy,
          ratio: ratio
        })}
        ref={barChartRef}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  dashboardData: state.dashboardData
});

export default connect(mapStateToProps)(StackedBarChart);
