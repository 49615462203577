import React, { useCallback, useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import Select from "../../../components/Select";
import Checkbox, { CheckboxGroup } from "../../../components/Checkbox";
import Preloader from "../../../components/Preloader";
import CheckboxesInput from "../../../components/CheckboxesInput";
import { CHART_COST_ANALYSIS_NAME } from "../CostAnalysis/CostAnalysisChart";
import { CHART_COST_TREND_ANALYSIS_NAME } from "../CostTrendAnalysis/CostTrendAnalysisChart";
import { analysisTypes, analysisTypes3, mapAnalysisTypesToCPCC } from "./methods";

const LegendsCheckboxes = (props) => {
  const {
    legends,
    onChange,
    onHover,
    loading,
    hideCategoryDropdown,
    hideTypeDropdown = true,
    typeOptions,
    typePlaceholder = "Select filter",
    propAnalysisTypes,
    chartName,
    dropdownName
  } = props;
  const [localLegendsCopy, setLocalLegendsCopy] = useState(legends);
  const [checkedList, setCheckedList] = useState(localLegendsCopy);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);
  const [selectValue, setSelectValue] = useState(analysisTypes[0].value);
  const [selectTypeValue, setSelectTypeValue] = useState(typeOptions ? typeOptions[0]?.value : []);
  const [highlightedValue, setHighlightedValue] = useState(null);
  const analysisTypes2 = propAnalysisTypes || analysisTypes;

  useEffect(() => {
    if (!isEqual(legends, localLegendsCopy)) {
      setLocalLegendsCopy(legends);
      setCheckedList(legends);
    }
    // eslint-disable-next-line
  }, [legends]);

  useEffect(() => {
    if (typeOptions && typeOptions.length > 0) {
      setSelectTypeValue(typeOptions[0].value);
    }
  }, [typeOptions]);

  const onChangeCheckboxes = useCallback(
    (list) => {
      if (list && !isEqual(list, checkedList)) {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < localLegendsCopy.length);
        setCheckAll(list.length === localLegendsCopy.length);
        setHighlightedValue(null);

        onChange && onChange({ type: selectValue, checked: list });
      }
    },
    [localLegendsCopy, selectValue, checkedList, onChange]
  );

  const onCheckAllChange = (e) => {
    const checkedValues = e.target.checked ? localLegendsCopy : [];
    setCheckedList(checkedValues);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    setHighlightedValue(null);

    onChange && onChange({ type: selectValue, checked: checkedValues });
  };

  const onSelectChange = (value) => {
    setSelectValue(value);
    setHighlightedValue(null);

    onChange && onChange({ type: value, checked: undefined });
  };

  const onSelectTypeChange = (value) => {
    setSelectTypeValue(value);

    onChange && onChange({ type: value, checked: undefined });
  };

  const onMouseEnter = (option) => () => {
    if (
      checkedList &&
      checkedList.length > 0 &&
      checkedList.filter((checkedOptions) => checkedOptions.name === option.name).length > 0
    ) {
      onHover && onHover({ active: option });
      setHighlightedValue(option);
    }
  };

  const onMouseLeave = (option) => () => {
    if (highlightedValue && highlightedValue.name === option.name) {
      onHover && onHover({ active: null });
      setHighlightedValue(null);
    }
  };

  const getDefaultValue = useCallback(() => {
    const defaultValue = `All ${mapAnalysisTypesToCPCC(selectValue)}`;
    return checkedList.length > 0 ? `${checkedList.length} items selected` : defaultValue;
  }, [checkedList, selectValue]);

  return (
    <div
      className={chartName === CHART_COST_TREND_ANALYSIS_NAME ? "vertical-chart-legend-trend" : "vertical-chart-legend"}
    >
      {!hideTypeDropdown && (
        <Select
          className="mb-15"
          options={typeOptions}
          placeholder={typePlaceholder}
          onChange={onSelectTypeChange}
          value={selectTypeValue}
          disabled={loading}
        />
      )}

      {!hideCategoryDropdown && (
        <Select
          className="mb-15"
          options={chartName === CHART_COST_ANALYSIS_NAME ? analysisTypes3 : analysisTypes2}
          placeholder="Select filter"
          onChange={onSelectChange}
          value={selectValue}
          disabled={loading}
        />
      )}
      <CheckboxesInput
        className="mb-15 checkboxes-input--align-select"
        showArrow
        selectAll
        searchable
        allowClear
        options={localLegendsCopy}
        defaultValue={getDefaultValue()}
        selectedValue={checkedList}
        clear={false}
        onChange={onChangeCheckboxes}
        placeholder={`Select ${dropdownName ? dropdownName : selectValue}`}
        getOptionsFromRequest={() => console.log("")}
        dropdownName={dropdownName ? dropdownName : selectValue}
      />
      {loading && (
        <div className="flex flex-justify-center mt-50">
          <Preloader size={30} />
        </div>
      )}
      {!loading && localLegendsCopy?.length > 0 && (
        <ul>
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} className="check-all">
            All
          </Checkbox>
          <CheckboxGroup value={checkedList} onChange={onChangeCheckboxes}>
            {localLegendsCopy.map((option, idx) => (
              <li key={option.name || idx} onMouseEnter={onMouseEnter(option)} onMouseLeave={onMouseLeave(option)}>
                <span
                  className="checkbox-overlay"
                  style={{
                    background: chartName === CHART_COST_TREND_ANALYSIS_NAME ? option.costTrendColor : option.color
                  }}
                />
                <Checkbox value={option}>{option.name}</Checkbox>
              </li>
            ))}
          </CheckboxGroup>
        </ul>
      )}
    </div>
  );
};

export default LegendsCheckboxes;
