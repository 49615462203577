import React from "react";
import { Modal as AntModal } from "antd";
import { connect } from "react-redux";
import classNames from "classnames";
import Close from "./Close";
import { closeModalAction } from "./reducer";

const Modal = (props) => {
  const { onClose, visible, className, title, children, size, rounded, ...rest } = props;

  return (
    <AntModal
      footer={null}
      onCancel={onClose}
      closable={false}
      maskClosable={false}
      visible={visible}
      wrapClassName={classNames("modal-wrapper", className, size, { "modal-no-title": !title, rounded })}
      destroyOnClose
      {...rest}
    >
      <div className="modal-header">
        <button type="button" className="modal-close" onClick={onClose}>
          <Close />
        </button>
        {title && <div className="modal-title">{title}</div>}
      </div>
      {children}
    </AntModal>
  );
};

const mapDispatchToProps = (dispatch, { name }) => ({
  onClose: () => dispatch(closeModalAction({ name }))
});

const mapStateToProps = (state, { name }) => {
  const modalState = state.modal ? state.modal[name] : null;

  return {
    visible: modalState ? !!modalState.visible : false
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
