import { useRef, useEffect } from "react";
import useSWR, { mutate } from "swr";
import isEqual from "lodash/isEqual";
import { useSelector } from "react-redux";
import axios from "../axios";

// Initialize a global counter for the number of requests
let requestCounter = 0;
export const usePrevious = (value) => {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const useLoggedUser = () => {
  const userState = useSelector((state) => state.auth?.user);

  return userState || {};
};

export const getRequestCounter = () => {
  return requestCounter;
};

export const useRequestSWR = ({
  requestKeyName,
  apiRoute,
  swrOptions,
  method = "get",
  params,
  onStart,
  onSuccess,
  transformer = (res) => res
}) => {
  const source = useRef(axios.CancelToken.source());
  const previousParams = usePrevious(params);
  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      source.current.cancel();
    };
  }, []);
  const { data, error, isValidating } = useSWR(
    requestKeyName,
    async () => {
      // eslint-disable-next-line no-useless-catch
      onStart();
      try {
        const response = await axios[method](apiRoute, { ...params }, { cancelToken: source.current.token });
        onSuccess();
        return response;
      } catch (err) {
        onSuccess();
        console.error(error);
        throw err;
      }
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      revalidateOnMount: true,
      ...(swrOptions || {})
    }
  );

  const reFetch = () => {
    mutate(requestKeyName);
  };

  useEffect(() => {
    if (requestKeyName && !isEqual(params, previousParams)) {
      mutate(requestKeyName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const loading = isValidating || (!error && !data);

  return {
    data: transformer(data?.data?.data, loading),
    isError: error,
    loading,
    source: source.current,
    reFetch
  };
};
