import { Font, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import omitBy from "lodash/omitBy";
import isEmpty from "lodash/isEmpty";
import { Chart } from "chart.js";
import Heebo400 from "../../../fonts/Heebo400.ttf";
import Heebo500 from "../../../fonts/Heebo700.ttf";
import Heebo700 from "../../../fonts/Heebo700.ttf";
import config from "../../../config";
import { analysisTypes, analysisTypes3 } from "../../Dashboard/Charts/methods";
import axios from "../../../axios";
import { errorNotification } from "../../../components/Notification";
import { CHART_CPCC_NAME } from "../../Dashboard/CostPerCustomer/CostPerCustomerChart";
import { CHART_COST_ANALYSIS_NAME } from "../../Dashboard/CostAnalysis/CostAnalysisChart";
import { CHART_CPM_NAME } from "../../Dashboard/CostPerMille/CostPerMilleChart";
import { CHART_REACH_NAME } from "../../Dashboard/Reach/ReachChart";
import { CHART_COST_TREND_ANALYSIS_NAME } from "../../Dashboard/CostTrendAnalysis/CostTrendAnalysisChart";
import { prepareCPCCParams } from "../../Dashboard/methods";

// Register font
Font.register({ family: "Heebo400", src: Heebo400 });
Font.register({ family: "Heebo500", src: Heebo500 });
Font.register({ family: "Heebo700", src: Heebo700 });

// Create styles
export const baseStyle = (newStyle = {}) => {
  return StyleSheet.create({
    page: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 20,
      backgroundColor: "#fff",
      fontSize: 10,
      fontFamily: "Heebo400",
      color: "#002060"
    },
    bold: {
      fontFamily: "Heebo700"
    },
    table: {
      width: "100%",
      display: "flex",
      flexDirection: "column"
    },
    row: {
      width: "100%",
      display: "flex",
      flexDirection: "row"
    },
    column: {
      padding: "0 5px",
      width: "100%",
      display: "flex",
      flexDirection: "row"
    },
    ...newStyle
  });
};

export const extendStyle = (style = {}, extendStyle = {}) => {
  return StyleSheet.create({ ...style, ...extendStyle });
};

export const downloadPDFBlob = (blob, name = `${moment().format(config.dateFormat.compressed)}_taico_report.pdf`) => {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) return window.navigator.msSaveOrOpenBlob(blob);

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = data;
  link.download = name;

  // this is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window
    })
  );

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
    link.remove();
  }, 100);
};

export const downloadPDFfromBase64 = (base64, fileName) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = base64;
  downloadLink.download = fileName + ".pdf";
  downloadLink.click();
};

export const preparePDFReportData = ({ data = {}, onSuccess, onFailed, onStart }) => {
  const {
    channels,
    campaigns,
    properties,
    publishers,
    mediaTypes,
    mediaClusters,
    startDate,
    endDate,
    cpccVisible,
    costVisible,
    cpmVisible,
    costTrendVisible,
    reachVisible,
    funnelData,
    totalSpendingsVisible,
    totalSpendingTable,
    totalSpendingValue,
    chartCpccAnalyseType = analysisTypes[0].value,
    chartCpmAnalyseType = analysisTypes[0].value,
    chartReachAnalyseType = analysisTypes[0].value,
    chartCostAnalysisAnalyseType = analysisTypes3[0].value,
    chartCostTrendAnalyseType = analysisTypes[0].value
  } = data;

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const params = prepareCPCCParams({ ...data }, {}, "id");

  const promiseChannels =
    channels?.length > 0
      ? Promise.resolve({ data: { data: channels } })
      : axios.post("data/names", { ...params, type_name: "channel" }, { cancelToken: source.token });
  const promiseCampaigns =
    campaigns?.length > 0
      ? Promise.resolve({ data: { data: campaigns } })
      : axios.post("data/names", { ...params, type_name: "campaign" }, { cancelToken: source.token });
  const promiseProperties =
    properties?.length > 0
      ? Promise.resolve({ data: { data: properties } })
      : axios.post("data/names", { ...params, type_name: "property" }, { cancelToken: source.token });
  const promisePublishers =
    publishers?.length > 0
      ? Promise.resolve({ data: { data: publishers } })
      : axios.post("data/names", { ...params, type_name: "publisher" }, { cancelToken: source.token });
  const promiseMediaTypes =
    mediaTypes?.length > 0
      ? Promise.resolve({ data: { data: mediaTypes } })
      : axios.post("data/names", { ...params, type_name: "mediatype" }, { cancelToken: source.token });
  const promiseMediaClusters =
    mediaClusters?.length > 0
      ? Promise.resolve({ data: { data: mediaClusters } })
      : axios.post("data/names", { ...params, type_name: "mediacluster" }, { cancelToken: source.token });

  async function _addLegendAndCreateImage(chart) {
    if (chart.config.type !== "bar") {
      return new Promise((resolve) => {
        chart.options.plugins.legend.display = true;
        chart.update("none");

        const base64Image = chart.toBase64Image();

        chart.options.plugins.legend.display = false;
        chart.update("none");
        resolve(base64Image);
      });
    } else {
      return new Promise((resolve) => {
        const oldLegendConfig = chart.options.plugins.legend;

        chart.options.plugins.legend = {
          display: true,
          labels: {
            generateLabels(chart) {
              const data = chart.data;
              if (data.labels.length && data.datasets.length) {
                return data.labels.map((label, i) => {
                  const meta = chart.getDatasetMeta(0);
                  const style = meta.controller.getStyle(i);
                  return {
                    text: label,
                    fillStyle: style.backgroundColor,
                    strokeStyle: style.borderColor,
                    hidden: !chart.getDataVisibility(i),
                    index: i
                  };
                });
              }
            }
          }
        };
        chart.update("none");

        const base64Image = chart.toBase64Image();

        chart.options.plugins.legend.display = oldLegendConfig.display;
        chart.options.plugins.legend.labels = {};
        chart.update("none");

        resolve(base64Image);
      });
    }
  }

  onStart && onStart(source);
  Promise.all([
    promiseChannels,
    promiseCampaigns,
    promisePublishers,
    promiseProperties,
    promiseMediaTypes,
    promiseMediaClusters
  ]).then(
    async (res) => {
      const result = {
        filters: {},
        cpcc: {},
        cpm: {},
        cpcd: {},
        reach: {},
        costTrend: {},
        totalSpending: {},
        funnel: {}
      };
      console.log(res);
      console.log(res[0]);
      console.log(res[0].data);
      console.log(res[0].data.data);
      result.filters.channel = res[0].data.data.map((item) => ({ ...item, label: item.name }));
      result.filters.campaign = res[1].data.data.map((item) => ({ ...item, label: item.name }));
      result.filters.publisher = res[2].data.data.map((item) => ({ ...item, label: item.name }));
      result.filters.property = res[3].data.data.map((item) => ({ ...item, label: item.name }));
      result.filters.mediaTypes = res[4].data.data.map((item) => ({ ...item, label: item.name }));
      result.filters.mediaClusters = res[5].data.data.map((item) => ({ ...item, label: item.name }));
      result.filters.date = `${startDate} - ${endDate}`;

      if (cpccVisible) {
        result.cpcc.analysis_type = chartCpccAnalyseType;
        const chartCpcc = Chart.getChart(CHART_CPCC_NAME);
        const chartCpcc2 = Chart.getChart(CHART_CPCC_NAME + "2");
        if (chartCpcc2) {
          result.cpcc.image2 = await _addLegendAndCreateImage(chartCpcc2);
        }
        if (chartCpcc) {
          result.cpcc.image = await _addLegendAndCreateImage(chartCpcc);
        }
      }

      if (cpmVisible) {
        result.cpm.analysis_type = chartCpmAnalyseType;
        const chartCpm = Chart.getChart(CHART_CPM_NAME);
        const chartCpm2 = Chart.getChart(CHART_CPM_NAME + "2");
        if (chartCpm2) {
          result.cpm.image2 = await _addLegendAndCreateImage(chartCpm2);
        }
        if (chartCpm) {
          result.cpm.image = await _addLegendAndCreateImage(chartCpm);
        }
      }

      if (reachVisible) {
        result.reach.analysis_type = chartReachAnalyseType;
        const chartReach = Chart.getChart(CHART_REACH_NAME);
        const chartReach2 = Chart.getChart(CHART_REACH_NAME + "2");
        if (chartReach2) {
          result.reach.image2 = await _addLegendAndCreateImage(chartReach2);
        }
        if (chartReach) {
          result.reach.image = await _addLegendAndCreateImage(chartReach);
        }
      }

      if (costVisible) {
        result.cpcd.analysis_type = chartCostAnalysisAnalyseType;
        const chartCostTrend = Chart.getChart(CHART_COST_ANALYSIS_NAME);
        const chartCostTrend2 = Chart.getChart(CHART_COST_ANALYSIS_NAME + "2");
        if (chartCostTrend2) {
          result.cpcd.image2 = await _addLegendAndCreateImage(chartCostTrend2);
        }
        if (chartCostTrend) {
          result.cpcd.image = await _addLegendAndCreateImage(chartCostTrend);
        }
      }

      if (costTrendVisible) {
        result.costTrend.analysis_type = chartCostTrendAnalyseType;
        const chartCostTrend = Chart.getChart(CHART_COST_TREND_ANALYSIS_NAME);
        const chartCostTrend2 = Chart.getChart(CHART_COST_TREND_ANALYSIS_NAME + "2");
        if (chartCostTrend2) {
          result.costTrend.image2 = await _addLegendAndCreateImage(chartCostTrend2);
        }
        if (chartCostTrend) {
          result.costTrend.image = await _addLegendAndCreateImage(chartCostTrend);
        }
      }

      result.funnel.image = funnelData["image"];

      if (totalSpendingsVisible) {
        result.totalSpending.data = (totalSpendingTable?.result || []).map((item) => ({
          ...item,
          label: item["Channel"] || item["Campaign"] || item["Publisher"] || item["Property"],
          spent: item["Spend (€)"],
          reach: item["Reach"],
          cpcc: item["CpCC (€)"]
        }));
        result.totalSpending.total = totalSpendingValue;

        if (result.totalSpending.data.length > 0) {
          if (result.totalSpending.data[0]["Channel"]) {
            result.totalSpending.columnName = "Channels";
          }
          if (result.totalSpending.data[0]["Campaign"]) {
            result.totalSpending.columnName = "Campaign";
          }
          if (result.totalSpending.data[0]["Publisher"]) {
            result.totalSpending.columnName = "Publishers";
          }
          if (result.totalSpending.data[0]["Properties"]) {
            result.totalSpending.columnName = "Properties";
          }
        }
      }

      onSuccess && onSuccess(omitBy(result, isEmpty));
    },
    (err) => {
      errorNotification({ description: "Some processes from PDF report failed." });
      onFailed && onFailed(err.response);
    }
  );
};
