import React from "react";
import Modal from "../../../components/Modal";
import { SAVE_MODAL } from "../config";
import SaveModalForm from "./SaveModalForm";

const SaveModal = ({ type }) => {
  return (
    <Modal width={530} name={SAVE_MODAL} className="rounded">
      <SaveModalForm type={type} />
    </Modal>
  );
};

export default SaveModal;
