import React, { useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import isEqual from "lodash/isEqual";
import classNames from "classnames";
import { connect } from "react-redux";
import { usePrevious } from "../../../utils/custom-hooks";
import Preloader from "../../../components/Preloader";
import NoData from "../../../components/NoData";
import { CHART_COST_TREND_ANALYSIS_NAME } from "../CostTrendAnalysis/CostTrendAnalysisChart";
import { formatCostTrendLineChartData, formatLineChartData, lineChartOptions, swapActiveChartColors } from "./methods";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

const LineChart = (props) => {
  const { data = [], activeDataset, visibleDatasets, loading, parserKeys = {}, chartName, dashboardFilters } = props;
  const lineChartRef = useRef(null);
  const options = lineChartOptions(dashboardFilters?.yoy);
  const previousActiveDataset = usePrevious(activeDataset);
  const noDataToShow = !loading && data.length === 0;

  useEffect(() => {
    if (!isEqual(previousActiveDataset, activeDataset)) {
      swapActiveChartColors(lineChartRef.current, activeDataset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDataset]);

  return (
    <div className={classNames("line-chart-container", { loading, "no-data": noDataToShow })}>
      {loading && <Preloader size={30} />}
      {noDataToShow && (
        <div className="no-chart-data">
          <NoData />
        </div>
      )}
      {dashboardFilters?.yoy ? (
        <div className="legend">
          <div className="current-year mr-5"></div>
          <span style={{ fontSize: 12 }} className="mr-10">
            Current Year
          </span>
          <div className="previous-year mr-5"></div>
          <span style={{ fontSize: 12 }}>Previous Year</span>
        </div>
      ) : null}
      <Line
        id={chartName}
        options={options}
        data={{
          datasets:
            chartName === CHART_COST_TREND_ANALYSIS_NAME
              ? formatCostTrendLineChartData({
                  data,
                  visible: visibleDatasets,
                  nameKey: parserKeys.nameKey,
                  valueKey: parserKeys.valueKey
                })
              : formatLineChartData({
                  data,
                  visible: visibleDatasets,
                  nameKey: parserKeys.nameKey,
                  valueKey: parserKeys.valueKey
                })
        }}
        ref={lineChartRef}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  dashboardData: state.dashboardData
});

export default connect(mapStateToProps)(LineChart);
