/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Back from "../../../../images/icons/Back";
import Preloader from "../../../../components/Preloader";
import "./style.scss";
import Button from "../../../../components/Button";
import axios from "../../../../axios";
import UpdateRuleModel from "./UpdateRule/UpdateModal";
import UpdateTargetRulePopupModal from "./UpdateRule/Modal";

const TargetDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (location.state && location.state.record) {
      setData(location.state.record);
      setLoading(false);
    }
  }, [location]);

  const fetchDataById = useCallback(
    (id) => {
      const source = axios.CancelToken.source();
      setLoading(true);

      axios
        .get(`targets/${id}`, { cancelToken: source.token })
        .then((response) => {
          setData(response.data.data);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log("Request canceled", error.message);
          } else {
            console.error("Error fetching data", error);
          }
        })
        .finally(() => {
          setLoading(false);
        });

      return () => {
        source.cancel("Request canceled"); // Cancel the request when the component unmounts or when the function is called again
      };
    },
    [data]
  );

  const reloadData = () => {
    fetchDataById(data.id);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    setLoading(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showUpdateModal = () => {
    setIsUpdateModalOpen(true);
  };

  const handleUpdateModalCancel = () => {
    setIsUpdateModalOpen(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    console.log(dateString);
    console.log(date);
    const formattedDate = `${new Intl.DateTimeFormat("en-US", { month: "short" }).format(date)} ${date.getFullYear()}`;
    return formattedDate;
  };

  const formatString = (costType) => {
    return costType.charAt(0).toUpperCase() + costType.slice(1).toLowerCase();
  };

  return (
    <>
      <div className="flex flex-items-center title-medium mr-20 mb-10 mt-15 flex-nowrap">
        <button
          type="button"
          className="back-button"
          onClick={() => {
            history.goBack();
          }}
        >
          <Back />
        </button>
        <div className="mr-5 nowrap">Targets</div>
      </div>

      <div className="rounded-box cost-management-box">
        {loading ? (
          <Preloader size={30} />
        ) : (
          <div>
            <div className="title-info-container">
              <div className="mr-5">{data?.title}</div>
              <div className="mr-5">Last Updated: {data?.updated_at.substring(0, 10)}</div>
            </div>
            <div className="divider"></div>
            <div className="details-container">
              <div className="detail-row">
                <div className="mr-5 title-container">Date period : </div>
                <div className="mr-5 value-container">
                  {formatDate(data?.period_start)} - {formatDate(data?.period_end)}
                </div>
              </div>
              <div className="detail-row">
                <div className="mr-5 title-container">Channels : </div>
                <div className="mr-5 value-container">
                  {data?.channels
                    ?.map(function (item) {
                      return item?.name;
                    })
                    .join(", ")}
                </div>
              </div>
              <div className="detail-row">
                <div className="mr-5 title-container">Publishers : </div>
                <div className="mr-5 value-container">
                  {data?.publishers
                    ?.map(function (item) {
                      return item?.name;
                    })
                    .join(", ")}
                </div>
              </div>
              <div className="detail-row">
                <div className="mr-5 title-container">Campaigns : </div>
                <div className="mr-5 value-container">
                  {data?.campaigns
                    ?.map(function (item) {
                      return item?.name;
                    })
                    .join(", ")}
                </div>
              </div>
              <div className="detail-row">
                <div className="mr-5 title-container">Properties : </div>
                <div className="mr-5 value-container">
                  {data?.properties
                    ?.map(function (item) {
                      return item?.name;
                    })
                    .join(", ")}
                </div>
              </div>
            </div>
            <div className="divider"></div>
            <div className="details-container">
              <div className="detail-row">
                <div className="mr-5 title-container">Target Type :</div>
                <div className="mr-5 value-container">{data?.target_type}</div>
              </div>
              <div className="detail-row">
                <div className="mr-5 title-container">Target Value :</div>
                <div className="mr-5 value-container">{data?.target_value}</div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="rounded-box update-box">
        <div className="update-info-container">
          <div className="mr-5">Update the target rule?</div>
          <div className="mr-5 button-container">
            <div>
              <Button
                type="primary"
                style={{ padding: "5px 15px", width: "90px", marginRight: "20px" }}
                className="ml-a"
                onClick={showUpdateModal}
              >
                Edit
              </Button>
              <UpdateRuleModel
                data={data}
                visible={isUpdateModalOpen}
                onCancel={handleUpdateModalCancel}
                reloadData={reloadData}
              />
            </div>
            <div>
              <Button
                type="primary"
                style={{ padding: "5px 15px", width: "90px" }}
                className="ml-a"
                onClick={showModal}
              >
                {data?.active ? "Deactivate" : "Activate"}
              </Button>
              <UpdateTargetRulePopupModal visible={isModalOpen} onCancel={handleCancel} data={data} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TargetDetails;
