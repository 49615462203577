import { useState, useEffect } from "react";

export const useCursorMovingDown = () => {
  const [isMovingDown, setIsMovingDown] = useState(false);
  const [lastY, setLastY] = useState(null);

  useEffect(() => {
    const handleMouseMove = (event) => {
      const currentY = event.clientY;

      if (lastY !== null) {
        if (currentY > lastY) {
          setIsMovingDown(true);
        } else {
          setIsMovingDown(false);
        }
      }

      setLastY(currentY);
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [lastY]);

  return isMovingDown;
};
