import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import CheckToken from "../../components/_auth/CheckToken";
import loadable from "../../utils/loadable";
import Layout from "../../components/_layout";
import CostManagementPage from "../CostManagement";
import CostManagementDetails from "../CostManagement/CostManagementDetails";
import TargetDetails from "../Targets/cards/overview/TargetDetails";
import AuthenticatedRoute from "./RouterRoutes/AuthenticatedRoute";
import UnauthenticatedRoute from "./RouterRoutes/UnauthenticatedRoute";
import PublicRoute from "./RouterRoutes/PublicRoute";
import routes from "./routes";

// const RegisterPage = loadable(() => import("../Register"));
const LoginPage = loadable(() => import("../Login"));
const DashboardPage = loadable(() => import("../Dashboard"));
const TargetsPage = loadable(() => import("../Targets"));

const NotFoundPage = loadable(() => import("../NotFound"));
const ComingSoonPage = loadable(() => import("../ComingSoon"));
const ForgotPasswordPage = loadable(() => import("../ForgotPassword"));
const ResetPasswordPage = loadable(() => import("../ResetPassword"));
const MyAccountPage = loadable(() => import("../MyAccount"));
const UsersAccessPage = loadable(() => import("../UsersAccess"));
const MyReportsPage = loadable(() => import("../MyReports"));
const DataCenterMyChannels = loadable(() => import("../DataCenterMyChannels"));
const RequestDemoPage = loadable(() => import("../RequestDemo"));
const SandboxPage = loadable(() => import("../Sandbox"));
const PlanningCenter = loadable(() => import("../PlanningCenter"));
const PlanDetails = loadable(() => import("../PlanningCenter/PlanDetails"));
const ModelsCenter = loadable(() => import("../ModelsManagementCenter"));
const LandingPage = loadable(() => import("../LandingPage"));

const App = () => {
  return (
    <CheckToken>
      <Router>
        <Switch>
          {/* Unauthenticated pages */}
          <UnauthenticatedRoute path={routes.LOGIN_PAGE}>
            <LoginPage />
          </UnauthenticatedRoute>
          {/* <UnauthenticatedRoute path={routes.REGISTER_PAGE}>
            <RegisterPage />
          </UnauthenticatedRoute> */}
          <UnauthenticatedRoute path={routes.REQUEST_DEMO}>
            <RequestDemoPage />
          </UnauthenticatedRoute>
          <UnauthenticatedRoute path={routes.FORGOT_PASSWORD_PAGE}>
            <ForgotPasswordPage />
          </UnauthenticatedRoute>
          <UnauthenticatedRoute path={routes.RESET_PASSWORD_PAGE}>
            <ResetPasswordPage />
          </UnauthenticatedRoute>

          {/* Public pages */}
          <PublicRoute path={routes.NOT_FOUND_PAGE} component={NotFoundPage} />

          {/* Authenticated pages */}
          <Route>
            <Switch>
              <AuthenticatedRoute exact path={routes.LANDING_PAGE} layout={null}>
                <LandingPage />
              </AuthenticatedRoute>
              <Route>
                <Layout>
                  <Switch>
                    <AuthenticatedRoute exact path={routes.DASHBOARD_PAGE}>
                      <DashboardPage />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute exact path={routes.MY_ACCOUNT}>
                      <MyAccountPage />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute exact path={routes.ACCESS}>
                      <UsersAccessPage />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute exact path={routes.MY_REPORTS}>
                      <MyReportsPage />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute exact path={routes.DATA_CENTER_MY_CHANNELS}>
                      <DataCenterMyChannels />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute exact path={routes.SANDBOX}>
                      <SandboxPage />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute exact path={routes.TARGETS_PAGE}>
                      <TargetsPage />
                    </AuthenticatedRoute>

                    <AuthenticatedRoute exact path={routes.PLANNING_CENTER}>
                      <PlanningCenter />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute exact path={routes.PLANNING_CENTER_DETAILS}>
                      <PlanDetails />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute exact path={routes.MODELS_CENTER}>
                      <ModelsCenter />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute exact path={routes.COST_MANAGEMENT}>
                      <CostManagementPage />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute exact path={routes.COST_MANAGEMENT_DETAILS}>
                      <CostManagementDetails />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute exact path={routes.TARGETS_DETAILS}>
                      <TargetDetails />
                    </AuthenticatedRoute>

                    <AuthenticatedRoute exact path={routes.COMING_SOON}>
                      <ComingSoonPage />
                    </AuthenticatedRoute>

                    <Redirect from={routes.LOGIN_PAGE} to={routes.LOGIN_PAGE} />
                    {/* 404 Not found page */}
                    <Redirect from="*" to={routes.NOT_FOUND_PAGE} />
                  </Switch>
                </Layout>
              </Route>
            </Switch>
          </Route>
        </Switch>
      </Router>
    </CheckToken>
  );
};

export default App;
