import moment from "moment";

export const filtersDateFormat = "MM/DD/YYYY";

export const prepareFilters = (filters = {}) => {
  const { channels = [], publishers = [], properties = [], startDate, endDate, typeDate, yoy = false } = filters;

  return {
    cpccVisible: true,
    totalSpendingsVisible: true,
    reachVisible: true,
    cpmVisible: true,
    costVisible: true,
    costTrendVisible: true,
    ...filters,
    channels,
    publishers,
    properties,
    startDate: startDate || moment().subtract(5, "years").format(filtersDateFormat),
    endDate: endDate || moment().format(filtersDateFormat),
    typeDate: typeDate || "week",
    yoy: yoy
  };
};
