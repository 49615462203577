import React from "react";

const Info = () => {
  return (
    <div className="notification">
      <div className="notification-header">
        <span className="notification-title">Contact</span>
      </div>
      <div className="content">
        <p>
          <font size="3">
            If you want to learn more about us, visit <a href="https://taico.io/">our website</a>!
          </font>
        </p>
        <p>
          <font size="3">
            Contact us at <a href="mailto: api@taico.io">api@taico.io</a>.
          </font>
        </p>
      </div>
    </div>
  );
};

export default Info;
