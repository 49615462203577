import React from "react";
import { Modal } from "antd";
import classNames from "classnames";
import UpdateBusinessRuleForm from "./Form";

const UpdateBusinessRuleModel = ({ data, visible, onCancel, reloadPanningCenterData }) => {
  return (
    <Modal
      width={850}
      name={"UPDATE_PLAN"}
      wrapClassName={classNames("modal-wrapper", "rounded", 850)}
      visible={visible}
      header={null}
      footer={null}
      onCancel={onCancel}
    >
      <UpdateBusinessRuleForm data={data} reloadPanningCenterData={reloadPanningCenterData} onCancel={onCancel} />
    </Modal>
  );
};

export default UpdateBusinessRuleModel;
