import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import isEqual from "lodash/isEqual";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import axios from "../../axios";
import CheckboxesInput from "../CheckboxesInput";
import { ReactComponent as DatabaseSearchIcon } from "../../images/icons/database-search.svg";
import { ReactComponent as CalendarIcon } from "../../images/icons/calendar.svg";
import DateRangePicker from "../DateRangePicker";
import Button from "../Button";
import routes from "../../pages/App/routes";
import { prepareFilters, prepareFilterParams, filtersDateFormat } from "./methods";
import { globalFiltersChangeAction, globalFiltersInitialState } from "./reducer";
import { useAreGlobalFitersVisible } from "./useAreGlobalFitersVisible";

const metrics = [
  {
    id: "cpccVisible",
    name: "Cost per customer contact"
  },
  {
    id: "reachVisible",
    name: "Reach"
  },
  {
    id: "grpVisible",
    name: "GRP"
  },
  {
    id: "costVisible",
    name: "Cost Per Channel Detail"
  },
  {
    id: "costTrendVisible",
    name: "Cost Trends"
  },
  {
    id: "totalSpendingsVisible",
    name: "Total spending"
  }
];

const GlobalFilters = (props) => {
  const location = useLocation();
  const globalFiltersState = useSelector((state) => state.globalFilters);
  const fetchingReqCounterState = useSelector((state) => state.fetching);
  const userState = useSelector((state) => state?.auth?.user);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(globalFiltersState);
  const [yoyDisabledMessage, setYoyDisabledMessage] = useState("");
  const [reset, setReset] = useState(undefined);
  const [apply, setApply] = useState(undefined);
  const [initialSelectedValues, setInitialSelectedValues] = useState([]);
  const [channelsInitialLength, setChannelsInitialLength] = useState(
    localStorage.getItem("channelsInitialLength") ? Number(localStorage.getItem("channelsInitialLength")) : 0
  );
  const [audiencesInitialLength, setAudiencesInitialLength] = useState(
    localStorage.getItem("audiencesInitialLength") ? Number(localStorage.getItem("audiencesInitialLength")) : 0
  );
  const [productsInitialLength, setProductsInitialLength] = useState(
    localStorage.getItem("productsInitialLength") ? Number(localStorage.getItem("productsInitialLength")) : 0
  );
  const [productGroupsInitialLength, setProductGroupsInitialLength] = useState(
    localStorage.getItem("productGroupsInitialLength") ? Number(localStorage.getItem("productGroupsInitialLength")) : 0
  );
  const [campaignsInitialLength, setCampaignsInitialLength] = useState(
    localStorage.getItem("campaignsInitialLength") ? Number(localStorage.getItem("campaignsInitialLength")) : 0
  );
  const [publishersInitialLength, setPublishersInitialLength] = useState(
    localStorage.getItem("publishersInitialLength") ? Number(localStorage.getItem("publishersInitialLength")) : 0
  );
  const [propertiesInitialLength, setPropertiesInitialLength] = useState(
    localStorage.getItem("propertiesInitialLength") ? Number(localStorage.getItem("propertiesInitialLength")) : 0
  );
  const [mediaTypesInitialLength, setMediaTypesInitialLength] = useState(
    localStorage.getItem("mediaTypesInitialLength") ? Number(localStorage.getItem("mediaTypesInitialLength")) : 0
  );
  const [mediaClustersInitialLength, setMediaClustersInitialLength] = useState(
    localStorage.getItem("mediaClustersInitialLength") ? Number(localStorage.getItem("mediaClustersInitialLength")) : 0
  );

  const [onApplyDisabled, setOnApplyDisabled] = useState(true);
  const [localOptions, setLocalOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isVisible, setIsMouseOver } = useAreGlobalFitersVisible();

  useEffect(() => {
    fetchFilterLengths();
    getOptionsFromRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchingReqCounterState.fetchingCounter === 0) {
      setOnApplyDisabled(false);
    } else {
      setOnApplyDisabled(true);
    }
  }, [fetchingReqCounterState.fetchingCounter]);

  useEffect(() => {
    if (globalFiltersState.startDate && globalFiltersState.endDate) {
      const validIntervalYoy = intervalIsSmallerThanOneYear(
        moment(globalFiltersState.startDate, filtersDateFormat),
        moment(globalFiltersState.endDate, filtersDateFormat)
      );
      setYoyDisabledMessage(
        validIntervalYoy ? "" : "For YOY the time period should be less than or equal to 12 months"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const namesReqPayload = {
    "date": "04/01/2018 - 04/06/2023",
    "yoy": false
  };

  const source = axios.CancelToken.source();

  const fetchFilterLengths = () => {
    axios
      .post("/data/filter_lengths", namesReqPayload, {
        cancelToken: source.token // pass the cancel token to the request
      })
      .then((response) => {
        const lengths = response.data.data;
        const storedChannelsLength = localStorage.getItem("channelsInitialLength");
        if (!storedChannelsLength && lengths.channels !== 0) {
          localStorage.setItem("channelsInitialLength", lengths.channels);
          setChannelsInitialLength(lengths.channels);
        }

        const storedAudiencesLength = localStorage.getItem("audiencesInitialLength");
        if (!storedAudiencesLength && lengths.audiences !== 0) {
          localStorage.setItem("audiencesInitialLength", lengths.audiences);
          setAudiencesInitialLength(lengths.audiences);
        }

        const storedProductsLength = localStorage.getItem("productsInitialLength");
        if (!storedProductsLength && lengths.products !== 0) {
          localStorage.setItem("productsInitialLength", lengths.products);
          setProductsInitialLength(lengths.products);
        }

        const storedProductGroupsLength = localStorage.getItem("productGroupsInitialLength");
        if (!storedProductGroupsLength && lengths.productGroups !== 0) {
          localStorage.setItem("productGroupsInitialLength", lengths.productGroups);
          setProductGroupsInitialLength(lengths.productGroups);
        }

        const storedCampaignsLength = localStorage.getItem("campaignsInitialLength");
        if (!storedCampaignsLength && lengths.campaigns !== 0) {
          localStorage.setItem("campaignsInitialLength", lengths.campaigns);
          setCampaignsInitialLength(lengths.campaigns);
        }

        const storedPublishersLength = localStorage.getItem("publishersInitialLength");
        if (!storedPublishersLength && lengths.publishers !== 0) {
          setPublishersInitialLength(lengths.publishers);
          localStorage.setItem("publishersInitialLength", lengths.publishers);
        }

        const storedPropertiesLength = localStorage.getItem("propertiesInitialLength");
        if (!storedPropertiesLength && lengths.properties !== 0) {
          setPropertiesInitialLength(lengths.properties);
          localStorage.setItem("propertiesInitialLength", lengths.properties);
        }

        const storedMediaTypesLength = localStorage.getItem("mediaTypesInitialLength");
        if (!storedMediaTypesLength && lengths.mediaTypes !== 0) {
          setMediaTypesInitialLength(lengths.mediaTypes);
          localStorage.setItem("mediaTypesInitialLength", lengths.mediaTypes);
        }

        const storedMediaClustersLength = localStorage.getItem("mediaClustersInitialLength");
        if (!storedMediaClustersLength && lengths.mediaClusters !== 0) {
          setMediaClustersInitialLength(lengths.mediaClusters);
          localStorage.setItem("mediaClustersInitialLength", lengths.mediaClusters);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onChange = useCallback(
    (type) => (value) => {
      let newFilters;
      if (type === "date") {
        const { type, val } = value || {};
        newFilters = prepareFilters({
          ...filters,
          startDate:
            val?.length > 0
              ? String(type) !== "week"
                ? val[0].startOf(String(type)).format(filtersDateFormat)
                : moment(val[0]).format(filtersDateFormat)
              : undefined,
          endDate:
            val?.length > 0
              ? String(type) !== "week"
                ? val[1].endOf(String(type)).format(filtersDateFormat)
                : moment(val[1]).format(filtersDateFormat)
              : undefined,
          typeDate: type
        });

        const validIntervalYoy = intervalIsSmallerThanOneYear(moment(newFilters.startDate), moment(newFilters.endDate));

        setYoyDisabledMessage(
          validIntervalYoy ? "" : "For YOY the time period should be less than or equal to 12 months"
        );

        if (filters.yoy && !validIntervalYoy) {
          newFilters = { ...newFilters, yoy: false };
        }
      } else {
        newFilters = prepareFilters({ ...filters, [type]: value });
      }

      if (!isEqual(newFilters, filters)) {
        setFilters(prepareFilters(newFilters));
      }
    },
    [filters]
  );

  const onClear = useCallback(() => {
    const initialFilters = prepareFilters({});
    setFilters(prepareFilters(initialFilters));
    if (filters) {
      dispatch(globalFiltersChangeAction({ ...initialFilters, resetLocalStorage: true }));
    }
    getOptionsFromRequest();
    setReset(!reset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reset]);

  const isDateRangeValid = (startDate, endDate) => {
    const start = moment(startDate, filtersDateFormat);
    const end = moment(endDate, filtersDateFormat);
    return start.isValid() && end.isValid() && end.isAfter(start);
  };

  const onApply = useCallback(() => {
    setApply(!apply);
    dispatch(globalFiltersChangeAction({ ...filters, _id: userState?._id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filters, userState]);

  const onSwitchChange = useCallback(
    (value) => {
      let newMetrics = {};
      metrics.forEach((item) => {
        if (value.includes(item)) {
          newMetrics[item.id] = true;
        } else {
          newMetrics[item.id] = false;
        }
      });
      setFilters(prepareFilters({ ...filters, ...newMetrics }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, apply]
  );

  const intervalIsSmallerThanOneYear = (startDate, endDate) => {
    const startDatePlusOneYear = moment(startDate).add(1, "year");
    return endDate.isSameOrBefore(startDatePlusOneYear);
  };

  const checkNumberOfMetricsSelected = (filters) => {
    let numberSelected = 0;
    if (filters.cpccVisible) {
      numberSelected += 1;
    }
    if (filters.totalSpendingsVisible) {
      numberSelected += 1;
    }
    if (filters.reachVisible) {
      numberSelected += 1;
    }
    if (filters.costVisible) {
      numberSelected += 1;
    }
    if (filters.costTrendVisible) {
      numberSelected += 1;
    }
    return numberSelected;
  };

  const transformer = (res) => {
    return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
  };

  const cancelRequest = axios.CancelToken.source();
  const getOptionsFromRequest = async () => {
    try {
      setLoading(true);
      const res = await axios.post("data/filters_names", prepareFilterParams({ ...filters }, {}, "options"), {
        cancelToken: cancelRequest.token // Pass the Cancel Token in the Request
      });

      let transformedOptions = {
        channel: [],
        campaign: [],
        publisher: [],
        property: [],
        media_type: [],
        media_cluster: [],
        audience: [],
        product: [],
        product_group: []
      };
      transformedOptions["channel"] = transformer(res.data?.data?.channel || res.data);

      transformedOptions["campaign"] = transformer(res.data?.data?.campaign || res.data);

      transformedOptions["publisher"] = transformer(res.data?.data?.publisher || res.data);

      transformedOptions["property"] = transformer(res.data?.data?.property || res.data);

      transformedOptions["media_type"] = transformer(res.data?.data?.media_type || res.data);

      transformedOptions["media_cluster"] = transformer(res.data?.data?.media_cluster || res.data);

      transformedOptions["audience"] = transformer(res.data?.data?.audience || res.data);

      transformedOptions["product"] = transformer(res.data?.data?.product || res.data);

      transformedOptions["product_group"] = transformer(res.data?.data?.product_group || res.data);

      setLocalOptions(transformedOptions);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOptionsFromRequest();
    return () => {
      if (cancelRequest) {
        cancelRequest.cancel("Operation canceled by the component unmounting"); // Cancel the request on component unmount
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const initialSelectedValues = metrics.filter((metric) => filters[metric.id]);
    setInitialSelectedValues(initialSelectedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, metrics]);

  useEffect(() => {
    return () => {
      setYoyDisabledMessage("");
      setReset(undefined);
      setApply(undefined);
      setInitialSelectedValues([]);
      source.cancel();
      cancelRequest.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames("statistics-filters rounded-box", props.className)}
      style={{
        maxHeight: isVisible ? "100%" : "0",
        padding: isVisible ? "16px" : "0",
        opacity: isVisible ? "1" : "0"
      }}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <header>
        <div className="title-medium">Filters</div>
        {location.pathname === routes.DASHBOARD_PAGE && (
          <div className="switchable-filters">
            <CheckboxesInput
              showArrow
              selectAll
              searchable
              initialLength={metrics.length}
              allowClear
              defaultValue={
                checkNumberOfMetricsSelected(filters) !== 0
                  ? `${
                      checkNumberOfMetricsSelected(filters) !== 1
                        ? checkNumberOfMetricsSelected(filters) + " visuals"
                        : checkNumberOfMetricsSelected(filters) + " visual"
                    } selected`
                  : "All visuals"
              }
              initialSelectedValues={initialSelectedValues}
              reset={reset}
              onChange={onSwitchChange}
              placeholder="Select visuals"
              icon={<DatabaseSearchIcon />}
              options={metrics}
              transformer={(res) => {
                return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
              }}
              dropdownName="metric"
              getOptionsFromRequest={() => {}}
            />
          </div>
        )}
      </header>
      <footer>
        <CheckboxesInput
          showArrow
          selectAll
          searchable
          allowClear
          defaultValue={
            filters?.channels?.length > 0
              ? filters?.channels?.length === channelsInitialLength
                ? "All channels"
                : `${filters?.channels?.length} channels selected`
              : "All channels"
          }
          selectedValue={
            filters?.channels?.length === 0
              ? reset !== undefined
                ? filters?.channels
                : localOptions["channel"]
              : filters?.channels
          }
          reset={reset}
          apply={apply}
          initialLength={channelsInitialLength}
          onChange={onChange("channels")}
          placeholder="Select channels"
          icon={<DatabaseSearchIcon />}
          apiRoute="data/filters_names"
          requestMethod="post"
          requestBody={prepareFilterParams({ ...filters }, {}, "channel")}
          transformer={(res) => {
            return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
          }}
          isLoading={loading}
          options={localOptions["channel"]}
          initialSelectedValues={[]}
          getOptionsFromRequest={getOptionsFromRequest}
          dropdownName="channel"
        />

        <CheckboxesInput
          showArrow
          selectAll
          searchable
          allowClear
          initialLength={campaignsInitialLength}
          defaultValue={
            filters?.campaigns?.length > 0
              ? filters?.campaigns?.length === campaignsInitialLength
                ? "All campaigns"
                : `${filters?.campaigns?.length} campaigns selected`
              : "All campaigns"
          }
          selectedValue={
            filters?.campaigns?.length === 0
              ? reset !== undefined
                ? filters?.campaigns
                : localOptions["campaign"]
              : filters?.campaigns
          }
          reset={reset}
          apply={apply}
          onChange={onChange("campaigns")}
          placeholder="Select campaigns"
          icon={<DatabaseSearchIcon />}
          apiRoute="data/filters_names"
          requestMethod="post"
          requestBody={prepareFilterParams({ ...filters }, {}, "campaign")}
          transformer={(res) => {
            return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
          }}
          options={localOptions["campaign"]}
          getOptionsFromRequest={getOptionsFromRequest}
          initialSelectedValues={[]}
          isLoading={loading}
          dropdownName="campaign"
        />
        <CheckboxesInput
          showArrow
          selectAll
          searchable
          allowClear
          initialLength={publishersInitialLength}
          defaultValue={
            filters?.publishers?.length > 0
              ? filters?.publishers?.length === publishersInitialLength
                ? "All publishers"
                : `${filters?.publishers?.length} publishers selected`
              : "All publishers"
          }
          selectedValue={
            filters?.publishers?.length === 0
              ? reset !== undefined
                ? filters?.publishers
                : localOptions["publisher"]
              : filters?.publishers
          }
          reset={reset}
          apply={apply}
          onChange={onChange("publishers")}
          placeholder="Select publishers"
          icon={<DatabaseSearchIcon />}
          apiRoute="data/filters_names"
          requestMethod="post"
          requestBody={prepareFilterParams({ ...filters }, {}, "publisher")}
          transformer={(res) => {
            return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
          }}
          options={localOptions["publisher"]}
          getOptionsFromRequest={getOptionsFromRequest}
          initialSelectedValues={[]}
          isLoading={loading}
          dropdownName="publisher"
        />
        <CheckboxesInput
          showArrow
          selectAll
          searchable
          allowClear
          initialLength={propertiesInitialLength}
          defaultValue={
            filters?.properties?.length > 0
              ? filters?.properties?.length === propertiesInitialLength
                ? "All properties"
                : `${filters?.properties?.length} properties selected`
              : "All properties"
          }
          selectedValue={
            filters?.properties?.length === 0
              ? reset !== undefined
                ? filters?.properties
                : localOptions["property"]
              : filters?.properties
          }
          reset={reset}
          apply={apply}
          onChange={onChange("properties")}
          placeholder="Select properties"
          icon={<DatabaseSearchIcon />}
          apiRoute="data/filters_names"
          requestMethod="post"
          requestBody={prepareFilterParams({ ...filters }, {}, "property")}
          transformer={(res) => {
            return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
          }}
          options={localOptions["property"]}
          initialSelectedValues={[]}
          getOptionsFromRequest={getOptionsFromRequest}
          isLoading={loading}
          dropdownName="property"
        />
        <CheckboxesInput
          showArrow
          selectAll
          searchable
          allowClear
          initialLength={mediaTypesInitialLength}
          defaultValue={
            filters?.mediaTypes?.length > 0
              ? filters?.mediaTypes?.length === mediaTypesInitialLength
                ? "All media types"
                : `${filters?.mediaTypes?.length} media selected`
              : "All media types"
          }
          selectedValue={
            filters?.mediaTypes?.length === 0
              ? reset !== undefined
                ? filters?.mediaTypes
                : localOptions["media_type"]
              : filters?.mediaTypes
          }
          reset={reset}
          apply={apply}
          onChange={onChange("mediaTypes")}
          placeholder="Select media types"
          icon={<DatabaseSearchIcon />}
          apiRoute="data/filters_names"
          requestMethod="post"
          requestBody={prepareFilterParams({ ...filters }, {}, "mediaType")}
          transformer={(res) => {
            return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
          }}
          options={localOptions["media_type"]}
          getOptionsFromRequest={getOptionsFromRequest}
          initialSelectedValues={[]}
          isLoading={loading}
          dropdownName="media type"
        />

        <CheckboxesInput
          showArrow
          selectAll
          searchable
          allowClear
          initialLength={mediaClustersInitialLength}
          defaultValue={
            filters?.mediaClusters?.length > 0
              ? filters?.mediaClusters?.length === mediaClustersInitialLength
                ? "All media clusters"
                : `${filters?.mediaClusters?.length} media clusters selected`
              : "All media clusters"
          }
          selectedValue={
            filters?.mediaClusters?.length === 0
              ? reset !== undefined
                ? filters?.mediaClusters
                : localOptions["media_cluster"]
              : filters?.mediaClusters
          }
          reset={reset}
          apply={apply}
          onChange={onChange("mediaClusters")}
          placeholder="Select media clusters"
          icon={<DatabaseSearchIcon />}
          apiRoute="data/filters_names"
          requestMethod="post"
          requestBody={prepareFilterParams({ ...filters }, {}, "media_cluster")}
          transformer={(res) => {
            return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
          }}
          options={localOptions["media_cluster"]}
          getOptionsFromRequest={getOptionsFromRequest}
          initialSelectedValues={[]}
          isLoading={loading}
          dropdownName="media_cluster"
        />

        <CheckboxesInput
          showArrow
          selectAll
          searchable
          allowClear
          defaultValue={
            filters?.audiences?.length > 0
              ? filters?.audiences?.length === audiencesInitialLength
                ? "All audiences"
                : `${filters?.audiences?.length} audiences selected`
              : "All audiences"
          }
          selectedValue={
            filters?.audiences?.length === 0
              ? reset !== undefined
                ? filters?.audiences
                : localOptions["audience"]
              : filters?.audiences
          }
          reset={reset}
          apply={apply}
          initialLength={audiencesInitialLength}
          onChange={onChange("audiences")}
          placeholder="Select audiences"
          icon={<DatabaseSearchIcon />}
          apiRoute="data/filters_names"
          requestMethod="post"
          requestBody={prepareFilterParams({ ...filters }, {}, "audience")}
          transformer={(res) => {
            return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
          }}
          isLoading={loading}
          options={localOptions["audience"]}
          initialSelectedValues={[]}
          getOptionsFromRequest={getOptionsFromRequest}
          dropdownName="audience"
        />

        <CheckboxesInput
          showArrow
          selectAll
          searchable
          allowClear
          defaultValue={
            filters?.products?.length > 0
              ? filters?.products?.length === productsInitialLength
                ? "All products"
                : `${filters?.audiences?.length} products selected`
              : "All products"
          }
          selectedValue={
            filters?.products?.length === 0
              ? reset !== undefined
                ? filters?.products
                : localOptions["product"]
              : filters?.products
          }
          reset={reset}
          apply={apply}
          initialLength={productsInitialLength}
          onChange={onChange("products")}
          placeholder="Select products"
          icon={<DatabaseSearchIcon />}
          apiRoute="data/filters_names"
          requestMethod="post"
          requestBody={prepareFilterParams({ ...filters }, {}, "product")}
          transformer={(res) => {
            return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
          }}
          isLoading={loading}
          options={localOptions["product"]}
          initialSelectedValues={[]}
          getOptionsFromRequest={getOptionsFromRequest}
          dropdownName="product"
        />

        <CheckboxesInput
          showArrow
          selectAll
          searchable
          allowClear
          defaultValue={
            filters?.productGroups?.length > 0
              ? filters?.productGroups?.length === productGroupsInitialLength
                ? "All product groups"
                : `${filters?.productGroups?.length} product groups selected`
              : "All product groups"
          }
          selectedValue={
            filters?.productGroups?.length === 0
              ? reset !== undefined
                ? filters?.productGroups
                : localOptions["product_group"]
              : filters?.productGroups
          }
          reset={reset}
          apply={apply}
          initialLength={productsInitialLength}
          onChange={onChange("productGroups")}
          placeholder="Select product groups"
          icon={<DatabaseSearchIcon />}
          apiRoute="data/filters_names"
          requestMethod="post"
          requestBody={prepareFilterParams({ ...filters }, {}, "product_group")}
          transformer={(res) => {
            return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
          }}
          isLoading={loading}
          options={localOptions["product_group"]}
          initialSelectedValues={[]}
          getOptionsFromRequest={getOptionsFromRequest}
          dropdownName="product group"
        />

        <DateRangePicker
          showArrow
          allowClear
          reset={reset}
          icon={<CalendarIcon />}
          placement="bottomRight"
          onChange={onChange("date")}
          defaultValue={{
            type: "week",
            val: [
              !isEmpty(filters.startDate)
                ? moment(filters.startDate, filtersDateFormat)
                : moment(globalFiltersInitialState.startDate, filtersDateFormat),
              !isEmpty(filters.endDate)
                ? moment(filters.endDate, filtersDateFormat)
                : moment(globalFiltersInitialState.endDate, filtersDateFormat)
            ]
          }}
          apiRoute="data/disable_dates"
          requestMethod="post"
          transformer={(res) => {
            return !isEmpty(res) ? JSON.parse(res).map((item) => ({ ...item })) : [];
          }}
          handleYoyChange={() => {
            const isYoyChecked = filters.yoy;
            if (!isYoyChecked) {
              if (
                intervalIsSmallerThanOneYear(
                  moment(filters.startDate, filtersDateFormat),
                  moment(filters.endDate, filtersDateFormat)
                )
              ) {
                setFilters({ ...filters, yoy: true });
              }
            } else {
              setFilters({ ...filters, yoy: false });
            }
          }}
          yoyValue={filters.yoy}
          yoyDisabledMessage={yoyDisabledMessage}
          yoyDisabled={
            isEmpty(filters.startDate) ||
            isEmpty(filters.endDate) ||
            !isDateRangeValid(filters.startDate, filters.endDate)
          }
        />
        <Button type="primary mr-15" onClick={onApply} disabled={onApplyDisabled}>
          Apply
        </Button>
        <Button type="text" onClick={onClear}>
          Clear All
        </Button>
      </footer>
    </div>
  );
};

export default GlobalFilters;
