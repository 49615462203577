/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import { openModalAction } from "../../components/Modal/reducer";
import Table from "../../components/Table";
import { SearchInput } from "../../components/Input";
import CheckboxesInput from "../../components/CheckboxesInput";
import { CREATE_NEW_BUSINESS_RULE } from "./config";
import CreateNewBusinessRuleModal from "./CreateBusinessRule/Modal";
import { costManagementColumns } from "./methods";

const ViewTable = () => {
  const initialFiltersValue = {
    costs: null
  };
  const dispatch = useDispatch();
  const [reloadTable, setReloadTable] = useState(null);
  const [noRecords, setNoRecords] = useState(true);
  const [dummyData, setDummyData] = useState([]);

  const history = useHistory();
  const [totalRows, setTotalRows] = useState(0);
  const [resetFilters, setResetFilters] = useState(undefined);
  const [filters, setFilters] = useState(initialFiltersValue);

  const onFilterChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: value
    });
  };

  useEffect(() => {
    getTableRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadCostManagementData = () => {
    setReloadTable(!reloadTable);
  };

  const onBuisnessRuleModalClose = () => {
    console.log("close");
  };

  const getTableRecords = () => {
    let records = [];

    if (records.length > 0) {
      setNoRecords(false);
    } else {
      setNoRecords(true);
    }
  };

  useEffect(() => {
    getTableRecords();
  }, [reloadTable]);

  const costTypes = [
    {
      text: "Technical Costs",
      value: "TECHNICAL"
    },
    {
      text: "Operational Costs",
      value: "OPERATIONAL"
    },
    {
      text: "Content Costs",
      value: "CONTENT"
    },
    {
      text: "Data Costs",
      value: "DATA"
    }
  ];

  const filterUsersTable = (filters = {}, data = []) => {
    const { costs, search } = filters;

    return data.filter((item) => {
      let isCost = true;
      let isSearch = true;

      if (!isEmpty(costs)) {
        isCost = costs.map((item) => item.id).indexOf(item.cost_type.toUpperCase()) > -1;
      }

      if (search) {
        isSearch = !!item.title && item.title.indexOf(search) > -1;
      }

      return isCost && isSearch;
    });
  };

  return (
    <div>
      <div className="flex flex-items-start mb-10">
        <div className="flex flex-items-center flex-1 flex-wrap">
          <div className="flex flex-items-center title-medium mr-20 mb-10 flex-nowrap">
            <div className="mr-5 nowrap">Cost Management</div>
          </div>
          <div className="mb-10 mr-a">
            <SearchInput className="pr-20" onChange={(e) => onFilterChange("search", e.target.value)} />
          </div>
          <div className="group-filters flex-wrap">
            <div className="mr-10 mb-10 ant-select">
              <CheckboxesInput
                reset={resetFilters}
                className="white-bg"
                showArrow
                searchable
                allowClear
                onChange={(val) => onFilterChange("costs", val)}
                placeholder="Cost Types"
                options={costTypes.map((item) => ({ id: item.value, name: item.text }))}
              />
            </div>
          </div>
        </div>

        <Button
          type="primary"
          style={{ padding: "5px 15px" }}
          className="ml-a"
          onClick={() => {
            dispatch(openModalAction({ name: CREATE_NEW_BUSINESS_RULE, data: { onClose: onBuisnessRuleModalClose } }));
          }}
        >
          Create Business Rule
        </Button>
      </div>
      <CreateNewBusinessRuleModal reloadCostManagementData={reloadCostManagementData} />
      <Table
        reload={reloadTable}
        className="rounded-box clickable-rows mb-40"
        columns={costManagementColumns(reloadCostManagementData, history)}
        apiRoute="/business_rules/"
        requestMethod="get"
        transformer={(res) => {
          let result = [];
          if (res && res.data) {
            result = res.data;
          }
          return result;
        }}
        onFilter={(data) => filterUsersTable(filters, data)}
      />
    </div>
  );
};

export default ViewTable;
