const routes = {
  LANDING_PAGE: "/",
  DASHBOARD_PAGE: "/dashboard",
  TARGETS_PAGE: "/targets",
  TARGETS_DETAILS: "/targets/:id",
  TARGETS_SINGLE_GRAPH: "/target-graph/:id",
  MY_REPORTS: "/my-reports",
  DATA_CENTER_MY_CHANNELS: "/data-center-my-channels",
  DATA_CENTER_EXPLORE: "/data-center-explore",
  ACCESS: "/access",
  SANDBOX: "/sandbox",
  NOT_FOUND_PAGE: "/404",
  LOGIN_PAGE: "/login",
  FORGOT_PASSWORD_PAGE: "/forgot-password",
  RESET_PASSWORD_PAGE: "/reset-password/:token",
  MY_ACCOUNT: "/my-account",
  // REGISTER_PAGE: "/register",
  NOTIFICATIONS: "/notifications",
  REQUEST_DEMO: "/request-demo",
  PLANNING_CENTER: "/planning-center",
  MODELS_CENTER: "/models-center",
  COST_MANAGEMENT: "/cost-management",
  COST_MANAGEMENT_DETAILS: "/cost-management/:id",
  PLANNING_CENTER_DETAILS: "/planning-center/:id",
  COMING_SOON: "/coming-soon"
};

export default routes;
