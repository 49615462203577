import { Input as AntdInput } from "antd";
import classNames from "classnames";
import React, { useState } from "react";

const Input = (props) => {
  return <AntdInput {...props} />;
};

const NumberInput = (props) => {
  const [inputValue, setInputValue] = useState("");

  const formatNumber = (value) => {
    const numericValue = value.replace(/[^\d.]/g, "");
    let [integerPart, decimalPart] = numericValue.split(".");

    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
  };

  const handleChange = (e) => {
    setInputValue(e.currentTarget.value); // Update the state directly with the input value
  };

  const handleBlur = () => {
    setInputValue(formatNumber(inputValue)); // Format the number when input is blurred
  };

  return (
    <AntdInput
      value={inputValue}
      {...props}
      onChange={handleChange}
      onBlur={handleBlur} // Apply formatting on blur
    />
  );
};
const PasswordInput = (props) => {
  if (props.visibilityToggle) {
    return <AntdInput.Password visibilityToggle={false} {...props} />;
  }
  return <AntdInput.Password visibilityToggle={true} {...props} />;
};

const SearchInput = (props) => {
  return (
    <AntdInput.Search
      {...props}
      addonAfter={false}
      className={classNames("search-field", props.className)}
      placeholder="Search"
      allowClear
    />
  );
};

export { Input, NumberInput, PasswordInput, SearchInput };
