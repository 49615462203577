import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { ReactComponent as BarChartIcon } from "../../../../../images/icons/bar-chart.svg";
import Button from "../../../../../components/Button";
import BarChart from "../../../../Dashboard/Charts/BarChart";
import LegendsCheckboxes from "../../../../Dashboard/Charts/LegendsCheckboxes";
import { prepareDataLegends } from "../../../../Dashboard/Charts/methods";

const SingleRuleChart = (props) => {
  const {
    className,
    dashboardData,
    title,
    data = [],
    loading,
    parserKeys = {},
    analysisTypes,
    chartName,
    hideCategoryDropdown
  } = props;

  const [chartActive, setChartActive] = useState("line");
  const [visibleDatasets, setVisibleDatasets] = useState(undefined);

  const onChangeCheckboxesLegends = useCallback((legendsValue) => {
    setVisibleDatasets(legendsValue?.checked || undefined);
  }, []);

  return (
    <div className={classNames("chart-section", className)}>
      <div className="flex flex-justify-between">
        <div className="title-medium mb-15">
          {title}
          &nbsp;
        </div>
        <div className="mb-15">
          <Button
            className={classNames("icon-link mr-5", { active: chartActive === "bar" })}
            onClick={() => setChartActive("bar")}
          >
            <BarChartIcon />
          </Button>
        </div>
      </div>
      <div className="rounded-box">
        <div className="rounded-box line-chart-with-vertical-legend">
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <BarChart
              dashboardFilters={dashboardData.filters}
              avgChart={false}
              parserKeys={parserKeys}
              data={data}
              loading={loading}
              visibleDatasets={visibleDatasets}
              chartName={chartName}
            />
          </div>

          <br />
          <div className="vertical-chart-legend-noline">
            <div className="vertical-chart-legend">
              <div className="disabled-inputs mb-10" style={{ justifyContent: "flex-start", width: "100%" }}>
                <p className="value" style={{ fontWeight: "bold" }}>
                  {" "}
                  Type : {data[0].target_type}
                </p>
              </div>
            </div>

            <LegendsCheckboxes
              dropdownName="value"
              chartName={chartName}
              legends={prepareDataLegends({ data, nameKey: parserKeys.nameKey })}
              onChange={onChangeCheckboxesLegends}
              loading={loading}
              hideCategoryDropdown={hideCategoryDropdown}
              propAnalysisTypes={analysisTypes}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dashboardData: state.dashboardData
});

export default connect(mapStateToProps)(SingleRuleChart);
