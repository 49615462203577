import React from "react";
import { Modal } from "antd";
import classNames from "classnames";
import UpdateRuleForm from "./Form";

const UpdateRuleModel = ({ data, visible, onCancel, reloadData }) => {
  return (
    <Modal
      width={850}
      name={"UPDATE_RULE"}
      wrapClassName={classNames("modal-wrapper", "rounded", 850)}
      visible={visible}
      header={null}
      footer={null}
      onCancel={onCancel}
    >
      <UpdateRuleForm data={data} reloadData={reloadData} onCancel={onCancel} />
    </Modal>
  );
};

export default UpdateRuleModel;
