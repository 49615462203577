import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { identity, pickBy } from "lodash";
import { useDispatch } from "react-redux";
import axios from "../../../axios";
import Button from "../../../components/Button";
import CheckboxesInput from "../../../components/CheckboxesInput";
import Form from "../../../components/Form";
import { Input } from "../../../components/Input";
import Select from "../../../components/Select";
import { ReactComponent as DatabaseSearchIcon } from "../../../images/icons/database-search.svg";
import {
  CREATE_NEW_BUSINESS_RULE,
  METRIC_LINEAR,
  METRIC_NOT_APPLICABLE,
  costUnit,
  costs,
  metrics,
  values
} from "../config";
import { getModalData } from "../../../utils/common";
import { ReactComponent as CalendarIcon } from "../../../images/icons/calendar.svg";
import DateRangePicker from "../../../components/DateRangePicker";
import { successNotification } from "../../../components/Notification";
import useFormSubmit from "../../../components/Form/useFormSubmit";
import { closeModalAction } from "../../../components/Modal/reducer";
import { prepareFilters } from "../../../components/GlobalFilters/methods";
import { prepareFilterParams } from "../methods";
import { filtersDateFormat, prepareInitialValues } from "./methods";

const BusinessRuleForm = ({ reloadPanningCenterData }) => {
  const globalFiltersState = useSelector((state) => state.globalFilters);

  const costManagementModalData = useSelector(getModalData(CREATE_NEW_BUSINESS_RULE));
  let initialValues = prepareInitialValues(costManagementModalData?.record);
  const [selectedMetric, setSelectedMetric] = useState(metrics[0]);
  const [selectedValue, setSelectedValue] = useState(values[0]);
  const [selectedCost, setSelectedCost] = useState(costs[0]);
  const [selectedCostUnit, setSelectedCostUnit] = useState(costUnit[0]);
  const [isLoading, setLoading] = useState(false);
  const [localOptions, setLocalOptions] = useState([]);
  const [isShown, setIsShown] = useState(selectedMetric === metrics[1]);

  const [selectedDate, setSelectedDate] = useState("01/01/2019 - 01/18/2024");

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(globalFiltersState);
  // eslint-disable-next-line unused-imports/no-unused-vars, no-unused-vars
  const [filtersReq, setFiltersReq] = useState({
    "date": selectedDate,
    "yoy": false
  });

  const transformer = (res) => {
    return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
  };
  const getOptionsFromRequest = async () => {
    try {
      setLoading(true);
      const res = await axios["post"]("data/filters_names", filtersReq);
      let transformedOptions = {
        channel: [],
        campaign: [],
        publisher: [],
        property: [],
        media_type: []
      };
      transformedOptions["channel"] = transformer(res.data?.data?.channel || res.data);
      transformedOptions["publisher"] = transformer(res.data?.data?.publisher || res.data);
      transformedOptions["media_type"] = transformer(res.data?.data?.media_type || res.data);

      setLocalOptions(transformedOptions);
      form.setFields([{ name: "channels", value: transformedOptions["channel"] }]);
      form.setFields([{ name: "publishers", value: transformedOptions["publisher"] }]);
      form.setFields([{ name: "mediaTypes", value: transformedOptions["media_type"] }]);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const filtersTransformer = (filters) => {
    const filteredArray = filters.map((filter) => filter.id);
    return filteredArray;
  };

  function transformDate(startDate, endDate) {
    const [startYear, startMonth, startDay] = startDate.split("-");
    const [endYear, endMonth, endDay] = endDate.split("-");

    const formattedStartDate = `${startMonth.padStart(2, "0")}/${startDay.padStart(2, "0")}/${startYear}`;
    const formattedEndDate = `${endMonth.padStart(2, "0")}/${endDay.padStart(2, "0")}/${endYear}`;

    return `${formattedStartDate} - ${formattedEndDate}`;
  }

  useEffect(() => {
    setIsShown((isShown) => !isShown);
  }, [selectedMetric]);
  useEffect(() => {
    getOptionsFromRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const initialFilters = prepareFilters({});
    setFilters(prepareFilters(initialFilters));
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback((type) => (value) => {
    let newFilters;
    if (type === "date") {
      const { type, val } = value || {};
      newFilters = {
        startDate:
          val?.length > 0
            ? String(type) !== "week"
              ? val[0].startOf(String(type)).format(filtersDateFormat)
              : moment(val[0]).format(filtersDateFormat)
            : undefined,
        endDate:
          val?.length > 0
            ? String(type) !== "week"
              ? val[1].endOf(String(type)).format(filtersDateFormat)
              : moment(val[1]).format(filtersDateFormat)
            : undefined
      };
      setSelectedDate(newFilters);
      setFiltersReq({ ...filtersReq, "date": transformDate(newFilters?.startDate, newFilters?.endDate) });
    }
    if (type === "metric") {
      setSelectedMetric({ text: value, value: value });
    }
    if (type === "value") {
      setSelectedValue({ text: value, value: value });
    }
    if (type === "cost") {
      setSelectedCost({ text: value, value: value });
    }
    if (type === "costUnit") {
      setSelectedCostUnit({ text: value, value: value });
    }
  });

  let { loading, generalError, submit } = useFormSubmit({
    form,
    route: "/business_rules/",
    method: "post",
    onSuccess: (res) => {
      successNotification({
        description: res.msg || "The business rule was created",
        iconStyle: "icon-color-green"
      });
      reloadPanningCenterData();
      costManagementModalData.onClose();
      dispatch(closeModalAction({ name: CREATE_NEW_BUSINESS_RULE }));
    },
    transformer: (dataForm) => {
      const selectedChannels = (dataForm.channels || []).map((channel) => channel.id);
      const selectedPublishers = (dataForm.publishers || []).map((publisher) => publisher.id);
      const selectedMediaTypes = (dataForm.mediaTypes || []).map((mediaType) => mediaType.id);
      // Conditionally setting split_values
      const splitValues = selectedMetric.value === METRIC_LINEAR ? METRIC_NOT_APPLICABLE : selectedValue.value;
      // TODO: merge comment
      // const selectedChannels = (dataForm.channels || []).map((channel) => channel.id);
      // const selectedPublishers = (dataForm.publishers || []).map((publisher) => publisher.id);
      // const selectedMediaTypes = (dataForm.mediaTypes || []).map((mediaType) => mediaType.id);
      const dataToSend = {
        "title": dataForm.title,
        "cost_type": selectedCost.value,
        "cost_value": dataForm.cost_value,
        "cost_unit": selectedCostUnit.value,
        "channels": selectedChannels,
        "publishers": selectedPublishers,
        "media_types": selectedMediaTypes,
        "split_metric": selectedMetric.value,
        "split_values": splitValues,
        "period_start": selectedDate.startDate || moment().subtract(5, "years").format(filtersDateFormat),
        "period_end": selectedDate.endDate || moment().format(filtersDateFormat)
      };

      return pickBy(dataToSend, identity);
    }
  });

  return (
    <Form onSubmit={submit} form={form} errors={generalError} loading={loading} requiredMark={false}>
      <div>
        <Form.Item
          label="Business Rule Name"
          name="title"
          validateTrigger={["onBlur", "onChange"]}
          rules={[
            {
              required: true,
              message: "Add a business rule name"
            }
          ]}
        >
          <Input placeholder="Rule Name" />
        </Form.Item>
      </div>
      <div className="group-two-columns">
        <Form.Item name="date">
          <DateRangePicker
            showArrow
            allowClear
            yoyDisabled
            icon={<CalendarIcon />}
            placement="bottomRight"
            placeholder="Select date"
            onChange={onChange("date")}
            defaultValue={{
              type: "week",
              val: [
                moment(initialValues.startDate, filtersDateFormat),
                moment(initialValues.endDate, filtersDateFormat)
              ]
            }}
            apiRoute="data/disable_dates"
            requestMethod="post"
            deleteYoyFunctionality
          />
        </Form.Item>
      </div>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 20 }} className="group-3-">
        <div style={{ flex: 1 }}>
          <Form.Item
            name="channels"
            rules={[
              {
                required: true,
                message: "Add the business rule's related channels"
              }
            ]}
          >
            <CheckboxesInput
              showArrow
              selectAll
              searchable
              allowClear
              selectedValue={form.getFieldValue("channels")}
              onChange={(newValue) => {
                setFiltersReq({ ...filtersReq, "channels": filtersTransformer(newValue) });
                form.setFields([{ name: "channels", value: newValue }]);
              }}
              placeholder="Channels"
              icon={<DatabaseSearchIcon />}
              apiRoute="data/filters_names"
              requestMethod="post"
              requestBody={prepareFilterParams({ ...filters }, {}, "channel")}
              transformer={(res) => {
                return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
              }}
              isLoading={isLoading}
              options={localOptions["channel"]}
              mmm={false}
              initialSelectedValues={localOptions["channel"]}
              getOptionsFromRequest={getOptionsFromRequest}
              dropdownName="channel"
            />
          </Form.Item>
        </div>
        <div style={{ flex: 1 }}>
          <Form.Item
            name="publishers"
            rules={[
              {
                required: true,
                message: "Add the business rule's related publishers"
              }
            ]}
          >
            <CheckboxesInput
              showArrow
              selectAll
              searchable
              allowClear
              selectedValue={form.getFieldValue("publishers")}
              onChange={(newValue) => {
                setFiltersReq({ ...filtersReq, "publishers": filtersTransformer(newValue) });
                form.setFields([{ name: "publishers", value: newValue }]);
              }}
              placeholder="Publishers"
              icon={<DatabaseSearchIcon />}
              apiRoute="data/filters_names"
              requestMethod="post"
              requestBody={prepareFilterParams({ ...filters }, {}, "publisher")}
              transformer={(res) => {
                return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
              }}
              isLoading={isLoading}
              options={localOptions["publisher"]}
              mmm={false}
              initialSelectedValues={localOptions["publisher"]}
              getOptionsFromRequest={getOptionsFromRequest}
              dropdownName="publisher"
            />
          </Form.Item>
        </div>
        <div style={{ flex: 1 }}>
          <Form.Item
            name="mediaTypes"
            rules={[
              {
                required: true,
                message: "Add the business rule's related media types"
              }
            ]}
          >
            <CheckboxesInput
              showArrow
              selectAll
              searchable
              allowClear
              selectedValue={form.getFieldValue("mediaTypes")}
              onChange={(newValue) => {
                setFiltersReq({ ...filtersReq, "mediaTypes": filtersTransformer(newValue) });
                form.setFields([{ name: "mediaTypes", value: newValue }]);
              }}
              placeholder="mediaTypes"
              icon={<DatabaseSearchIcon />}
              apiRoute="data/filters_names"
              requestMethod="post"
              requestBody={prepareFilterParams({ ...filters }, {}, "mediaType")}
              transformer={(res) => {
                return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
              }}
              isLoading={isLoading}
              options={localOptions["media_type"]}
              mmm={false}
              initialSelectedValues={localOptions["media_type"]}
              getOptionsFromRequest={getOptionsFromRequest}
              dropdownName="media type"
            />
          </Form.Item>
        </div>
      </div>
      <div className="group-three-columns">
        <div>
          <Form.Item label="Choose Cost Type" name="cost_type">
            <Select
              getPopupContainer={false}
              options={costs}
              onChange={onChange("cost")}
              defaultValue={costs[0]}
              placeholder="Cost Type"
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="cost_value"
            validateTrigger={["onBlur", "onChange"]}
            label=" "
            rules={[
              {
                required: true,
                message: "Add a cost value"
              }
            ]}
          >
            <Input
              placeholder="Cost in % or EUR"
              onKeyPress={(event) => {
                if (!/[0-9.-]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item label=" " name="cost_unit">
            <Select
              getPopupContainer={false}
              options={costUnit}
              onChange={onChange("costUnit")}
              defaultValue={costUnit[0]}
              placeholder="Cost Unit"
            />
          </Form.Item>
        </div>
      </div>
      <div className="group-two-columns mb-20">
        <div>
          <Form.Item label="Split by Metric" name="metric_type">
            <Select
              defaultValue={metrics[0]}
              onChange={onChange("metric")}
              getPopupContainer={false}
              options={metrics}
              placeholder="Set Metric"
            />
          </Form.Item>
        </div>
        {isShown && (
          <div>
            <Form.Item label="Split on Values" name="value_type">
              <Select
                defaultValue={values[0]}
                onChange={onChange("value")}
                getPopupContainer={false}
                options={values}
                placeholder="Set Value"
              />
            </Form.Item>
          </div>
        )}
      </div>

      <Form.Item shouldUpdate>
        {() => (
          <div className="form-actions flex flex-justify-end flex-wrap">
            <Button
              type="primary"
              htmlType="submit"
              disabled={isLoading || loading || form.getFieldsError().filter(({ errors }) => errors.length).length > 0}
              className="min-w-130"
            >
              Save
            </Button>
          </div>
        )}
      </Form.Item>
    </Form>
  );
};

export default BusinessRuleForm;
