import { notification } from "antd";
import classNames from "classnames";
import { ReactComponent as CheckmarkCircle } from "../../images/icons/checkmark-circle.svg";

export const successNotification = ({ message, description, placement, className, iconStyle }) => {
  notification.success({
    message,
    description: description || "Your file is being generated. Download will begin soon!",
    placement: placement || "bottom",
    className: classNames("min-w-450", className),
    icon: (
      <span className={classNames("ant-notification-notice-icon icon-color-blue mr-10", iconStyle)}>
        <CheckmarkCircle />
      </span>
    ),
    closeIcon: <></>
  });
};

export const errorNotification = ({ message, description, placement }) => {
  notification.error({
    message,
    description,
    placement: placement || "bottom"
  });
};
