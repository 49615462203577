import React from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { Redirect, Route } from "react-router-dom";
import routes from "../routes";

const UnauthenticatedRoute = ({ children, user, ...props }) => {
  if (!user || isEmpty(user)) {
    return <Route {...props}>{children}</Route>;
  }

  return <Redirect push to={routes.LANDING_PAGE} />;
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  };
};

export default connect(mapStateToProps)(UnauthenticatedRoute);
