import React, { useState } from "react";
import { Tooltip } from "antd";
import Button from "../../../../components/Button";
import { ReactComponent as BarChartIcon } from "../../../../images/icons/bar-chart.svg";
import SingleRuleChartModel from "./SingleRuleChart/SingleRuleChartModal.js";

const AnalysisActions = (props) => {
  const { data, reloadData } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="flex flex-items-center flex-nowrap">
        <Tooltip title="Rule chart">
          <Button className="icon-link" onClick={showModal}>
            <BarChartIcon />
          </Button>
        </Tooltip>
        <SingleRuleChartModel
          data={data}
          reloadPanningCenterData={reloadData}
          visible={isModalOpen}
          onCancel={handleCancel}
        />
      </div>
    </>
  );
};

export default AnalysisActions;
