/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import Button from "../../Button";
import NoData from "../../NoData";
import Preloader from "../../Preloader";
import axios from "../../../axios";
import { renderIcon, renderNotificationMessage, renderType } from "./methods";
import { prepareNotificationsData } from "./methods";
// import { notification } from "antd";

const Notifications = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const dataToRender = prepareNotificationsData(data);

  const getNotifications = () => {
    setLoading(true);
    axios.get("notifications/get_notifications").then(
      (res) => {
        setData(res?.data?.data || []);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };
  const updateNotifications = (data) => {
    setLoading(true);
    markRead(data);
    axios.post("notifications/update_notifications", { "notifications": data }).then(
      // eslint-disable-next-line unused-imports/no-unused-vars
      (res) => {
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const markRead = (data) => {
    data.forEach((element) => {
      element.expired = true;
    });
  };

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [data]);

  return (
    <div className="notification">
      {loading ? (
        <div className="flex flex-items-center flex-justify-center">
          <Preloader size={30} className="mt-50 pt-50" />
        </div>
      ) : (
        <>
          <div className="notification-header">
            <span className="notification-title">Notification</span>
            <Button className="icon-link" onClick={updateNotifications.bind(this, data)}>
              <span className="notification-button-text">Mark all as read</span>
            </Button>
          </div>
          <div className="content">
            {Object.keys(dataToRender).length === 0 && <NoData className="mt-20" />}
            {Object.keys(dataToRender).map((key) => (
              <div key={key}>
                <div className="moment">
                  <span className="moment-text">{key}</span>
                </div>
                {dataToRender[key].map((item) => {
                  return (
                    // eslint-disable-next-line react/jsx-no-comment-textnodes
                    <div className="notification-container" key={item.id}>
                      <div className="notification-content">
                        {renderIcon({ type: item.type, photo: item.picture, expired: item.expired })}
                        <div className="notification-text">
                          <span className="notification-content-type grey-text">
                            {renderType({ type: item.type, time: item.created_at })}
                          </span>
                          <span>
                            {renderNotificationMessage({
                              subject: item.subject,
                              category: item.category,
                              type: item.type
                            })}
                          </span>
                          {item.type === "API error" ? (
                            <span className="contact-text red-text">Contact support</span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Notifications;
