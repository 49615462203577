import { Layout } from "antd";

/**
 * Main Container
 * @param  {object} props: { children, className, style }
 * @description Container page component
 */
const MainContainer = ({ children, isLandingPage }) => (
  <Layout.Content style={{ padding: isLandingPage ? "0 0" : "0 16px" }} className="layout-content-inner">
    {children}
  </Layout.Content>
);

export default MainContainer;
