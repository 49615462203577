import React from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { Redirect, Route } from "react-router-dom";
import routes from "../routes";

const AuthenticatedRoute = ({ children, user, ...props }) => {
  if (isEmpty(user)) {
    return <Redirect push to={routes.LOGIN_PAGE} {...props} />;
  }

  return <Route {...props}>{children}</Route>;
};

const mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(AuthenticatedRoute);
