import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRequestSWR } from "../../../utils/custom-hooks";
import LineBarChartLegends from "../Charts/LineBarChartLegends";
import { prepareCPCCParams, prepareYOYResponse } from "../methods";
import { changeGlobalState } from "../../App/reducer";
import { analysisTypes, getChartNameKeyBasedOnAnalysisType } from "../Charts/methods";
import { incrementFetchingCounter, decrementFetchingCounter } from "../../../utils/reducer";
export const CHART_CPM_NAME = "chartCpm";
export const AVG_CHART = true;

const CostPerMilleChart = () => {
  const globalFiltersState = useSelector((state) => state.globalFilters);
  const [analysisType, setAnalysisType] = useState(analysisTypes[0].value);
  const dispatch = useDispatch();

  const cpmData = useRequestSWR({
    requestKeyName: "cpm_graph",
    apiRoute: "data/cpm_graph",
    method: "post",
    params: prepareCPCCParams(globalFiltersState, {
      analysis_type: analysisType
    }),
    onStart: () => {
      dispatch(incrementFetchingCounter());
    },
    onSuccess: () => {
      dispatch(decrementFetchingCounter());
    }
  });
  dispatch(changeGlobalState({ cpm: { data: cpmData?.data?.result, loading: cpmData?.loading } }));

  useEffect(() => {
    return () => {
      cpmData.source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parseNameKey = getChartNameKeyBasedOnAnalysisType(analysisType);

  return (
    <LineBarChartLegends
      dashboardFilters={globalFiltersState}
      chartName={CHART_CPM_NAME}
      avgChart={AVG_CHART}
      title="Cost per mille"
      data={cpmData.isError ? [] : prepareYOYResponse(cpmData.data, parseNameKey)}
      loading={cpmData.loading}
      className="mb-50"
      parserKeys={{
        nameKey: parseNameKey,
        valueKey: "cpm"
      }}
      onAnalysisTypeChange={(type) => {
        if (type !== analysisType) {
          setAnalysisType(type);
        }
      }}
      lineChartActive
    />
  );
};

export default CostPerMilleChart;
