const getApiBaseUrl = () =>
  process.env.NODE_ENV === "development" ? "http://localhost:5000/api/" : process.env.REACT_APP_API_BASE_URL;

const config = {
  apiBaseUrl: getApiBaseUrl(),
  sentry: {
    dsn: "https://811959d6d6de421b8bb6dc30aa3ebf77@sentry.digitalya.ro/85"
  },
  localStorageKeys: {
    token: "access_token_key"
  },
  dateFormat: {
    api: "YYYY-MM-DD HH:mm:ss",
    front: "YYYY-MM-DD HH:mm:ss",
    shortFormat: "YYYY-MM-DD",
    compressed: "YYYYMMDD"
  },
  generalError: "Internal error"
};

export default config;
