import { createAction, createReducer } from "@reduxjs/toolkit";
import moment from "moment";
import { filtersDateFormat } from "./methods";

const FILTERS_LOCALSTORAGE_KEY = "planning_center_filters";

export const saveFiltersToLocalStorage = (filters = {}) => {
  localStorage.setItem(FILTERS_LOCALSTORAGE_KEY, JSON.stringify(filters));
  return filters;
};

export const filtersInitialState = {
  channels: [],
  publishers: [],
  properties: [],
  startDate: moment().startOf("month").subtract(5, "years").format(filtersDateFormat),
  endDate: moment().format(filtersDateFormat),
  typeDate: "week",
  yoy: false
};

export const getInitialFilters = () => {
  const filtersFromLocalStorage = localStorage.getItem(FILTERS_LOCALSTORAGE_KEY);
  return filtersFromLocalStorage ? JSON.parse(filtersFromLocalStorage) : filtersInitialState;
};

//TODO: Change the API if needed for both changePlanningCenterFilters and resetPlanningCenterFilters
export const changePlanningCenterFilters = createAction("planningCenter/change-filters");

export const resetPlanningCenterFilters = createAction("planningCenter/reset-filters");

export default createReducer(getInitialFilters(), (builder) => {
  builder
    .addCase(changePlanningCenterFilters, (state, action) => {
      if (action.payload?.resetLocalStorage) {
        localStorage.removeItem(FILTERS_LOCALSTORAGE_KEY);
        return {
          ...state,
          ...action.payload
        };
      }

      return {
        ...state,
        ...saveFiltersToLocalStorage(action.payload)
      };
    })
    .addCase(resetPlanningCenterFilters, () => {
      return filtersInitialState;
    });
});
