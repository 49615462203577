import React from "react";
import { Text, View } from "@react-pdf/renderer";
import moment from "moment";
import config from "../../../config";
import { baseStyle, extendStyle } from "./methods";
import LogoPDF from "./LogoPDF";

const ReportHeaderPdf = ({ reportName }) => {
  const headerStyle = baseStyle({
    headerContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "flex-start",
      flexWrap: "nowrap",
      borderBottom: "1px solid #F5F6FA",
      paddingBottom: 20
    },
    header: {
      marginBottom: 20
    },
    logo: {
      display: "block",
      marginLeft: "auto"
    }
  });

  return (
    <View style={headerStyle.header} fixed>
      <View style={headerStyle.headerContainer}>
        <View style={extendStyle(headerStyle.table, { width: 200 })}>
          <View style={headerStyle.row}>
            <View style={extendStyle(headerStyle.column, { width: 120 })}>
              <Text>Report name:</Text>
            </View>
            <View style={headerStyle.column}>
              <Text style={headerStyle.bold}>{reportName}</Text>
            </View>
          </View>
          <View style={headerStyle.row}>
            <View style={extendStyle(headerStyle.column, { width: 120 })}>
              <Text>Report date:</Text>
            </View>
            <View style={headerStyle.column}>
              <Text style={headerStyle.bold}>{moment().format(config.dateFormat.shortFormat)}</Text>
            </View>
          </View>
        </View>
        <View>
          <LogoPDF style={headerStyle.logo} />
        </View>
      </View>
    </View>
  );
};

export default ReportHeaderPdf;
