import { Popconfirm } from "antd";
import React from "react";
import classNames from "classnames";

const PopupConfirm = (props) => {
  return (
    <Popconfirm
      title="Please confirm"
      {...props}
      overlayClassName={classNames("custom-popup-confirm", props.className)}
    />
  );
};

export default PopupConfirm;
