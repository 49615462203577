import React from "react";
import { Select as AntdSelect } from "antd";
import classNames from "classnames";
import { antdSelectFilterOption } from "./methods";

const Select = (props) => {
  const { options = [], className, ...rest } = props;

  return (
    <AntdSelect
      className={classNames("custom-antd-select", className)}
      dropdownMatchSelectWidth={false}
      style={{ width: "100%", textTransform: "capitalize" }}
      optionFilterProp="children"
      getPopupContainer={(el) => el}
      filterOption={antdSelectFilterOption}
      notFoundContent={<p className="pt-10 text-center">No data found</p>}
      dropdownClassName="custom-antd-select-dropdown"
      {...rest}
    >
      {options.map((option) => (
        <AntdSelect.Option
          key={option.value}
          value={option.value}
          disabled={option.disabled}
          style={{ textTransform: "capitalize" }}
        >
          {option.text}
        </AntdSelect.Option>
      ))}
    </AntdSelect>
  );
};

export default Select;
