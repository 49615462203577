import React from "react";

function Icon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon/fluent:arrow-reply-24-regular">
        <path
          id="icon"
          d="M9.27685 16.2215C9.41341 16.3631 9.48891 16.5526 9.48711 16.7492C9.48531 16.9458 9.40634 17.1339 9.26722 17.2729C9.1281 17.4119 8.93995 17.4907 8.7433 17.4923C8.54666 17.4939 8.35724 17.4182 8.21585 17.2815L3.21885 12.2785C3.0784 12.1379 2.99951 11.9473 2.99951 11.7485C2.99951 11.5498 3.0784 11.3592 3.21885 11.2185L8.21685 6.22055C8.35824 6.08386 8.54766 6.00818 8.7443 6.00979C8.94095 6.01141 9.1291 6.09019 9.26822 6.22919C9.40735 6.36818 9.48631 6.55625 9.48811 6.7529C9.48991 6.94954 9.4144 7.13903 9.27785 7.28055L5.55685 11.0005H13.3989C14.9939 11.0005 16.2089 11.2425 17.2879 11.7645L17.5339 11.8905C18.631 12.472 19.5284 13.3694 20.1099 14.4665C20.7199 15.6065 20.9999 16.8845 20.9999 18.6015C20.9999 18.8005 20.9208 18.9912 20.7802 19.1319C20.6395 19.2725 20.4488 19.3515 20.2499 19.3515C20.0509 19.3515 19.8602 19.2725 19.7195 19.1319C19.5789 18.9912 19.4999 18.8005 19.4999 18.6015C19.4999 17.1175 19.2719 16.0815 18.7869 15.1735C18.3453 14.3381 17.6623 13.6551 16.8269 13.2135C15.9889 12.7655 15.0409 12.5375 13.7329 12.5045L13.3999 12.5005H5.56185L9.27685 16.2215Z"
          fill="#002060"
        />
      </g>
    </svg>
  );
}

export default Icon;
