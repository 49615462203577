import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { connect, useDispatch } from "react-redux";
import { Switch } from "antd";
import Button from "../../../components/Button";
import { openModalAction } from "../../../components/Modal/reducer";
import { ReactComponent as LineChartIcon } from "../../../images/icons/line-chart.svg";
import { ReactComponent as BarChartIcon } from "../../../images/icons/bar-chart.svg";
import { ReactComponent as InfoIcon } from "../../../images/icons/question.svg";
import { ReactComponent as PieChartIcon } from "../../../images/icons/pie-chart.svg";
import { changeDashboardData } from "../reducer";
import { INFO_MODAL } from "../config";
import InfoModal from "../Modals/InfoModal";
import { CHART_COST_ANALYSIS_NAME } from "../CostAnalysis/CostAnalysisChart";
import { CHART_COST_TREND_ANALYSIS_NAME } from "../CostTrendAnalysis/CostTrendAnalysisChart";
import LineChart from "./LineChart";
import { costAnalysisTypes, prepareDataLegends } from "./methods";
import BarChart from "./BarChart";
import LegendsCheckboxes from "./LegendsCheckboxes";
import PieChart from "./PieChart";
import StackedBarChart from "./StackedBarChart";

const LineBarChartLegends = (props) => {
  const {
    className,
    title,
    avgChart,
    data = [],
    loading,
    onAnalysisTypeChange,
    legendBackgroundColor,
    parserKeys = {},
    analysisTypes,
    dropdownName,
    chartName,
    dashboardData,
    dashboardFilters,
    noLineChart,
    noPieChart,
    noBarChart,
    hideCategoryDropdown,
    typeOptions,
    typePlaceholder,
    hideTypeDropdown = true
  } = props;
  const dispatch = useDispatch();
  const [chartActive, setChartActive] = useState(!noLineChart ? "line" : "bar");
  const [activeDataset, setActiveDataset] = useState(undefined);
  const [visibleDatasets, setVisibleDatasets] = useState(undefined);
  const [visibleCostTypes, setVisibleCostTypes] = useState(undefined);
  const [ratio, setRatio] = useState(false);

  const onChangeCheckboxesLegends = useCallback(
    (legendsValue) => {
      setVisibleDatasets(legendsValue?.checked || undefined);
      onAnalysisTypeChange && onAnalysisTypeChange(legendsValue.type);
      if (chartName) {
        dispatch(
          changeDashboardData({
            ...dashboardData,
            [`${chartName}AnalyseType`]: legendsValue.type
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onAnalysisTypeChange, chartName, dashboardData]
  );
  const onChangeCostTypeLegends = useCallback(
    (legendsValue) => {
      setVisibleCostTypes(legendsValue.checked.map((item) => item.value) || undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartName, dashboardData]
  );

  const onHoverCheckboxesLegends = useCallback((legendsValue) => {
    setActiveDataset(legendsValue?.active?.name || undefined);
  }, []);

  const onChangeRatio = (checked) => {
    setRatio(checked);
  };

  return (
    <div className={classNames("chart-section", className)}>
      <div className="flex flex-justify-between">
        <div className="title-medium mb-15">
          {title}
          &nbsp;
          <Button
            className={classNames("icon-link mr-5")}
            onClick={() => dispatch(openModalAction({ name: INFO_MODAL, data: chartName }))}
          >
            <InfoIcon />
          </Button>
          <InfoModal />
        </div>
        <div className="mb-15">
          {noLineChart ? null : (
            <Button
              className={classNames("icon-link mr-5", { active: chartActive === "line" })}
              onClick={() => setChartActive("line")}
            >
              <LineChartIcon />
            </Button>
          )}
          {noBarChart ? null : (
            <Button
              className={classNames("icon-link mr-5", { active: chartActive === "bar" })}
              onClick={() => setChartActive("bar")}
            >
              <BarChartIcon />
            </Button>
          )}
          {noPieChart ? null : (
            <Button
              className={classNames("icon-link mr-5", { active: chartActive === "pie" })}
              onClick={() => setChartActive("pie")}
            >
              <PieChartIcon />
            </Button>
          )}
        </div>
      </div>
      <div className="rounded-box">
        <div className="rounded-box line-chart-with-vertical-legend">
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {chartName === CHART_COST_ANALYSIS_NAME && (
              <div>
                <div style={{ marginTop: "20px" }} className="title-light">
                  Show Ratio
                </div>
                <div style={{ marginTop: "20px", marginBottom: "40px" }}>
                  <Switch onChange={onChangeRatio} />
                </div>
              </div>
            )}

            {(() => {
              switch (chartActive) {
                case "line":
                  return chartName === CHART_COST_ANALYSIS_NAME ? null : (
                    <LineChart
                      dashboardFilters={dashboardFilters}
                      chartName={chartName}
                      parserKeys={parserKeys}
                      data={data}
                      loading={loading}
                      activeDataset={activeDataset}
                      visibleDatasets={
                        chartName === CHART_COST_TREND_ANALYSIS_NAME ? visibleCostTypes : visibleDatasets
                      }
                    />
                  );

                case "bar":
                  return chartName === CHART_COST_ANALYSIS_NAME ? (
                    <StackedBarChart
                      className={"bar-chart-container_cost"}
                      dashboardFilters={dashboardFilters}
                      avgChart={avgChart}
                      parserKeys={parserKeys}
                      data={data}
                      ratio={ratio}
                      loading={loading}
                      visibleDatasets={visibleDatasets}
                      visibleCostTypes={visibleCostTypes}
                      chartName={chartName}
                    />
                  ) : (
                    <BarChart
                      dashboardFilters={dashboardFilters}
                      avgChart={avgChart}
                      parserKeys={parserKeys}
                      data={data}
                      loading={loading}
                      visibleDatasets={visibleDatasets}
                      chartName={chartName}
                    />
                  );

                case "pie":
                  return (
                    <PieChart
                      dashboardFilters={dashboardFilters}
                      chartName={chartName}
                      parserKeys={parserKeys}
                      data={data}
                      loading={loading}
                      activeDataset={activeDataset}
                      visibleDatasets={visibleDatasets}
                    />
                  );

                // eslint-disable-next-line no-fallthrough
                default:
                  <p>"error"</p>;
              }
            })()}
          </div>
          {chartName === CHART_COST_TREND_ANALYSIS_NAME ? (
            <LegendsCheckboxes
              style={{ minHeight: "fit-content" }}
              dropdownName="cost"
              chartName={chartName}
              legends={costAnalysisTypes}
              legendBackgroundColor={legendBackgroundColor}
              onChange={onChangeCostTypeLegends}
              loading={loading}
              hideCategoryDropdown={true}
              propAnalysisTypes={costAnalysisTypes}
            />
          ) : (
            <div>
              {chartName === CHART_COST_ANALYSIS_NAME && (
                <LegendsCheckboxes
                  dropdownName="cost"
                  chartName={chartName}
                  legends={costAnalysisTypes}
                  legendBackgroundColor={legendBackgroundColor}
                  onChange={onChangeCostTypeLegends}
                  loading={loading}
                  hideCategoryDropdown={true}
                  propAnalysisTypes={costAnalysisTypes}
                />
              )}
              <br />
              <LegendsCheckboxes
                chartName={chartName}
                dropdownName={dropdownName}
                legends={prepareDataLegends({ data, nameKey: parserKeys.nameKey })}
                onHover={onHoverCheckboxesLegends}
                onChange={onChangeCheckboxesLegends}
                loading={loading}
                hideCategoryDropdown={hideCategoryDropdown}
                typePlaceholder={typePlaceholder}
                typeOptions={typeOptions}
                legendBackgroundColor={legendBackgroundColor}
                propAnalysisTypes={analysisTypes}
                hideTypeDropdown={hideTypeDropdown}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dashboardData: state.dashboardData
});

export default connect(mapStateToProps)(LineBarChartLegends);
