import moment from "moment";

export const filtersDateFormat = "MM/DD/YYYY";

export const prepareFilters = (filters = {}) => {
  const {
    cpccVisible = false,
    totalSpendingsVisible = true,
    reachVisible = true,
    cpmVisible = false,
    costVisible = false,
    costTrendVisible = false,
    channels = [],
    campaigns = [],
    publishers = [],
    properties = [],
    mediaTypes = [],
    mediaClusters = [],
    audiences = [],
    products = [],
    productGroups = [],
    startDate,
    endDate,
    typeDate,
    yoy = false
  } = filters;

  return {
    ...filters,
    cpccVisible,
    totalSpendingsVisible,
    reachVisible,
    cpmVisible,
    costVisible,
    costTrendVisible,
    channels,
    campaigns,
    publishers,
    properties,
    mediaTypes,
    mediaClusters,
    audiences,
    products,
    productGroups,
    startDate: startDate || moment().subtract(5, "years").format(filtersDateFormat),
    endDate: endDate || moment().format(filtersDateFormat),
    typeDate: typeDate || "week",
    yoy: yoy
  };
};

export const prepareFilterParams = (params = {}, additionalParams = {}, selectedFilter = "") => {
  return {
    channels:
      selectedFilter === "channel" || params?.channels?.length === 0 ? null : params?.channels?.map((item) => item.id),
    campaigns:
      selectedFilter === "campaign" || params?.campaigns?.length === 0
        ? null
        : params?.campaigns?.map((item) => item.id),
    publishers:
      selectedFilter === "publisher" || params?.publishers?.length === 0
        ? null
        : params?.publishers?.map((item) => item.id),
    // properties: selectedFilter === "property" ? [] : params?.properties?.map((item) => item.id).toString(),
    mediaTypes:
      selectedFilter === "mediaType" || params?.mediaTypes?.length === 0
        ? null
        : params?.mediaTypes?.map((item) => item.id),
    mediaClusters:
      selectedFilter === "mediaCluster" || params?.mediaClusters?.length === 0
        ? null
        : params?.mediaClusters?.map((item) => item.id),
    audiences:
      selectedFilter === "audience" || params?.audiences?.length === 0
        ? null
        : params?.audiences?.map((item) => item.id),
    products:
      selectedFilter === "product" || params?.products?.length === 0 ? null : params?.products?.map((item) => item.id),
    productGroups:
      selectedFilter === "productGroup" || params?.productGroups?.length === 0
        ? null
        : params?.productGroups?.map((item) => item.id),
    date: `${params.startDate} - ${params.endDate}`,
    yoy: params.yoy,
    ...additionalParams
  };
};
