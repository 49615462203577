import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {};

export const openModalAction = createAction("modal/open");
export const closeModalAction = createAction("modal/close");

export default createReducer(initialState, (builder) => {
  builder
    .addCase(openModalAction, (state, action) => {
      return {
        ...state,
        [action.payload.name]: {
          visible: true,
          data: action.payload.data
        }
      };
    })
    .addCase(closeModalAction, (state, action) => {
      return {
        ...state,
        [action.payload.name]: {
          visible: false
        }
      };
    });
});
