import React, { useEffect, useState } from "react";
import { Layout as AntdLayout } from "antd";
import { useHistory } from "react-router-dom";
import GlobalFilters from "../GlobalFilters";
import Sidebar from "./Sidebar";
import Header from "./Header";
import MainContainer from "./MainContainer";

/**
 * Layout
 * @function Layout
 * @description Layout component
 */
// eslint-disable-next-line unused-imports/no-unused-vars
const Layout = ({ children, layout }) => {
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 1300);
  const [currRoute, setCurrentRoute] = useState(history.location.pathname);

  const handleCollapse = (collapse) => {
    setCollapsed(collapse);
  };
  const [firstClicked, setFirstClicked] = useState(false);

  useEffect(() => {
    // Update the current route when the route changes
    const unlisten = history.listen((location) => {
      setCurrentRoute(location.pathname);
    });

    return () => {
      // Cleanup the listener when the component unmounts
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    const appContainerElement = document.getElementsByClassName("app-global-container")[0];

    if (appContainerElement) {
      appContainerElement.classList[collapsed ? "add" : "remove"]("navigation-collapsed");
    }
  }, [collapsed]);

  return (
    <>
      <Header handleCollapse={handleCollapse} collapsed={collapsed} setFirstClicked={setFirstClicked} />
      <AntdLayout style={{ minHeight: "calc(100vh - 80px)" }}>
        {currRoute !== "/" && (
          <Sidebar
            collapsed={collapsed}
            handleCollapse={handleCollapse}
            firstClicked={firstClicked}
            setFirstClicked={setFirstClicked}
          />
        )}
        <AntdLayout className="site-layout">
          <MainContainer isLandingPage={currRoute === "/"}>
            <GlobalFilters />
            {children}
          </MainContainer>
        </AntdLayout>
      </AntdLayout>
    </>
  );
};

export default Layout;
