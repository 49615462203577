import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { identity, pickBy } from "lodash";
import { useDispatch } from "react-redux";
import axios from "../../../../../axios";
import Button from "../../../../../components/Button";
import CheckboxesInput from "../../../../../components/CheckboxesInput";
import Form from "../../../../../components/Form";
import { Input } from "../../../../../components/Input";
import Select from "../../../../../components/Select";
import { CREATE_NEW_TARGET_RULE, targets } from "../../config";
import { getModalData } from "../../../../../utils/common";
import { ReactComponent as CalendarIcon } from "../../../../../images/icons/calendar.svg";
import DateRangePicker from "../../../../../components/DateRangePicker";
import { successNotification } from "../../../../../components/Notification";
import useFormSubmit from "../../../../../components/Form/useFormSubmit";
import { closeModalAction } from "../../../../../components/Modal/reducer";
import { prepareFilters } from "../StatisticsFilters/methods";
import { prepareFilterParams } from "../../methods";
import { filtersDateFormat } from "../CreateRule/methods";

const UpdateRuleForm = ({ data, reloadData, onCancel }) => {
  // eslint-disable-next-line unused-imports/no-unused-vars, no-unused-vars
  const globalFiltersState = useSelector((state) => state.globalFilters);

  const ModalData = useSelector(getModalData(CREATE_NEW_TARGET_RULE));
  const [selectedTarget, setSelectedTarget] = useState(targets[0]);
  const [isLoading, setLoading] = useState(false);
  const [localOptions, setLocalOptions] = useState([]);
  const formattedStartDate = new Date(data?.period_start)
    .toLocaleDateString("en-US", { day: "2-digit", month: "2-digit", year: "numeric" })
    .toString();
  const formattedEndDate = new Date(data?.period_end)
    .toLocaleDateString("en-US", { day: "2-digit", month: "2-digit", year: "numeric" })
    .toString();
  const [selectedDate, setSelectedDate] = useState(formattedStartDate + " - " + formattedEndDate);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(globalFiltersState);
  useEffect(() => {
    const initialSelectedChannels = data?.channels;
    const initialSelectedPublishers = data?.publishers;
    const initialSelectedCampaigns = data?.campaigns;
    const initialSelectedProperties = data?.properties;

    form.setFields([{ name: "channels", value: initialSelectedChannels }]);
    form.setFields([{ name: "publishers", value: initialSelectedPublishers }]);
    form.setFields([{ name: "campaigns", value: initialSelectedCampaigns }]);
    form.setFields([{ name: "properties", value: initialSelectedProperties }]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialSelectedChannels = data?.channels;
  const initialSelectedPublishers = data?.publishers;
  const initialSelectedCampaigns = data?.campaigns;
  const initialSelectedProperties = data?.properties;

  // eslint-disable-next-line unused-imports/no-unused-vars, no-unused-vars
  const [initialSelectedValues, setInitialSelectedValues] = useState({
    "channels": initialSelectedChannels?.length !== 0 ? initialSelectedChannels.map((channel) => channel?.id) : [],
    "publishers":
      initialSelectedPublishers?.length !== 0 ? initialSelectedPublishers.map((publisher) => publisher?.id) : [],
    "campaigns": initialSelectedCampaigns?.length !== 0 ? initialSelectedCampaigns.map((campaign) => campaign?.id) : [],
    "properties":
      initialSelectedProperties?.length !== 0 ? initialSelectedProperties.map((property) => property?.id) : []
  });

  const transformer = (res) => {
    return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
  };

  //Used to update filters request payload upon the change of the selected values
  const filtersTransformer = (filters) => {
    const filteredArray = filters.map((filter) => filter.id);
    return filteredArray;
  };

  function transformDate(startDate, endDate) {
    const [startYear, startMonth, startDay] = startDate.split("-");
    const [endYear, endMonth, endDay] = endDate.split("-");

    const formattedStartDate = `${startMonth.padStart(2, "0")}/${startDay.padStart(2, "0")}/${startYear}`;
    const formattedEndDate = `${endMonth.padStart(2, "0")}/${endDay.padStart(2, "0")}/${endYear}`;

    return `${formattedStartDate} - ${formattedEndDate}`;
  }

  // eslint-disable-next-line unused-imports/no-unused-vars, no-unused-vars
  const [filtersReq, setFiltersReq] = useState({
    "channels": data?.channels?.length !== 0 ? data.channels.map((channel) => channel.id) : [],
    "publishers": data?.publishers?.length !== 0 ? data.publishers.map((publisher) => publisher.id) : [],
    "properties": data?.properties?.length !== 0 ? data.properties.map((property) => property.id) : [],
    "campaigns": data?.campaigns?.length !== 0 ? data.campaigns.map((campaign) => campaign.id) : [],
    "mediaTypes": [],
    "date": selectedDate,
    "yoy": false
  });

  const getOptionsFromRequest = async () => {
    try {
      setLoading(true);
      const res = await axios["post"]("data/filters_names", filtersReq);
      let transformedOptions = {
        channel: [],
        campaign: [],
        publisher: [],
        property: [],
        media_type: []
      };
      transformedOptions["channel"] = transformer(res.data?.data?.channel || res.data);

      transformedOptions["campaign"] = transformer(res.data?.data?.campaign || res.data);

      transformedOptions["publisher"] = transformer(res.data?.data?.publisher || res.data);

      transformedOptions["property"] = transformer(res.data?.data?.property || res.data);

      transformedOptions["media_type"] = transformer(res.data?.data?.media_type || res.data);
      setLocalOptions(transformedOptions);
      form.setFields([{ name: "channels", value: transformedOptions["channel"] }]);
      form.setFields([{ name: "publishers", value: transformedOptions["publisher"] }]);
      form.setFields([{ name: "campaigns", value: transformedOptions["campaign"] }]);
      form.setFields([{ name: "property", value: transformedOptions["property"] }]);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getOptionsFromRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const initialFilters = prepareFilters({});
    setFilters(prepareFilters(initialFilters));
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback((type) => (value) => {
    let newFilters;
    if (type === "date") {
      const { type, val } = value || {};
      newFilters = {
        startDate:
          val?.length > 0
            ? String(type) !== "week"
              ? val[0].startOf(String(type)).format(filtersDateFormat)
              : moment(val[0]).format(filtersDateFormat)
            : undefined,
        endDate:
          val?.length > 0
            ? String(type) !== "week"
              ? val[1].endOf(String(type)).format(filtersDateFormat)
              : moment(val[1]).format(filtersDateFormat)
            : undefined
      };
      setSelectedDate(newFilters);
      setFiltersReq({ ...filtersReq, "date": transformDate(newFilters?.startDate, newFilters?.endDate) });
    }
    if (type === "target_type") {
      setSelectedTarget({ text: value, value: value });
    }
  });

  let { loading, generalError, submit } = useFormSubmit({
    form,
    route: "/targets/",
    method: "patch",
    onSuccess: (res) => {
      successNotification({
        description: res.msg || "The target rule was updated",
        iconStyle: "icon-color-green"
      });
      reloadData();
      ModalData?.onClose();
      onCancel();
      dispatch(closeModalAction({ name: CREATE_NEW_TARGET_RULE }));
    },
    transformer: (dataForm) => {
      const selectedChannels = (dataForm.channels || []).map((channel) => channel.id);
      const selectedPublishers = (dataForm.publishers || []).map((publisher) => publisher.id);
      const selectedProperties = (dataForm.properties || []).map((property) => property.id);
      const selectedCampaigns = (dataForm.campaigns || []).map((campaign) => campaign.id);
      const dataToSend = {
        "id": data.id,
        "title": dataForm.title,
        "target_type": selectedTarget.value,
        "target_value": dataForm.target_value,
        "channels": selectedChannels,
        "publishers": selectedPublishers,
        "properties": selectedProperties,
        "campaigns": selectedCampaigns,
        "period_start": selectedDate.startDate || moment().subtract(5, "years").format(filtersDateFormat),
        "period_end": selectedDate.endDate || moment().format(filtersDateFormat)
      };

      return pickBy(dataToSend, identity);
    }
  });

  return (
    <Form onSubmit={submit} form={form} errors={generalError} loading={loading} requiredMark={false}>
      <div>
        <Form.Item
          label="Target Rule Name"
          name="title"
          validateTrigger={["onBlur", "onChange"]}
          rules={[
            {
              required: true,
              message: "Add a target rule name"
            }
          ]}
          initialValue={data.title}
        >
          <Input placeholder="Rule Name" />
        </Form.Item>
      </div>
      <div className="group-two-columns">
        <Form.Item name="date">
          <DateRangePicker
            showArrow
            allowClear
            yoyDisabled
            icon={<CalendarIcon />}
            placement="bottomRight"
            placeholder="Select date"
            onChange={onChange("date")}
            defaultValue={{
              type: "week",
              val: [moment(data.period_start, filtersDateFormat), moment(data.period_end, filtersDateFormat)]
            }}
            apiRoute="data/disable_dates"
            requestMethod="post"
            deleteYoyFunctionality
          />
        </Form.Item>
      </div>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 20 }} className="group-4-">
        <div style={{ flex: 1 }}>
          <Form.Item name="channels">
            <CheckboxesInput
              showArrow
              selectAll
              searchable
              allowClear
              selectedValue={form.getFieldValue("channels") || initialSelectedValues?.channels}
              apiRoute="data/filters_names"
              requestMethod="post"
              requestBody={prepareFilterParams({ ...filters }, {}, "channel")}
              onChange={(newValue) => {
                setFiltersReq({ ...filtersReq, "channels": filtersTransformer(newValue) });
                form.setFields([{ name: "channels", value: newValue }]);
              }}
              placeholder="Channels"
              transformer={(res) => {
                return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
              }}
              isLoading={isLoading}
              options={localOptions["channel"]}
              mmm={false}
              initialSelectedValues={initialSelectedValues?.channels}
              getOptionsFromRequest={getOptionsFromRequest}
              dropdownName="channel"
            />
          </Form.Item>
        </div>
        <div style={{ flex: 1 }}>
          <Form.Item name="campaigns">
            <CheckboxesInput
              showArrow
              selectAll
              searchable
              allowClear
              selectedValue={form.getFieldValue("campaigns") || initialSelectedValues?.campaigns}
              onChange={(newValue) => {
                setFiltersReq({ ...filtersReq, "campaigns": filtersTransformer(newValue) });
                setInitialSelectedValues({ ...initialSelectedValues, "campaigns": filtersTransformer(newValue) });
                form.setFields([{ name: "campaigns", value: newValue }]);
              }}
              placeholder="Campaigns"
              apiRoute="data/filters_names"
              requestMethod="post"
              requestBody={prepareFilterParams({ ...filters }, {}, "campaign")}
              transformer={(res) => {
                return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
              }}
              isLoading={isLoading}
              options={localOptions["campaign"]}
              initialSelectedValues={initialSelectedValues?.campaigns}
              getOptionsFromRequest={getOptionsFromRequest}
              dropdownName="campaign"
            />
          </Form.Item>
        </div>
        <div style={{ flex: 1 }}>
          <Form.Item name="publishers">
            <CheckboxesInput
              showArrow
              selectAll
              searchable
              allowClear
              selectedValue={form.getFieldValue("publishers") || initialSelectedValues?.publishers}
              onChange={(newValue) => {
                setFiltersReq({ ...filtersReq, "publishers": filtersTransformer(newValue) });
                form.setFields([{ name: "publishers", value: newValue }]);
              }}
              apiRoute="data/filters_names"
              requestMethod="post"
              placeholder="Publishers"
              requestBody={prepareFilterParams({ ...filters }, {}, "publisher")}
              transformer={(res) => {
                return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
              }}
              isLoading={isLoading}
              options={localOptions["publisher"]}
              initialSelectedValues={initialSelectedValues?.publishers}
              getOptionsFromRequest={getOptionsFromRequest}
              dropdownName="publisher"
            />
          </Form.Item>
        </div>
        <div style={{ flex: 1 }}>
          <Form.Item name="properties">
            <CheckboxesInput
              showArrow
              selectAll
              searchable
              allowClear
              selectedValue={form.getFieldValue("properties") || initialSelectedValues?.properties}
              onChange={(newValue) => {
                setFiltersReq({ ...filtersReq, "properties": filtersTransformer(newValue) });
                form.setFields([{ name: "properties", value: newValue }]);
              }}
              apiRoute="data/filters_names"
              requestMethod="post"
              placeholder="Properties"
              requestBody={prepareFilterParams({ ...filters }, {}, "property")}
              transformer={(res) => {
                return !isEmpty(res) ? res.map((item) => ({ ...item, id: item.id, name: item.name })) : [];
              }}
              isLoading={isLoading}
              options={localOptions["property"]}
              initialSelectedValues={initialSelectedValues?.properties}
              getOptionsFromRequest={getOptionsFromRequest}
              dropdownName="property"
            />
          </Form.Item>
        </div>
      </div>
      <div className="group-two-columns">
        <div>
          <Form.Item label="Choose Target Type" name="target_type">
            <Select
              getPopupContainer={false}
              options={targets}
              onChange={onChange("target_type")}
              defaultValue={data.target_type}
              placeholder="Target Type"
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="target_value"
            validateTrigger={["onBlur", "onChange"]}
            label=" "
            rules={[
              {
                required: true,
                message: "Add a target value"
              }
            ]}
            initialValue={data.target_value}
          >
            <Input
              placeholder="Target in EUR"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
        </div>
      </div>
      <Form.Item shouldUpdate>
        {() => (
          <div className="form-actions flex flex-justify-end flex-wrap">
            <Button
              type="primary"
              htmlType="submit"
              disabled={isLoading || loading || form.getFieldsError().filter(({ errors }) => errors.length).length > 0}
              className="min-w-130"
            >
              Update
            </Button>
          </div>
        )}
      </Form.Item>
    </Form>
  );
};

export default UpdateRuleForm;
