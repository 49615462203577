/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Tooltip } from "antd";
import Button from "../../../../components/Button";
import { ReactComponent as DeleteIcon } from "../../../../images/icons/delete.svg";
import { ReactComponent as UpdateIcon } from "../../../../images/icons/update.svg";
import PopupConfirm from "../../../../components/PopupConfirm";
import { useLoggedUser } from "../../../../utils/custom-hooks";
import axios from "../../../../axios";
import { ACCESS_TYPE_READ } from "../../../UsersAccess/config";
import config from "../../../../config";
import { errorNotification, successNotification } from "../../../../components/Notification";
import UpdateRuleModel from "./UpdateRule/UpdateModal";
// import UpdateBusinessRuleModel from "./UpdateBusinessRule/UpdateModal";

const Actions = (props) => {
  // eslint-disable-next-line no-unused-vars, unused-imports/no-unused-vars
  const dispatch = useDispatch();
  const { data, reloadData } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const loggedUser = useLoggedUser();

  const onConfirmDelete = () => {
    // const reqData = {
    //   br_id: data.br_id // Replace with the actual br_id you want to delete
    // };
    axios.delete(`/targets/${data.id}`).then(
      (res) => {
        successNotification({
          description: res.data.msg || "The target rule was deleted",
          iconStyle: "icon-color-green"
        });
        reloadData();
      },
      (error) => {
        errorNotification({
          description: error.response.data.msg || config.generalError
        });
      }
    );
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="flex flex-items-center flex-nowrap">
        <Tooltip title="Update rule">
          <Button className="icon-link" onClick={showModal}>
            <UpdateIcon />
          </Button>
        </Tooltip>
        <UpdateRuleModel data={data} reloadData={reloadData} visible={isModalOpen} onCancel={handleCancel} />

        {loggedUser.access_role.type === ACCESS_TYPE_READ ? null : (
          <PopupConfirm onConfirm={onConfirmDelete}>
            <Tooltip title="Delete rule">
              <Button className="icon-link">
                <DeleteIcon />
              </Button>
            </Tooltip>
          </PopupConfirm>
        )}
      </div>
    </>
  );
};

export default Actions;
