import React from "react";
import { Image, Text, View } from "@react-pdf/renderer";
import { baseStyle, extendStyle } from "./methods";

const ReportChartSection = ({ data, name }) => {
  const styles = baseStyle();
  if (!data) {
    return null;
  }

  return (
    <View style={extendStyle({}, { marginBottom: 35 })} wrap={false}>
      <Text style={extendStyle(styles.bold, { marginBottom: 5, fontSize: 12 })}>{name}</Text>
      <View style={styles.table}>
        <View style={styles.row}>
          <Text style={extendStyle(styles.row, { width: 300, textTransform: "capitalize" })}>
            Analysis Type: {data.analysis_type.replace("_", " ")}
          </Text>
        </View>
      </View>
      {!data.image2 && (
        <Image src={data.image} style={extendStyle({}, { maxWidth: "100%", height: "auto", marginTop: "10px" })} />
      )}
      {data.image2 && (
        <View
          style={extendStyle(
            {},
            {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              marginTop: 10,
              flexWrap: "wrap",
              marginBottom: 50,
              minHeight: 300
            }
          )}
        >
          <View
            style={extendStyle(
              {},
              {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "50%"
              }
            )}
          >
            <Text style={[styles.bold, { marginBottom: 10 }]}>Previous year</Text>
            <Image src={data.image} style={extendStyle({}, { maxWidth: "100%", height: "auto" })} />
          </View>
          <View
            style={extendStyle(
              {},
              {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "50%"
              }
            )}
          >
            <Text style={[styles.bold, { marginBottom: 10 }]}>Current year</Text>
            <Image src={data.image2} style={extendStyle({}, { maxWidth: "100%", height: "auto" })} />
          </View>
        </View>
      )}
    </View>
  );
};

export default ReportChartSection;
