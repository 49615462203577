import React, { useRef } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import classNames from "classnames";
import { connect } from "react-redux";
import Preloader from "../../../components/Preloader";
import NoData from "../../../components/NoData";
import { barChartOptions, formatBarChartData } from "./methods";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = (props) => {
  const { data = [], visibleDatasets, avgChart, loading, parserKeys = {}, chartName, dashboardFilters } = props;
  const options = barChartOptions(dashboardFilters?.yoy);
  const barChartRef = useRef(null);
  const noDataToShow = !loading && (!data || data.length === 0);

  return (
    <div className={classNames("bar-chart-container", { loading, "no-data": noDataToShow })}>
      {loading && <Preloader size={30} />}
      {!loading && (!data || data.length === 0) && (
        <div className="no-chart-data">
          <NoData />
        </div>
      )}
      <Bar
        id={chartName}
        options={options}
        // plugins={[barPosition]}
        data={formatBarChartData({
          data,
          visible: visibleDatasets,
          multipleLabels: parserKeys.multipleLabels ? parserKeys.multipleLabels : [],
          avgChart: avgChart,
          nameKey: parserKeys.nameKey,
          valueKey: parserKeys.valueKey,
          multipleValueKeys: parserKeys.multipleValueKeys ? parserKeys.multipleValueKeys : [],
          isYOYActive: dashboardFilters?.yoy
        })}
        ref={barChartRef}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  dashboardData: state.dashboardData
});

export default connect(mapStateToProps)(BarChart);
