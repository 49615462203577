import React from "react";
import { Document, Page, View } from "@react-pdf/renderer";
import moment from "moment";
import config from "../../../config";
import { baseStyle, extendStyle } from "./methods";
import ReportHeaderPdf from "./ReportHeaderPdf";
import ReportFooterPdf from "./ReportFooterPdf";
import ReportFiltersPdf from "./ReportFiltersPdf";
import ReportChartSection from "./ReportChartSection";
import ReportTotalSpendingPdf from "./ReportTotalSpendingPdf";
import ReportFunnelSection from "./ReportFunnelSection";

const ReportPDF = ({ data = {}, type }) => {
  const styles = baseStyle();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <ReportHeaderPdf reportName={data.reportName || `Report ${moment().format(config.dateFormat.shortFormat)}`} />
        {/* PDF Report Content */}
        <View style={extendStyle({}, { marginBottom: 35 })}>
          <ReportFiltersPdf data={data.filters.channel} name="Channels:" />
          <ReportFiltersPdf data={data.filters.campaign} name="Campaigns:" />
          <ReportFiltersPdf data={data.filters.publisher} name="Publishers:" />
          <ReportFiltersPdf data={data.filters.property} name="Properties:" />
          <ReportFiltersPdf data={data.filters.mediaTypes} name="Media Types:" />
          <ReportFiltersPdf data={data.filters.mediaClusters} name="Media Clusters:" />
          <ReportFiltersPdf data={[{ label: data.filters.date }]} name="Date:" />
          {type === "DASHBOARD_PAGE" ? (
            <>
              <ReportChartSection data={data.cpcc} filters={data.filters} name="Cost per customer contact" />
              <ReportChartSection data={data.reach} filters={data.filters} name="Reach" />
              <ReportChartSection data={data.cpm} filters={data.filters} name="Cost per mille" />
              <ReportChartSection data={data.cpcd} filters={data.filters} name="Cost per channel detail" />
              <ReportChartSection data={data.costTrend} filters={data.filters} name="Cost Trend" />
              <ReportTotalSpendingPdf data={data.totalSpending} />
            </>
          ) : type === "SANDBOX" ? (
            <ReportFunnelSection data={data.funnel} name="Funnel Analysis" />
          ) : null}
        </View>
        {/* End PDF Report Content */}
        <ReportFooterPdf />
      </Page>
    </Document>
  );
};

export default ReportPDF;
