import { createAction, createReducer } from "@reduxjs/toolkit";

export const changeDashboardData = createAction("dashboard/change-data");

export default createReducer({}, (builder) => {
  builder.addCase(changeDashboardData, (state, action) => {
    return {
      ...state,
      ...action.payload
    };
  });
});
