import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRequestSWR } from "../../../utils/custom-hooks";
// import LineBarChartLegends from "../Charts/LineBarChartLegends";
import { prepareCPCCParams, prepareYOYResponse } from "../methods";
import { changeGlobalState } from "../../App/reducer";
import { analysisTypes3, getChartNameKeyBasedOnAnalysisType } from "../Charts/methods";
import { incrementFetchingCounter, decrementFetchingCounter } from "../../../utils/reducer";
import LineBarChartLegends from "../Charts/LineBarChartLegends";
import { getStorageColorData } from "../../../components/_auth/methods";
export const CHART_COST_TREND_ANALYSIS_NAME = "chartCostTrendAnalysis";
export const AVG_CHART = true;

const CostTrendAnalysisChart = () => {
  const globalFiltersState = useSelector((state) => state.globalFilters);
  const [analysisType, setAnalysisType] = useState(analysisTypes3[0].value);

  const dispatch = useDispatch();

  const costAnalysisData = useRequestSWR({
    requestKeyName: "calculate_cost_trends",
    apiRoute: "data/calculate_cost_trends",
    // requestKeyName: "calculate_costs",
    // apiRoute: "dashboard/calculate_costs",
    method: "post",
    params: prepareCPCCParams(globalFiltersState, {
      analysis_type: analysisType,
      colors: JSON.parse(getStorageColorData())
    }),
    onStart: () => {
      dispatch(incrementFetchingCounter());
    },
    onSuccess: () => {
      dispatch(decrementFetchingCounter());
    }
  });
  dispatch(
    changeGlobalState({ costTrend: { data: costAnalysisData?.data?.result, loading: costAnalysisData?.loading } })
  );

  useEffect(() => {
    return () => {
      costAnalysisData.source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parseNameKey = getChartNameKeyBasedOnAnalysisType(analysisType);

  return (
    <LineBarChartLegends
      dashboardFilters={globalFiltersState}
      chartName={CHART_COST_TREND_ANALYSIS_NAME}
      analysisType={analysisTypes3}
      avgChart={false}
      title="Cost Trends"
      data={costAnalysisData.isError ? [] : prepareYOYResponse(costAnalysisData.data, parseNameKey)}
      loading={costAnalysisData.loading}
      className="mb-50"
      parserKeys={{
        nameKey: parseNameKey,
        valueKey: "cost",
        additionalKey: "cost_type"
      }}
      onAnalysisTypeChange={(type) => {
        if (type !== analysisType) {
          setAnalysisType(type);
        }
      }}
      noBarChart
      noPieChart
    />
  );
};

export default CostTrendAnalysisChart;
