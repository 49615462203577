import { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "../../axios";
import { globalFiltersChangeAction, globalFiltersInitialState } from "../GlobalFilters/reducer";
import { openLogoutModal, closeLogoutModal } from "../_layout/utils/reducer";
import { resetFetchingCounter } from "../../utils/reducer";
import { getStorageAccessToken, deleteStorageAccessToken } from "./methods";
import { userUnauthenticatedAction } from "./reducer";

const useLogout = () => {
  const dispatch = useDispatch();
  const [localState, setLocalState] = useState({
    loading: false
  });

  const submit = () => {
    const accessToken = getStorageAccessToken();

    if (accessToken) {
      setLocalState({ ...localState, loading: true });
      dispatch(openLogoutModal());
      axios.post("auth/logout").then(
        () => {
          dispatch(globalFiltersChangeAction({ ...globalFiltersInitialState, resetLocalStorage: true }));
          deleteStorageAccessToken();
          localStorage.removeItem("campaignsInitialLength");
          localStorage.removeItem("publishersInitialLength");
          localStorage.removeItem("propertiesInitialLength");
          localStorage.removeItem("mediaTypesInitialLength");
          localStorage.removeItem("channelsInitialLength");
          localStorage.removeItem("sidebar_data");

          setLocalState({ ...localState, loading: false });
          dispatch(userUnauthenticatedAction());
          dispatch(closeLogoutModal());
          dispatch(resetFetchingCounter());
        },
        () => {
          dispatch(globalFiltersChangeAction({ ...globalFiltersInitialState, resetLocalStorage: true }));
          deleteStorageAccessToken();
          localStorage.removeItem("campaignsInitialLength");
          localStorage.removeItem("publishersInitialLength");
          localStorage.removeItem("propertiesInitialLength");
          localStorage.removeItem("mediaTypesInitialLength");
          localStorage.removeItem("channelsInitialLength");
          localStorage.removeItem("sidebar_data");

          setLocalState({ ...localState, loading: false });
          dispatch(userUnauthenticatedAction());
          dispatch(closeLogoutModal());
          dispatch(resetFetchingCounter());
        }
      );
    } else {
      dispatch(userUnauthenticatedAction());
      dispatch(resetFetchingCounter());
    }
  };

  return {
    loading: localState.loading,
    submit
  };
};

export default useLogout;
