/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState, useRef } from "react";
import { Dropdown, Input } from "antd";
import classNames from "classnames";
import { ReactComponent as ChevronDownIcon } from "../../images/icons/chevron-down.svg";
import { ReactComponent as DismissIcon } from "../../images/icons/dismiss.svg";
import { usePrevious } from "../../utils/custom-hooks";
import CheckboxesDropdown from "./CheckboxesDropdown";

const CheckboxesInput = (props) => {
  const {
    icon,
    placeholder = "Select items",
    showArrow,
    selectAll,
    searchable,
    options,
    allowClear,
    apply,
    reset,
    onChange,
    defaultValue,
    selectedValue,
    initialSelectedValues,
    initialLength,
    apiRoute,
    getOptionsFromRequest,
    dropdownName,
    isLoading,
    update,
    mmm,
    doNotCheckedAll,
    disabled = false,
    error,
    ...rest
  } = props;
  const [visible, setVisible] = useState(false);
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [checkAll, setCheckAll] = useState(true);
  const [loading, setLoading] = useState(isLoading);
  const [localOptions, setLocalOptions] = useState(options || []);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [changed, setChanged] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(initialSelectedValues || selectedValue || []);
  const prevVisibleRef = useRef();
  const [clearValues, setClearValues] = useState(false);
  const [clear, setClear] = useState(false);
  const [clearMetric, setClearMetric] = useState(false);
  const prevReset = usePrevious(reset);
  const prevApply = usePrevious(apply);

  useEffect(() => {
    prevVisibleRef.current = visible;
  }, [visible]);

  const prevVisible = prevVisibleRef.current;

  useEffect(() => {
    if (prevVisible && !visible && changed && mmm !== true) {
      // Your logic here that should run when visible transitions from true to false
      setLoading(true);
      if (getOptionsFromRequest) {
        getOptionsFromRequest();
      }
      setLoading(false);
      setChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, prevVisible]);

  useEffect(() => {
    if (dropdownName === "metric" || update === true) {
      setSelectedOptions(initialSelectedValues);
    }
  }, [dropdownName, initialSelectedValues, update]);

  useEffect(() => {
    if (!isLoading) {
      // Filter out values from selectedOptions that do not exist in localOptions
      const filteredSelectedOptions = selectedOptions.filter((option) =>
        localOptions.some((localOption) => localOption.id === option.id)
      );
      setSelectedOptions(filteredSelectedOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, localOptions]);

  const openDropdown = useCallback(() => {
    setVisible(true);
    setFilteredOptions([]);
    setIsFiltered(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const handleCheckAllChange = (checkAllSelection) => {
    doNotCheckedAll && setCheckAll(checkAllSelection);
  };
  const onSearchOptions = useCallback(
    (e) => {
      const filteredOptions = localOptions.filter((item) => {
        if (item.name && e.target.value) {
          return item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;
        }
        return true;
      });
      setFilteredOptions(filteredOptions);
      setIsFiltered(true);
    },
    [localOptions]
  );

  const onChangeValue = useCallback(
    (values) => {
      if (values.length !== selectedOptions.length) {
        setSelectedOptions(values);
        onChange(values);
        setChanged(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange]
  );

  useEffect(() => {
    if (selectedValue) {
      setSelectedOptions(selectedValue);
    }
  }, [selectedValue]);

  const onClear = useCallback(
    (e) => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }
      setClear(true);
      setSelectedOptions([]);
      if (dropdownName !== "metric") {
        setLocalOptions([]);
        setSelectedOptions([]);
        onChange([]);
      } else {
        setSelectedOptions(initialSelectedValues);
        onChange(initialSelectedValues);
      }
      setClearValues(!clearValues);
      setFilteredOptions([]);
      setCheckAll(false);
      setIsFiltered(false);
      setClearMetric(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearValues, onChange]
  );
  const onClearButton = useCallback(
    (e) => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }
      setSelectedOptions([]);
      onChange([]);
      setClearValues(!clearValues);
      setFilteredOptions([]);
      setCheckAll(false);
      setIsFiltered(false);
      setClearMetric(true);
    },
    [clearValues, onChange]
  );

  useEffect(() => {
    if (reset !== undefined && reset !== prevReset) {
      onClear();
      setClear(true);
    }
  }, [reset, prevReset, onClear]);

  useEffect(() => {
    if (options) {
      setLocalOptions(options);
    }
  }, [options]);

  const dropdownNamePlural = () => {
    if (initialLength === selectedOptions.length && selectedOptions.length !== 0) {
      switch (dropdownName) {
        case "metric": {
          return "All visuals";
        }
        case "channel": {
          return "All channels";
        }
        case "campaign": {
          return "All campaigns";
        }
        case "publisher": {
          return "All publishers";
        }
        case "property": {
          return "All properties";
        }
        case "media type": {
          return "All media types";
        }
        case "media_cluster": {
          return "All media clusters";
        }
        case "cost": {
          return "All cost types";
        }
        default: {
          return "All items";
        }
      }
    } else if (selectedOptions.length !== 1 || selectedOptions.length === 0) {
      switch (dropdownName) {
        case "metric": {
          return "visuals";
        }
        case "channel": {
          return "channels";
        }
        case "campaign": {
          return "campaigns";
        }
        case "publisher": {
          return "publishers";
        }
        case "property": {
          return "properties";
        }
        case "media type": {
          return "media types";
        }
        case "media cluster": {
          return "media clusters";
        }
        case "cost": {
          return "cost types";
        }
        case "audience": {
          return "audiences";
        }
        case "product": {
          return "products";
        }
        case "product group": {
          return "product groups";
        }

        default: {
          return "items";
        }
      }
    } else if (selectedOptions.length === 1) {
      switch (dropdownName) {
        case "metric": {
          return "visual";
        }
        case "channel": {
          return "channel";
        }
        case "campaign": {
          return "campaign";
        }
        case "publisher": {
          return "publisher";
        }
        case "property": {
          return "property";
        }
        case "media type": {
          return "media type";
        }
        case "media cluster": {
          return "media cluster";
        }
        case "cost": {
          return "cost type";
        }
        case "audience": {
          return "audience";
        }
        case "product": {
          return "product";
        }
        case "product group": {
          return "product group";
        }

        default: {
          return "item";
        }
      }
    } else {
      return dropdownName;
    }
  };

  const placeholderOrSelectedItems =
    selectedOptions.length > 0
      ? initialLength === selectedOptions.length
        ? `${dropdownNamePlural()}`
        : `${selectedOptions.length} ${dropdownNamePlural()} selected`
      : localOptions.length !== 0 && changed
      ? `${selectedOptions.length} ${dropdownNamePlural()} selected`
      : defaultValue || placeholder;

  return (
    <div className={classNames("checkboxes-input", { visible })}>
      <Dropdown
        overlayClassName="checkboxes-input-dropdown-container"
        trigger="click"
        visible={visible}
        onClick={openDropdown}
        overlay={
          <CheckboxesDropdown
            dropdownName={dropdownName}
            handleCheckAllChangeParent={handleCheckAllChange}
            reset={prevReset}
            selectedValue={selectedOptions}
            initialSelectedValues={clearMetric ? [] : initialSelectedValues}
            loading={loading}
            clear={clear}
            clearValues={clearValues}
            selectAll={selectAll}
            searchable={searchable}
            options={isFiltered ? filteredOptions : localOptions}
            onChange={onChangeValue}
            doNotCheckedAll={doNotCheckedAll}
            disabled={disabled}
          />
        }
        onVisibleChange={(vis) => setVisible(vis)}
        getPopupContainer={(el) => el}
        {...rest}
      >
        <div
          className="visible-part"
          style={{
            border: error ? "2px solid red" : ""
          }}
        >
          {!!icon && <div className="icon">{icon}</div>}
          <div className="placeholder body-large">
            {!visible ? (
              placeholderOrSelectedItems
            ) : (
              <Input
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  openDropdown();
                }}
                onFocus={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  openDropdown();
                }}
                onChange={onSearchOptions}
                placeholder={placeholder}
                autoFocus
                className="ant-input-borderless"
              />
            )}
          </div>
          {!visible && allowClear && (selectedOptions.length > 0 || selectedValue?.length > 0) && (
            <div className="clear" onClick={onClearButton}>
              <DismissIcon />
            </div>
          )}
          {showArrow && (
            <div className="arrow">
              <ChevronDownIcon />
            </div>
          )}
        </div>
      </Dropdown>
    </div>
  );
};

export default CheckboxesInput;
