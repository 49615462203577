import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useCursorMovingDown } from "./useCursorMovingDown";

export const useAreGlobalFitersVisible = () => {
  const isVisiblityTriggered = useSelector((state) => state.globalFilters.isVisible);
  const isCursorMovingDown = useCursorMovingDown();
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isVisiblityTriggered) {
      setIsVisible(true);
    } else {
      if (!isMouseOver && !isCursorMovingDown) {
        setIsVisible(false);
      }
    }
  }, [isVisiblityTriggered, isCursorMovingDown]);

  return { isVisible: isMouseOver ? true : isVisible, setIsMouseOver };
};
