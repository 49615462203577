import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout } from "antd";
import { NavLink, useHistory } from "react-router-dom";
import { Menu, Dropdown, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import Avatar from "../Avatar";
import { formatString, preventDefault, preventDefaultWithCallback } from "../../utils/common";
import useLogout from "../_auth/useLogout";
import { ReactComponent as FiltersIcon } from "../../images/icons/filters.svg";
import { ReactComponent as MenuPanelOpen } from "../../images/icons/menu-panel-open.svg";
import { ReactComponent as MenuPanelClose } from "../../images/icons/menu-panel-close.svg";
import { ReactComponent as Logo } from "../../images/logo.svg";
import { ReactComponent as Question } from "../../images/icons/question.svg";
import { ReactComponent as Notification } from "../../images/icons/notification.svg";
import { ReactComponent as Settings } from "../../images/icons/settings.svg";
import { ReactComponent as SmallLogo } from "../../images/small-logo.svg";
import { ReactComponent as ChevronDownIcon } from "../../images/icons/chevron-down2.svg";
import routes from "../../pages/App/routes";
import { globalFiltersSetVisibilityAction } from "../GlobalFilters/reducer";
import Notifications from "./Notifications";
import Info from "./Info";
import LogoutModal from "./modal/LogoutModal";
import { getDropdownValues } from "./methods";
import { changeSidebarData } from "./reducer";

/**
 * Header
 * @function Header
 * @description Header component
 */
const Header = ({ collapsed, handleCollapse, loggedUser, setFirstClicked }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [currRoute, setCurrentRoute] = useState(history.location.pathname);

  useEffect(() => {
    // Update the current route when the route changes
    const unlisten = history.listen((location) => {
      setCurrentRoute(location.pathname);
    });

    return () => {
      // Cleanup the listener when the component unmounts
      unlisten();
    };
  }, [history]);

  const collapse = useCallback(
    (e) => {
      e.preventDefault();
      handleCollapse(!collapsed);
    },
    [collapsed, handleCollapse]
  );
  const logoutModal = useSelector((state) => state.logoutModal);
  const sidebarData = localStorage.getItem("sidebar_data")
    ? JSON.parse(localStorage.getItem("sidebar_data"))
    : { selectedValue: getDropdownValues()[0] };

  const { submit } = useLogout();
  const handleDropdownClick = (item) => () => {
    dispatch(changeSidebarData({ selectedValue: item }));
    setDropdownVisible(false);
    setFirstClicked(true);
  };
  const handleDropdownVisibleChange = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClick = () => {
    dispatch(changeSidebarData({ selectedValue: getDropdownValues()[0] }));
  };

  useEffect(() => {
    if (currRoute === "/dashboard") dispatch(changeSidebarData({ selectedValue: { key: "1", label: "MediaCore" } }));
    else if (currRoute === "/planning-center")
      dispatch(changeSidebarData({ selectedValue: { key: "2", label: "MediaProphet" } }));
    else if (currRoute === "/coming-soon")
      dispatch(changeSidebarData({ selectedValue: { key: "3", label: "MediaAviator" } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currRoute]);

  const GlobalFiltersTriggerIcon = () => {
    const toggleGlobalFiltersVisibility = (isVisible) => dispatch(globalFiltersSetVisibilityAction({ isVisible }));

    return (
      <a
        href="/#"
        onClick={preventDefault}
        className="icon-link mr-5"
        onMouseEnter={() => toggleGlobalFiltersVisibility(true)}
        onMouseLeave={() => toggleGlobalFiltersVisibility(false)}
      >
        <FiltersIcon />
      </a>
    );
  };

  return (
    <Layout.Header className="layout-header">
      <div className="flex flex-items-center">
        {currRoute !== "/" && (
          <a href="/#" onClick={collapse} className="icon-link trigger-sidebar-nav">
            {collapsed ? <MenuPanelOpen /> : <MenuPanelClose />}
          </a>
        )}
        <NavLink to="/" className="header-logo" onClick={handleClick}>
          <Logo />
          <SmallLogo />
        </NavLink>
        {currRoute !== "/" && (
          <Dropdown
            // className="checkboxes-input-dropdown-container"
            style={{
              width: "100px",
              backgroundColor: "black"
            }}
            trigger="click"
            visible={dropdownVisible}
            onVisibleChange={handleDropdownVisibleChange}
            placement="bottomLeft"
            overlay={
              <Menu style={{ borderRadius: "10px", marginLeft: "25px", width: "250px", padding: "0" }}>
                {getDropdownValues().map((item) => (
                  <Menu.Item key={item.key} onClick={handleDropdownClick(item)} className="header-dropdown-item">
                    {item.label}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <Button
              style={{
                width: "100%",
                top: "4px",
                backgroundColor: "#f5f6fa",
                color: "#0032cf",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontFamily: "Heebo-Medium 500 ,sans-serif",
                fontSize: "30px",
                lineHeight: "44px",
                letterSpacing: "-0.02em",
                paddingLeft: "16px"
              }}
            >
              {sidebarData.selectedValue.label} <ChevronDownIcon style={{ marginLeft: "8px" }} />
            </Button>
          </Dropdown>
        )}
      </div>
      <div className="ml-a flex flex-items-center mr-25">
        <GlobalFiltersTriggerIcon />
        <Dropdown
          placement="bottomRight"
          overlay={<Info />}
          overlayClassName="header-notifications-dropdown"
          trigger={["hover"]}
        >
          <a href="/#" onClick={preventDefault} className="icon-link mr-5">
            <Question />
          </a>
        </Dropdown>
        <Dropdown
          placement="bottom"
          overlay={<Notifications />}
          overlayClassName="header-notifications-dropdown"
          trigger={["hover"]}
        >
          <a href="/#" onClick={preventDefault} className="icon-link danger mr-5">
            <Notification />
          </a>
        </Dropdown>
        <NavLink to={routes.MY_ACCOUNT} className="icon-link mr-40">
          <Settings />
        </NavLink>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="0">
                <NavLink to={routes.MY_ACCOUNT}>My account</NavLink>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item key="1">
                <a href="/#" onClick={preventDefaultWithCallback(submit)}>
                  Logout
                </a>
                {logoutModal.visible ? <LogoutModal /> : null}
              </Menu.Item>
            </Menu>
          }
          overlayClassName="header-user-dropdown"
          trigger={["hover"]}
        >
          <a href="/#" onClick={preventDefault} className="user-name-avatar">
            <span className="name mr-10">{formatString(loggedUser.username)}</span>
            <Avatar src={loggedUser?.avatar} size={44} icon={<UserOutlined />} className="mr-10" />
          </a>
        </Dropdown>
      </div>
    </Layout.Header>
  );
};

const mapStateToProps = (state) => ({
  loggedUser: state.auth.user,
  sidebarData: state.sidebarData
});

export default connect(mapStateToProps)(Header);
