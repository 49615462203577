import { createAction, createReducer } from "@reduxjs/toolkit";
import moment from "moment";
import { filtersDateFormat } from "./methods";

const FILTERS_LOCALSTORAGE_KEY = "taico_filters";

export const saveFiltersToLocalStorage = (filters = {}) => {
  localStorage.setItem(FILTERS_LOCALSTORAGE_KEY, JSON.stringify(filters));
  return filters;
};

export const globalFiltersInitialState = {
  isVisible: false,

  cpccVisible: false,
  totalSpendingsVisible: true,
  reachVisible: true,
  cpmVisible: false,
  costVisible: false,
  costTrendVisible: false,
  channels: [],
  campaigns: [],
  publishers: [],
  properties: [],
  mediaTypes: [],
  mediaClusters: [],
  startDate: moment().startOf("month").subtract(4, "years").format(filtersDateFormat),
  endDate: moment().format(filtersDateFormat),
  typeDate: "week",
  yoy: false
};

export const getInitialFilters = () => {
  const filtersFromLocalStorage = localStorage.getItem(FILTERS_LOCALSTORAGE_KEY);
  return filtersFromLocalStorage
    ? { ...globalFiltersInitialState, ...JSON.parse(filtersFromLocalStorage) }
    : globalFiltersInitialState;
};

export const globalFiltersSetVisibilityAction = createAction("global-filters/set-visibility");

export const globalFiltersChangeAction = createAction("global-filters/change");

export const globalFiltersResetAction = createAction("global-filters/reset");

export default createReducer(getInitialFilters(), (builder) => {
  builder
    .addCase(globalFiltersChangeAction, (state, action) => {
      if (action.payload?.resetLocalStorage) {
        localStorage.removeItem(FILTERS_LOCALSTORAGE_KEY);
        return {
          ...state,
          ...action.payload
        };
      }

      return {
        ...state,
        ...saveFiltersToLocalStorage(action.payload)
      };
    })
    .addCase(globalFiltersResetAction, () => {
      return globalFiltersInitialState;
    })
    .addCase(globalFiltersSetVisibilityAction, (state, action) => {
      return {
        ...state,
        isVisible: action.payload.isVisible
      };
    });
});
