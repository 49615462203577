import { formatString } from "../../utils/common";
import { sortTableColumnString } from "../../components/Table/methods";
import Actions from "./Actions";

export const costManagementColumns = (reloadPanningCenterData, history) => [
  {
    title: "Business Rule Name",
    sorter: (a, b) => sortTableColumnString(a.title, b.title),
    width: "10%",
    render: (row) => (
      <a
        href={`/cost-management/${row.id}`}
        onClick={(e) => {
          e.preventDefault();
          history.push({
            pathname: `/cost-management/${row.id}`,
            state: { record: row }
          });
        }}
        style={{
          display: "block",
          width: "100%",
          height: "100%",
          textDecoration: "none"
        }}
      >
        {formatString(row.title, "-")}
      </a>
    )
  },
  {
    title: "Creation Date",
    width: "10%",
    sorter: (a, b) => sortTableColumnString(a.created_at, b.created_at),

    render: (row) => formatString(row.created_at.substring(0, 10), "-")
  },
  {
    title: "Last Updated",
    width: "10%",
    sorter: (a, b) => sortTableColumnString(a.updated_at, b.updated_at),

    render: (row) => formatString(row.updated_at.substring(0, 10), "-")
  },
  {
    title: "Status",
    width: "5%",
    sorter: (a, b) => sortTableColumnString(a.acive, b.active),

    render: (row) => formatString(row.active ? "Active" : "Inactive")
  },
  {
    title: "",
    width: "10%",
    render: (row) => {
      return <Actions data={row} reloadPanningCenterData={reloadPanningCenterData} />;
    }
  }
];

export const analysisTypes = [
  { value: "channel", text: "Channel" },
  { value: "publisher", text: "Publisher" },
  { value: "property", text: "Property" }
];
export const getMMMChartNameKeyBasedOnAnalysisType = (analysisType) => {
  if (analysisType === analysisTypes[0].value) {
    return "channel";
  }

  if (analysisType === analysisTypes[1].value) {
    return "publisher";
  }

  if (analysisType === analysisTypes[2].value) {
    return "property";
  }

  return "channel";
};
export const prepareMBAParams = (params = {}, additionalParams = {}) => {
  return {
    channels: params?.channel?.map((item) => item.id).toString(),
    publishers: params?.publisher?.map((item) => item.id).toString(),
    properties: params?.property?.map((item) => item.id).toString(),
    date: `${params.startDate} - ${params.endDate}`,
    ...additionalParams
  };
};

export const prepareFilterParams = (params = {}, additionalParams = {}, selectedFilter = "") => {
  return {
    channels: selectedFilter === "channel" ? [] : params?.channel?.map((item) => item.id).toString(),
    publishers: selectedFilter === "publisher" ? [] : params?.publisher?.map((item) => item.id).toString(),
    properties: selectedFilter === "property" ? [] : params?.property?.map((item) => item.id).toString(),
    mediaTypes: [],
    campaigns: [],
    date: `${params.startDate} - ${params.endDate}`,
    yoy: params.yoy,
    ...additionalParams
  };
};
