// This method is used also for reach graph / cpm graph
import moment from "moment";

export const prepareCPCCParams = (params = {}, additionalParams = {}, id = ["name"]) => {
  return {
    channels: params?.channels?.map((item) => item[id]),
    campaigns: params?.campaigns?.map((item) => item[id]),
    publishers: params?.publishers?.map((item) => item[id]),
    properties: params?.properties?.map((item) => item[id]),
    mediaTypes: params?.mediaTypes?.map((item) => item[id]),
    audiences: params?.audiences?.map((item) => item[id]),
    products: params?.products?.map((item) => item[id]),
    productGroups: params?.productGroups?.map((item) => item[id]),
    mediaClusters: params?.mediaClusters?.map((item) => item[id]),
    date: `${params.startDate} - ${params.endDate}`,
    yoy: params.yoy,
    ...additionalParams
  };
};

export const prepareFilterParams = (params = {}, additionalParams = {}, selectedFilter = "") => {
  return {
    channels:
      selectedFilter === "channel" || params?.channels?.length === 0 ? null : params?.channels?.map((item) => item.id),
    campaigns:
      selectedFilter === "campaign" || params?.campaigns?.length === 0
        ? null
        : params?.campaigns?.map((item) => item.id),
    publishers:
      selectedFilter === "publisher" || params?.publishers?.length === 0
        ? null
        : params?.publishers?.map((item) => item.id),
    // properties: selectedFilter === "property" ? [] : params?.properties?.map((item) => item.id).toString(),
    mediaTypes:
      selectedFilter === "mediaType" || params?.mediaTypes?.length === 0
        ? null
        : params?.mediaTypes?.map((item) => item.id),
    mediaClusters:
      selectedFilter === "mediaCluster" || params?.mediaClusters?.length === 0
        ? null
        : params?.mediaClusters?.map((item) => item.id),
    audiences:
      selectedFilter === "audience" || params?.audiences?.length === 0
        ? null
        : params?.audiences?.map((item) => item.id),
    products:
      selectedFilter === "product" || params?.products?.length === 0 ? null : params?.products?.map((item) => item.id),
    productGroups:
      selectedFilter === "productGroup" || params?.productGroups?.length === 0
        ? null
        : params?.productGroups?.map((item) => item.id),

    date: `${params.startDate} - ${params.endDate}`,
    yoy: params.yoy,
    ...additionalParams
  };
};

export const prepareYOYResponse = (data, nameKey) => {
  const { result = [], result_yoy = [] } = data || {};
  return [
    ...result.map((item) => ({
      ...item,
      date: item.date,
      original_date: item.date,
      yoy: false,
      [`original_${nameKey}`]: item[nameKey]
    })),
    ...result_yoy.map((item) => {
      return {
        ...item,
        yoy: true,
        [nameKey]: `YOY ${item[nameKey]}`,
        [`original_${nameKey}`]: item[nameKey],
        hideAsLegend: true,
        original_date: item.date,
        date: moment(item.date).add(1, "year")
      };
    })
  ];
};
