import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { Dropdown } from "antd";
import moment from "moment";
import { isEqual, isEmpty } from "lodash";
import { ReactComponent as DismissIcon } from "../../images/icons/dismiss.svg";
import { ReactComponent as ChevronDownIcon } from "../../images/icons/chevron-down.svg";
import { formatDate } from "../../utils/common";
import { usePrevious } from "../../utils/custom-hooks";
import axios from "../../axios";
import { filtersDateFormat } from "../GlobalFilters/methods";
import DateRangePickerDropdown from "./DateRangePickerDropdown";

const DateRangePicker = (props) => {
  const {
    icon,
    placeholder = "Select date",
    showArrow,
    allowClear,
    reset,
    onChange,
    defaultValue,
    disabledDates = [],
    apiRoute,
    requestBody,
    handleYoyChange,
    yoyValue,
    yoyDisabledMessage,
    yoyDisabled,
    deleteYoyFunctionality,
    canBeOpened = true,
    ...rest
  } = props;
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(defaultValue || undefined);
  const [localDisabledDates, setLocalDisabledDates] = useState(disabledDates || []);
  const prevReset = usePrevious(reset);
  const prevDefaultValue = usePrevious(defaultValue);
  const openDropdown = useCallback(() => {
    if (canBeOpened) {
      setVisible(true);
    } else {
      setVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDisabledDates = () => {
    if (apiRoute && !loading) {
      setLoading(true);

      // Create a Cancel Token
      const source = axios.CancelToken.source();

      axios
        .post(apiRoute, requestBody, {
          cancelToken: source.token // Pass the Cancel Token in the Request Configuration
        })
        .then(
          (res) => {
            setLocalDisabledDates(!isEmpty(res.data?.data) ? res.data.data : []);
          },
          // eslint-disable-next-line unused-imports/no-unused-vars
          (error) => {
            console.error("error");
          }
        )
        .finally(() => {
          setLoading(false); // Ensure loading is set to false in both success and error cases
        });

      // Return the cancel function to be called on component unmount
      return () => {
        source.cancel("Operation canceled by the component unmounting");
      };
    }
  };

  useEffect(() => {
    const cancelRequest = getDisabledDates();
    return () => {
      // Cancel the request when the component unmounts
      if (cancelRequest) cancelRequest();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEqual(defaultValue, prevDefaultValue)) {
      setSelectedDate(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const onChangeDate = useCallback(
    (type) => (val) => {
      if (val.length === 2) {
        let value = [moment(val[0].toDate()), moment(val[1].toDate())];
        val = value;
        setSelectedDate(() => ({ type, val }));
        onChange({ type, val });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, selectedDate]
  );

  useEffect(() => {
    return () => {
      // Clean up any subscriptions or asynchronous tasks here if any.
    };
  }, []);

  const onClear = useCallback(
    (e) => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }
      setSelectedDate(defaultValue || undefined);
      onChange(undefined);
    },
    [onChange, defaultValue]
  );

  useEffect(() => {
    if (reset !== undefined && reset !== prevReset) {
      onClear();
    }
  }, [reset, prevReset, onClear]);

  const placeholderOrValue =
    selectedDate && selectedDate.val ? (
      <div className="date-range-picker-value">
        <div>{formatDate({ date: selectedDate.val[0], formatIn: filtersDateFormat })}</div>
        <div>{formatDate({ date: selectedDate.val[1], formatIn: filtersDateFormat })}</div>
      </div>
    ) : (
      placeholder
    );

  useEffect(() => {
    return () => {
      setVisible(false);
      setLoading(false);
      setLocalDisabledDates(disabledDates || []);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames("date-range-picker-input", { visible })}>
      <Dropdown
        overlayClassName="date-range-picker-input-dropdown-container"
        trigger="click"
        visible={visible}
        onClick={openDropdown}
        overlay={
          <DateRangePickerDropdown
            handleYoyChange={handleYoyChange}
            yoyValue={yoyValue}
            yoyDisabledMessage={yoyDisabledMessage}
            yoyDisabled={yoyDisabled}
            visible={visible}
            onChange={onChangeDate}
            selectedDate={selectedDate}
            disabledDates={localDisabledDates}
            loading={loading}
            deleteYoyFunctionality={deleteYoyFunctionality}
          />
        }
        onVisibleChange={(vis) => setVisible(vis)}
        getPopupContainer={(el) => el}
        {...rest}
      >
        <div className="visible-part">
          {!!icon && <div className="icon">{icon}</div>}
          <div className="placeholder body-large">{placeholderOrValue}</div>
          {visible && allowClear && selectedDate && selectedDate.val && (
            <div className="clear" onClick={onClear}>
              <DismissIcon />
            </div>
          )}
          {showArrow && (
            <div className="arrow">
              <ChevronDownIcon />
            </div>
          )}
        </div>
      </Dropdown>
    </div>
  );
};

export default DateRangePicker;
