/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Tabs, DatePicker, Tooltip } from "antd";
import isEqual from "lodash/isEqual";
import moment from "moment";
import { Calendar, DateObject } from "react-multi-date-picker";
import { formatDate } from "../../utils/common";
import { usePrevious } from "../../utils/custom-hooks";
import { filtersDateFormat } from "../GlobalFilters/methods";
import Checkbox from "../Checkbox";
import Preloader from "../Preloader";
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const DateRangePickerDropdown = (props) => {
  const {
    onChange = () => (e) => e,
    selectedDate,
    disabledDates,
    handleYoyChange,
    yoyValue,
    yoyDisabledMessage,
    yoyDisabled,
    loading,
    deleteYoyFunctionality
  } = props;

  const [activeTab, setActiveTab] = useState(selectedDate?.type);
  const prevType = usePrevious(selectedDate?.type);
  const dateFormat = "YYYY-MM-DD";
  const [disabledMonths, setDisabledMonths] = useState([]);
  const [disabledYears, setDisabledYears] = useState([]);
  const [maxDay, setMaxDay] = useState("");
  useEffect(() => {
    setDisabledMonths(getDisabledMonths(disabledDates));
    setDisabledYears(getDisabledYears(disabledDates));
    setMaxDay(setMaxDayFunction(disabledDates, "01/01/2018").toISOString().slice(0, 10));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledDates]);

  function setMaxDayFunction(disabledDates, minDay) {
    let latestEnabledDate = new Date(minDay);
    const today = new Date();

    disabledDates.forEach((dateRange) => {
      const startDate = new Date(dateRange.start);
      const endDate = new Date(dateRange.end);

      if (startDate <= latestEnabledDate && endDate >= latestEnabledDate) {
        latestEnabledDate = new Date(startDate.getTime() - 86400000); // subtract one day
      }

      if (endDate >= latestEnabledDate && endDate <= today) {
        latestEnabledDate = new Date(endDate.getTime() - 86400000); // subtract one day
      }
    });

    return latestEnabledDate;
  }

  function getDisabledMonths(disabledDates) {
    const disabledMonths = {};

    disabledDates.forEach((dateRange) => {
      const start = new Date(dateRange.start);
      const end = new Date(dateRange.end);

      let current = new Date(start);
      while (current <= end) {
        const monthYear = `${current.toLocaleString("default", { month: "long" })} ${current.getFullYear()}`;

        if (!disabledMonths[monthYear]) {
          // check if all days of the month are disabled
          const year = current.getFullYear();
          const month = current.getMonth();
          const daysInMonth = new Date(year, month + 1, 0).getDate();
          let allDaysDisabled = true;

          for (let day = 1; day <= daysInMonth; day++) {
            const date = new Date(year, month, day);
            const disabled = disabledDates.some((range) => {
              const start = new Date(range.start);
              const end = new Date(range.end);
              return start <= date && date <= end;
            });
            if (!disabled) {
              allDaysDisabled = false;
              break;
            }
          }

          disabledMonths[monthYear] = {
            month: current.toLocaleString("default", { month: "long" }),
            year: current.getFullYear(),
            disabled: allDaysDisabled
          };
        }

        current = new Date(current.setMonth(current.getMonth() + 1));
      }
    });

    const disabledMonthsList = Object.values(disabledMonths).filter((month) => month.disabled);
    return disabledMonthsList;
  }

  function findYearsWithDisabledMonths(data) {
    const yearsWithDisabledMonths = [2007, 2008, 2009, 2010, 2011, 2012, 2013];
    for (const yearData of data) {
      const hasDisabledMonth = Object.values(yearData.disabledMonths).some((disabled) => !disabled);
      if (hasDisabledMonth) {
        yearsWithDisabledMonths.push(yearData.year);
      }
    }
    return yearsWithDisabledMonths;
  }

  function getDisabledYears(disabledDates) {
    const disabledYears = {};
    disabledDates.forEach(({ start, end }) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      let currentYear = startDate.getFullYear();
      while (currentYear <= endDate.getFullYear()) {
        if (!disabledYears[currentYear]) {
          disabledYears[currentYear] = { year: currentYear, disabledMonths: {} };
        }
        const disabledYear = disabledYears[currentYear];
        const startMonth = currentYear === startDate.getFullYear() ? startDate.getMonth() : 0;
        const endMonth = currentYear === endDate.getFullYear() ? endDate.getMonth() : 11;
        for (let month = startMonth; month <= endMonth; month++) {
          const monthYear = `${new Date(currentYear, month).toLocaleString("default", {
            month: "long"
          })} ${currentYear}`;
          // eslint-disable-next-line no-loop-func
          const monthDisabled = disabledDates.some((dateRange) => {
            const rangeStart = new Date(dateRange.start);
            const rangeEnd = new Date(dateRange.end);
            return (
              rangeStart.getFullYear() === currentYear &&
              rangeStart.getMonth() <= month &&
              rangeEnd.getFullYear() === currentYear &&
              rangeEnd.getMonth() >= month
            );
          });
          if (!monthDisabled) {
            disabledYear.disabledMonths[monthYear] = false;
          } else if (disabledYear.disabledMonths[monthYear] !== false) {
            disabledYear.disabledMonths[monthYear] = true;
          }
        }
        currentYear++;
      }
    });
    return findYearsWithDisabledMonths(Object.values(disabledYears));
  }

  const customDisableWeeks = ({ date }) => {
    const isDisabled = disabledDates.some((disabledInterval) => {
      const startDate = moment(disabledInterval.start, "MM/DD/YYYY");
      const endDate = moment(disabledInterval.end, "MM/DD/YYYY");
      const momentString = moment(date.toDate()).format("MM/DD/YYYY");
      const currentDate = moment(momentString, "MM/DD/YYYY");
      return currentDate.isBetween(startDate, endDate, null, "[]"); // include start and end dates
    });

    if (isDisabled) {
      return {
        disabled: true,
        style: { color: "#ccc" }
      };
    }
  };

  const customDisableDatesMonths = () => {
    const year = document.querySelector(".rmdp-header-values")?.textContent;
    const calendars = document.querySelectorAll(".rmdp-day");

    if (calendars !== null) {
      calendars.forEach((calendar) => {
        const month = calendar.textContent.trim();
        let isDisabled = false;
        disabledMonths.forEach((disabledMonth) => {
          if (disabledMonth.month === month && disabledMonth.year === Number(year)) {
            calendar.classList.add("rmdp-disabled");
            calendar.classList.add("disable-selection");
            calendar.addEventListener("click", (event) => {
              event.preventDefault();
            });
            isDisabled = true;
          }
        });

        // disable months in the future
        const currentDate = new Date();
        const maxMonthYear = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const currentMonthYear = new Date(`${month} 01, ${year}`);
        if (!isDisabled && currentMonthYear >= maxMonthYear) {
          calendar.classList.add("rmdp-disabled");
          calendar.classList.add("disable-selection");
          calendar.addEventListener("click", (event) => {
            event.preventDefault();
          });
          isDisabled = true;
        }

        if (!isDisabled) {
          calendar.classList.remove("rmdp-disabled");
          calendar.classList.remove("disable-selection");
          calendar.removeEventListener("click", () => {});
        }
      });
    }
  };

  useEffect(() => {
    if (activeTab === "month") {
      setTimeout(() => {
        customDisableDatesMonths();
      }, 0);
    } else if (activeTab === "year") {
      setTimeout(() => {
        customDisableDatesYears();
      }, 0);
    }
  }, [activeTab]);

  const customDisableDatesYears = () => {
    //const disabledYears = getDisabledYears(disabledDates);
    const calendars = document.querySelectorAll(".rmdp-day");
    if (calendars !== null) {
      calendars.forEach((calendar) => {
        const year = calendar.textContent.trim();
        let isDisabled = false;
        disabledYears.forEach((disabledYear) => {
          if (disabledYear === Number(year)) {
            calendar.classList.add("rmdp-disabled");
            calendar.classList.add("disable-selection");
            calendar.addEventListener("click", (event) => {
              event.preventDefault();
            });
            isDisabled = true;
          }
        });
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        if (!isDisabled && Number(year) >= currentYear) {
          calendar.classList.add("rmdp-disabled");
          calendar.classList.add("disable-selection");
          calendar.addEventListener("click", (event) => {
            event.preventDefault();
          });
          isDisabled = true;
        }
        if (!isDisabled) {
          calendar.classList.remove("rmdp-disabled");
          calendar.classList.remove("disable-selection");
          calendar.removeEventListener("click", () => {});
        }
      });
    }
  };

  useEffect(() => {
    setDisabledMonths(getDisabledMonths(disabledDates));
    setDisabledYears(getDisabledYears(disabledDates));
    setMaxDay(setMaxDayFunction(disabledDates, "01/01/2018").toISOString().slice(0, 10));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEqual(selectedDate?.type, prevType)) {
      setActiveTab(selectedDate?.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate?.type]);

  const disableFutureDates = (current) => {
    const isInDisabledInterval = !!disabledDates.find((disabledInterval) => {
      return current?.isBetween(
        moment(disabledInterval.start).format(filtersDateFormat),
        moment(disabledInterval.end).format(filtersDateFormat)
      );
    });
    return isInDisabledInterval || current?.isAfter(moment()) || current?.isBefore(moment().subtract(7, "years"));
    // const isInDisabledInterval = !!disabledDates.find((disabledInterval) => {
    //   return current?.isBetween(
    //     moment(disabledInterval.start).format(filtersDateFormat),
    //     moment(disabledInterval.end).format(filtersDateFormat)
    //   );
    // });

    // const currentDate = current?.toDate();
    // const hasData = checkDataForDate(currentDate); // Replace 'checkDataForDate' with your own logic to check if the current date has data

    // return (
    //   isInDisabledInterval || current?.isAfter(moment()) || current?.isBefore(moment().subtract(7, "years")) || !hasData
    // );
  };
  const rangePickerSelectedQuarters = {
    start: moment(selectedDate.val[0]),
    end: moment(selectedDate.val[1])
  };

  useEffect(() => {
    return () => {
      setDisabledMonths([]);
      setDisabledYears([]);
      setMaxDay("");
    };
  }, []);
  return (
    <>
      <div className="date-range-picker-input-dropdown">
        {loading && (
          <div className="checkboxes-input-dropdown">
            <Preloader />{" "}
          </div>
        )}
        {!loading && (
          <Tabs
            defaultActiveKey="week"
            activeKey={activeTab}
            destroyInactiveTabPane
            onChange={(selectedTab) => setActiveTab(selectedTab)}
            tabBarExtraContent={
              !deleteYoyFunctionality
                ? {
                    right: (
                      <div>
                        <Tooltip title={yoyDisabledMessage || "Year Over Year"}>
                          <Checkbox
                            onChange={handleYoyChange}
                            checked={yoyValue}
                            disabled={!!yoyDisabledMessage || yoyDisabled}
                          >
                            YOY
                          </Checkbox>
                        </Tooltip>
                      </div>
                    )
                  }
                : null
            }
          >
            <TabPane tab="Week" key="week">
              <Calendar
                value={[
                  new DateObject(formatDate({ date: selectedDate.val[0], formatIn: dateFormat })),
                  new DateObject(formatDate({ date: selectedDate.val[1], formatIn: dateFormat }))
                ]}
                onChange={onChange("week")}
                minDate={new DateObject().subtract(5, "years")}
                maxDate={new DateObject(maxDay)}
                mapDays={customDisableWeeks}
                rangeHover
                onlyShowInRangeDates
                className="custom-calendar"
                range
                highlightToday={true}
                monthYearSeparator="|"
                format={dateFormat}
                numberOfMonths={2}
              />
            </TabPane>
            <TabPane tab="Month" key="month">
              <div
                onClick={() => {
                  setTimeout(() => {
                    customDisableDatesMonths();
                  }, 0);
                }}
              >
                <Calendar
                  value={[
                    new DateObject(selectedDate?.val[0].format("YYYY-MM-DD")),
                    new DateObject(selectedDate?.val[1].format("YYYY-MM-DD"))
                  ]}
                  onChange={onChange("month")}
                  minDate={new DateObject().subtract(5, "years")}
                  maxDate={new DateObject(maxDay)}
                  hideMonth
                  rangeHover
                  onlyShowInRangeDates
                  className="custom-calendar"
                  range
                  onlyMonthPicker
                  highlightToday={false}
                />
              </div>
            </TabPane>
            <TabPane tab="Quarter" key="quarter">
              <RangePicker
                picker="quarter"
                className="range-picker-quarter"
                getPopupContainer={(el) => el}
                onChange={onChange("quarter")}
                value={[rangePickerSelectedQuarters.start, rangePickerSelectedQuarters.end]}
                disabledDate={disableFutureDates}
                format={dateFormat}
                open
              />
            </TabPane>
            <TabPane tab="Year" key="year">
              <div
                onClick={() => {
                  setTimeout(() => {
                    customDisableDatesYears();
                  }, 0);
                }}
              >
                <Calendar
                  value={[
                    new DateObject(selectedDate?.val[0].format("YYYY-MM-DD")),
                    new DateObject(selectedDate?.val[1].format("YYYY-MM-DD"))
                  ]}
                  onChange={onChange("year")}
                  minDate={new DateObject().subtract(5, "years")}
                  maxDate={new DateObject(maxDay)}
                  onMouseMove={customDisableDatesYears}
                  rangeHover
                  onlyShowInRangeDates
                  className="custom-calendar"
                  range
                  onlyYearPicker
                  highlightToday={false}
                />
              </div>
            </TabPane>
          </Tabs>
        )}
      </div>
    </>
  );
};

export default DateRangePickerDropdown;
