import { useState } from "react";
import axios from "../../axios";
import { catchFormErrors } from "./methods";

const useFormSubmit = (params) => {
  const { form, route, onSuccess, onFailed, values, method, transformer = (v) => v, onlyTouched = false } = params;
  const [requestState, setRequestState] = useState({
    loading: false,
    generalError: []
  });

  const submit = (extendedValues) => {
    let touchedVals = {};
    // eslint-disable-next-line array-callback-return
    Object.entries(form.getFieldsValue()).map(([key, value]) => {
      if (form.isFieldTouched(key)) touchedVals[key] = value;
    });
    const dataToSend = onlyTouched
      ? transformer({ ...touchedVals })
      : transformer({ ...touchedVals, ...values, ...extendedValues });

    setRequestState({ ...requestState, loading: true, generalError: [] });
    axios[method || "post"](route, dataToSend).then(
      (res) => {
        setRequestState({ ...requestState, loading: false, generalError: [] });
        onSuccess && onSuccess(res.data);
      },
      (err) => {
        const { generalErrors } = catchFormErrors(err, form);
        setRequestState({ ...requestState, loading: false, generalError: generalErrors });
        onFailed && onFailed(err);
      }
    );
  };

  return {
    loading: requestState.loading,
    generalError: requestState.generalError,
    // dirtyFields: requestState.dirtyFields,
    submit
  };
};

export default useFormSubmit;
